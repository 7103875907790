import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ComplianceStatusFeatureKey, ComplianceStatusReducer } from './compliance-dashboard/state/compliance-status.reducer';
import { ComplianceStatusEffects } from './compliance-dashboard/state/compliance-status.effects';
import { ComplianceComponent } from './compliance.component';
import { LayoutModule } from '../layout/layout.module';
import { ComplianceDashboardComponent } from './compliance-dashboard/compliance-dashboard.component';
import { MintComponentsModule } from '@mint-libs/components';
import { PpaComponent } from './ppa/ppa.component';
import { PPAInformationFeatureKey, PPAInformationReducer } from './ppa/state/ppa-information.reducer';
import { PPAInformationEffects } from './ppa/state/ppa-information.effects';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatIconModule } from '@angular/material/icon';
import { ComplianceRoutingModule } from './compliance-routing.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { ComplianceService } from './compliance.service';
import { QuotaModule } from './quota/quota.module';
import { ToasterModule } from 'angular2-toaster';

@NgModule({
  declarations: [ComplianceComponent, ComplianceDashboardComponent, PpaComponent],
  imports: [
    CommonModule,
    LayoutModule,
    NgxExtendedPdfViewerModule,
    MatIconModule,
    DirectivesModule,
    MintComponentsModule,
    ComplianceRoutingModule,
    QuotaModule,
    ToasterModule.forRoot(),
    StoreModule.forFeature(ComplianceStatusFeatureKey, ComplianceStatusReducer),
    StoreModule.forFeature(PPAInformationFeatureKey, PPAInformationReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([ComplianceStatusEffects, PPAInformationEffects])
  ],
  providers: [ComplianceService]
})
export class ComplianceModule {}
