import { Component, Input } from '@angular/core';

@Component({
  selector: 'mint-mobile-rbi-quota-dashboard',
  templateUrl: './rbi-quota-dashboard.component.html',
  styleUrls: ['./rbi-quota-dashboard.component.scss']
})
export class RbiQuotaDashboardComponent {
  @Input() quotaPeriods: any;
  constructor() {}
}
