import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';
import { MeasureTypeMappingState, MeasureType } from '@mint-libs/context';

@Injectable({
  providedIn: 'root'
})
export class QuotaService {
  selectedTimePeriod: any;
  pccs = [];
  locale = 'en-US';
  hasPIT = false; /*point in time*/
  hasQuotaMultiplier = false; /* quota multiplier*/
  halfYearlyQuotaNotSupportedPartitions = ['Q3', 'Q4', 'H2'];
  qaConfigData: any;
  isLoaded = false;
  isQuotaAcknowledged = false;
  isQuotaAcknowledgeSuccess: any;
  isHalfYearlyQuotaSupported: any;
  isH2MessageHidden = false;
  ubiSelectedTab: any;
  notAvailable = false;
  notAvailableMessage: any;

  constructor() {}

  getQuotaDetails(quotaDetail, isHalfYearlyQuotaSupported, measureTypeMapping = new MeasureTypeMappingState([])) {
    let pccCountForPcg = 0;
    this.pccs = [];
    quotaDetail.planComponentGroups.forEach(pcg => {
      pccCountForPcg = pcg.planComponentCombinations.length;
      let currPccIndex = 0;
      let currPccRenderRowCount = 0;
      if (!this.hasPIT) {
        this.hasPIT = this.checkForPIT(pcg.planComponentCombinations);
      }
      this.hasQuotaMultiplier = this.checkForQuotaMultiplierPCC(pcg.planComponentCombinations);

      let pccCollection = new Array();

      pcg.planComponentCombinations.forEach(pcc => {
        const isBaseline = measureTypeMapping.getMeasureType(pcc.id) === MeasureType.Baseline;
        let total = 0;
        let totalRecurring = 0;
        const totalNonRecurring = 0;
        let previousTarget = 0;
        let previousRecurringTarget = 0;
        let pccMetrics;
        const pccUnits = pcc.measuringUnits;
        let totalUbiLegalWorkweekHours = 0;
        let totalUbiStandardTaskHours = 0;
        let totalUbiPublicHolidayHours = 0;
        let totalUbiVacationHours = 0;
        let totalUbiBaselineHours = 0;
        let pccAllMetrics = pcc.allMetrics;
        // To remove code periods from PCCs which are not supported in halfyearlyquotas quotas
        if (isHalfYearlyQuotaSupported && pcg.incentiveType !== 'UBI') {
          pccAllMetrics = Object.keys(pcc.allMetrics)
            .filter(key => !this.halfYearlyQuotaNotSupportedPartitions.includes(key.split('-').pop()))
            .reduce((object, key) => {
              object[key] = pcc.allMetrics[key];
              return object;
            }, {});
        }

        pccMetrics = Object.entries(pccAllMetrics).map(allMetric => {
          const codePeriodKey = allMetric[0];
          const metrics: any = allMetric[1];
          let finalTarget = metrics.target;
          let targetRecurring = metrics.targetRecurring;
          if (pcc.calcType !== 'PIT') {
            finalTarget = metrics.target - previousTarget;
            targetRecurring = metrics.targetRecurring - previousRecurringTarget;
            previousTarget = metrics.target;
            previousRecurringTarget = metrics.targetRecurring;
            total += finalTarget;
            totalRecurring += targetRecurring;
          } else {
            total = finalTarget;
            totalRecurring = metrics.targetRecurring;
          }
          if (pcg.incentiveType === 'UBI') {
            totalUbiBaselineHours = metrics.ubiTargetBaselineHours;
            totalUbiLegalWorkweekHours = metrics.ubiLegalWorkweekHours;
            totalUbiStandardTaskHours = metrics.ubiStandardTaskHours;
            totalUbiPublicHolidayHours = metrics.ubiPublicHolidayHours;
            totalUbiVacationHours = metrics.ubiVacationHours;
            finalTarget = metrics.target;
          }
          return {
            Target: finalTarget,
            TargetRecurring: targetRecurring,
            TargetNonRecurring: finalTarget - targetRecurring,
            QuotaMultiplier: metrics.quotaMultiplier,
            totalUbiBaselineHours: metrics.ubiTargetBaselineHours,
            totalUbiLegalWorkweekHours: metrics.ubiLegalWorkweekHours,
            totalUbiStandardTaskHours: metrics.ubiStandardTaskHours,
            totalUbiPublicHolidayHours: metrics.ubiPublicHolidayHours,
            totalUbiVacationHours: metrics.ubiVacationHours,
            timeWorkedPercentage: metrics.ubiTimeWorkPercentage,
            quotaAdjustment: metrics.ubiTargetAdjustment,
            CodePeriod: codePeriodKey
          };
        });

        if (totalRecurring !== 0) {
          let renderPCC: any;
          renderPCC = new Object();
          this.setPCCCollection(renderPCC, pccMetrics, pcc, isBaseline ? 'Baseline' : 'Recurring', 'TargetRecurring', totalRecurring, 2, pccUnits);
          pccCollection[currPccIndex++] = renderPCC;

          renderPCC = new Object();
          this.setPCCCollection(renderPCC, pccMetrics, pcc, isBaseline ? 'Total Quota - Baseline' : 'Non-Recurring', 'TargetNonRecurring', total - totalRecurring, -1, pccUnits);
          pccCollection[currPccIndex++] = renderPCC;

          currPccRenderRowCount += 2;
        } else {
          currPccRenderRowCount += 1;

          let renderPCC: any;
          renderPCC = new Object();
          this.setPCCCollection(renderPCC, pccMetrics, pcc, pcc.isDynamicMetric ? 'Total Committed' : 'Total', 'Target', total, 1, pccUnits);
          pccCollection[currPccIndex++] = renderPCC;
        }
      });

      pccCollection[0].pcgRowSpan = currPccIndex;

      for (let i = 0; i < pccCollection.length; i++) {
        const pccItem = pccCollection[i];
        this.pccs = this.getMetrics(
          pccItem.pccMetrics,
          pccItem.pccName,
          pccItem.metricType,
          pccItem.metricTypeKey,
          pccItem.total,
          pccItem.rowSpan,
          pccItem.pccUnits,
          pccItem.calcType,
          pccItem.pcgName,
          pccItem.pcgRowSpan,
          pccItem.ubiTargetHoursBreakUp,
          pcg.incentiveType,
          pcg.pCGWeight
        );
      }

      pccCollection = new Array();
    });
    this.isLoaded = true;
  }

  private setPCCCollection(renderPCC: any, pccMetrics: any, pcc: any, metricType: string, metricTypeKey: string, total: number, rowSpan: number, pccUnits: any) {
    renderPCC.pccMetrics = pccMetrics;
    renderPCC.pccName = pcc.name;
    renderPCC.metricType = metricType;
    renderPCC.metricTypeKey = metricTypeKey;
    renderPCC.total = total;
    renderPCC.rowSpan = rowSpan;
    renderPCC.pccUnits = pccUnits;
    renderPCC.calcType = pcc.calcType;
    renderPCC.pcgName = pcc.parent;
    renderPCC.pcgRowSpan = -1;
  }

  private checkForPIT(planComponentCombinations: any) {
    const result = planComponentCombinations.find(planComponentCombination => {
      return planComponentCombination.calcType === 'PIT';
    });
    return result !== undefined;
  }

  private checkForQuotaMultiplierPCC(planComponentCombinations) {
    const result = planComponentCombinations.find(planComponentCombination => {
      return Object.entries(planComponentCombination.allMetrics).find(allMetrics => {
        const metrics: any = allMetrics[1];
        if (metrics.quotaMultiplier !== null) {
          return true;
        } else {
          return false;
        }
      });
    });
    return (this.hasQuotaMultiplier = this.hasQuotaMultiplier || result !== undefined);
  }

  private getMetrics(pccMetrics, pccName, metricType, metricTypeKey, total, rowSpan, units, calcType, pcgName, pcgRowSpan, ubiTargetHoursBreakUp = null, pcgIncentiveType, pcgWeight) {
    let isPIT = false;
    let totalQuotaMultiplier = 0;
    let recurringQuotaMultiplier = 0;
    let nonRecurringQuotaMultiplier = 0;
    const isQuotaMultiplier = this.checkQuotaMultiplierMetric(pccMetrics);

    const metrics = pccMetrics.map(metric => {
      if (isNaN(metric[metricTypeKey])) {
        return {
          Value: metric[metricTypeKey],
          ValueNumeric: metric[metricTypeKey]
        };
      } else {
        return {
          Value: formatNumber(metric[metricTypeKey], this.locale, '1.0-2') + ' ' + units,
          ValueNumeric: formatNumber(metric[metricTypeKey], this.locale, '1.0-2'),
          CodePeriod: metric.CodePeriod
        };
      }
    });

    if (pcgIncentiveType && pcgIncentiveType !== 'UBI') {
      metrics.push({
        Value: formatNumber(total, this.locale, '1.0-2') + ' ' + units,
        ValueNumeric: formatNumber(total, this.locale, '1.0-2'),
        CodePeriod: metrics.CodePeriod
      });
    }

    if (calcType.toUpperCase() === 'PIT') {
      isPIT = true;
    }

    this.pccs.push({
      Name: pccName,
      MetricType: metricType,
      RowSpan: rowSpan,
      Metrics: metrics,
      IsPIT: isPIT,
      Units: units,
      PCGName: pcgName,
      PCGRowSpan: pcgRowSpan,
      PCGWeight: pcgWeight,
      isQuotaMultiplier: isQuotaMultiplier,
      UbiTargetHoursBreakUp: ubiTargetHoursBreakUp,
      PCGIncentiveType: pcgIncentiveType
    });
    return this.pccs;
  }

  private checkQuotaMultiplierMetric(pccMetrics) {
    const isQuotaMultiplier = pccMetrics.find(metric => {
      if (metric.QuotaMultiplier != null) {
        return true;
      } else {
        return false;
      }
    });
    return isQuotaMultiplier !== undefined;
  }
}
