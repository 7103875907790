import { Component, OnInit, Input } from '@angular/core';
// import { ToggleSwitchConfig } from 'src/app/shared/toggle-switch/toggle-switch-config';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '@mint-libs/authentication';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectAuthInfo } from '../../core/authentication/state/auth.selectors';
import { SidebarItem } from './sidebar-item.model';
import { PaginationNumberFormatterParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'mint-mobile-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() sidebarItemList: SidebarItem[];
  @Input() routePrefix = '';
  userName = '';
  supportLink = 'https://microsoft.service-now.com/wwic?id=sc_cat_item&sys_id=b34393d9131fd2042620f0912244b0e6';

  constructor(protected sanitizer: DomSanitizer, private authService: AuthenticationService, private appState: Store<AppState>, private router: Router) {}

  ngOnInit() {
    const authInfoSelector = this.appState.select(selectAuthInfo);
    authInfoSelector.subscribe(state => (this.userName = state.userInfo.profile.given_name));
  }

  logout() {
    this.authService.logOut();
  }

  onKeypress(url: string) {
    window.open(url, '');
  }

  onKeypressNavigate(url: string) {
    this.router.navigateByUrl(url);
  }
}
