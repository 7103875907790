import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { AuthActionTypes, AuthActions } from './auth.actions';
import { AuthenticationService } from '@mint-libs/authentication';

@Injectable()
export class AuthEffects {
  @Effect()
  loadLogins$ = this.actions$.pipe(
    ofType(AuthActionTypes.PerformLogin),
    concatMap(() => this.authService.login())
  );

  constructor(private actions$: Actions<AuthActions>, private authService: AuthenticationService) {}
}
