import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@mint-libs/authentication';
import { AppState } from './reducers';
import { Store } from '@ngrx/store';
import { navigate } from './core/routing/state/navigation.actions';
import { NavigationPaths } from './core/routing/state/navigation-paths.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'mint-mobile';
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private authService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private appState: Store<AppState>) {
    this.authService.init();
    this.authService.unsubscribe(this._destroying$);
  }

  ngOnInit(): void {
    this.appState.dispatch(navigate({ path: NavigationPaths.Login }));
  }

  changeOfRoutes() {
    this.activeRoute.firstChild.data.subscribe(t => (this.title = t['title']));
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
