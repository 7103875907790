import { Injectable } from '@angular/core';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, tap, filter } from 'rxjs/operators';
import * as FiltersActions from './filters.actions';
import { Router } from '@angular/router';
import * as NavigationActions from '../../../routing/state/navigation.actions';

@Injectable()
export class FilterEffects {
  navigate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FiltersActions.UpdateFilters),
      map(action => {
        return NavigationActions.refresh({ path: this.router.url });
      })
    );
  });

  constructor(private actions$: Actions, private router: Router) {}
}
