import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPaylineSummary from './payline-summary.reducer';
import { PaylineSummaryState } from './payline-summary.model';
import { selectFiltersState } from '@mint-libs/context';

export const selectPaylineSummaryState = createFeatureSelector<PaylineSummaryState>(fromPaylineSummary.PaylineSummaryKey);

export const selectPaylineWithFilterLabel = createSelector(selectPaylineSummaryState, selectFiltersState, (paylineSummary, filters) => {
  return { paylineSummary: paylineSummary, filterLabel: filters.filterLabel };
});
