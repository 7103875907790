// import { DataSourceData } from '../metric-grid/data-source';

export class PCGMetrics {
  pcgName?: string;
  pcgWeight?: string;
  attainmentPercent?: string;
  earningsBeforeCap?: string;
  currencyCode?: string;
  attainmentStatus?: string;
  attainmentHelpText?: string;
  earningsHelpText?: string;
  descText?: string;
  pcgNote?: string;
  pcgTitle?: string;
  attainmentTitle?: string;
  earningsTitle?: string;
  accelerator?: string;
  incentiveType?: string;
  totalTarget?: string;
  totalActual?: string;
  quotaToGo?: string;
  payoutImpact?: string;
  threshold?: string;
  thresholdCap?: string;
  isActive: boolean;
  timeSpan?: string;
  //   dataSource?: DataSourceData;

  //   pccData?: Array<{
  //     totalTarget?: string;
  //     totalActual?: string;
  //     actual?: string;
  //     target?: string;
  //     quotaToGo?: string;
  //     measuringUnit?: string;
  //     weight?: string;
  //     totalRecurring?: string;
  //     refreshDates?: Array<{
  //       dataSource?: string;
  //       nextRefresh?: string;
  //       lastRefresh?: string;
  //     }>;
  //   }>;

  //   chartData?: { descText?: string; dataSet?: Array<{ desc?: string; val?: number; toolTipHtml?: string }> };
}
