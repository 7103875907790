import { ITAInformationActions, ITAInformationActionTypes } from './ita-information.actions';

export const iTAInformationFeatureKey = 'ITAInformation';

export class ITAInformationState {}

export const initialState: ITAInformationState = null;

export function iTAInformationReducer(state = initialState, action: ITAInformationActions): ITAInformationState {
  switch (action.type) {
    case ITAInformationActionTypes.GetITAInformation:
      return state;

    case ITAInformationActionTypes.GetITAInformationSuccess:
      const userProfile = action.payload.data;
      return userProfile;

    case ITAInformationActionTypes.GetITAInformationFailure:
      console.log('Error: ' + action.payload.error);
      return state;

    default:
      return state;
  }
}
