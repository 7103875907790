<div class="header_tile">
    <div class="header_tile__heading" aria-label="heading" tabindex="0" role="heading" aria-level="2">{{heading}}</div>
    <span
    *ngIf="showFilters"
      id="filters-menu"
      name="Filters Menu"
      aria-label="Filters Menu"
      role="button"
      class="icon icon-filter header_tile__filter-icon"
      tabindex="0"
      (click)="openModal(template)"
      (keyup.enter)="openModal(template)"
      analytics-tracker
      [tracker]="'Dashboard_FiltersOpen'"
      [value]="'Button_Click'"
    ></span>
  </div>