import { NgModule } from '@angular/core';
import { MintMetricTileComponent } from './metric-tile/metric-tile.component';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MetricCardComponent } from './metric-card/metric-card.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { ProgressBarComponent } from './progress/progress-bar/progress-bar.component';
import { CommonModule } from '@angular/common';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { ContentLoaderMediumComponent } from './progress/content-loader/content-loader-medium.component';
import { ContentLoaderSmallComponent } from './progress/content-loader/content-loader-small.component';
import { ContentLoaderLargeComponent } from './progress/content-loader/content-loader-large.component';
import { ContentLoaderSingleComponent } from './progress/content-loader/content-loader-single.component';
import { ContentLoaderCardChartComponent } from './progress/content-loader/content-loader-card-chart.component';
import { ContentLoaderGridComponent } from './progress/content-loader/content-loader-grid.component';
import { ContentLoaderIconTextComponent } from './progress/content-loader/content-loader-icon-text.component';
import { ContentLoaderBarChartHorizontalComponent } from './progress/content-loader/content-loader-bar-chart-horizontal.component';
import { ContentLoaderBarChartComponent } from './progress/content-loader/content-loader-bar-chart.component';


@NgModule({
  imports: [CommonModule, NgbModule, FlexLayoutModule, NgProgressModule, NgProgressHttpModule, MatProgressBarModule, ContentLoaderModule, MatIconModule],
  declarations: [
    MintMetricTileComponent,
    MetricCardComponent,
    ProgressBarComponent,
    ContentLoaderSmallComponent,
    ContentLoaderMediumComponent,
    ContentLoaderLargeComponent,
    ContentLoaderSingleComponent,
    ContentLoaderCardChartComponent,
    ContentLoaderGridComponent,
    ContentLoaderIconTextComponent,
    ContentLoaderBarChartHorizontalComponent,
    ContentLoaderBarChartComponent,
  ],
  exports: [
    MintMetricTileComponent,
    MetricCardComponent,
    ProgressBarComponent,
    ContentLoaderSmallComponent,
    ContentLoaderMediumComponent,
    ContentLoaderLargeComponent,
    ContentLoaderSingleComponent,
    ContentLoaderCardChartComponent,
    ContentLoaderGridComponent,
    ContentLoaderIconTextComponent,
    ContentLoaderBarChartHorizontalComponent,
    ContentLoaderBarChartComponent,
  ]
})
export class MintComponentsModule { }
