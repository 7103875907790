import { WorkingHoursActions, WorkingHoursActionTypes } from './working-hours.actions';

export const workingHoursFeatureKey = 'workingHours';

export class WorkingHoursState {}

export const initialState: WorkingHoursState = null;

export function workingHoursReducer(state = initialState, action: WorkingHoursActions): WorkingHoursState {
  switch (action.type) {
    case WorkingHoursActionTypes.GetWorkingHours:
      return state;

    case WorkingHoursActionTypes.GetWorkingHoursSuccess:
      const userProfile = action.payload.data;
      return userProfile;

    case WorkingHoursActionTypes.GetWorkingHoursFailure:
      console.log('Error: ' + action.payload.error);
      return state;

    default:
      return state;
  }
}
