<div ngbNav #compliancenav="ngbNav" class="nav-tabs tile-tabset tabs" [(activeId)]="quotaService.ubiSelectedTab" (activeIdChange)="onTabChange($event)">
  <div [ngbNavItem]="0">
    <a ngbNavLink>Utilization Based Metric</a>
    <ng-template ngbNavContent>
      <div class="utilization-metric-container">
        <div>
          <div class="utilization-metric-header-container">
            <div class="utilization-metric-header-section" *ngFor="let metric of utilizationMetricTotal">
              <div class="utilization-metric-header-title">{{ metric.Value }}</div>
              <div class="utilization-metric-header-subtitle">{{ getTimePeriod(metric.CodePeriod) }}</div>
            </div>
          </div>
        </div>
        <div class="utilization-metric-hours-container" tabindex="0">
          <div class="target-breakup-row">
            <div class="target-breakup-row-label" id="available-hours-label">
              <div>Available Hours</div>
              <mat-icon
                name="Adjustment Hours Info"
                role="button"
                aria-label="Click here"
                aria-hidden="false"
                id="available-hours-info-icon"
                (click)="openBottomSheet()"
                (keyup.enter)="openBottomSheet()"
                analytics-tracker
                [tracker]="'AdjustmentHours_Info'"
                [value]="'AdjustmentHours_Info'"
                >info_outline</mat-icon
              >
            </div>
            <div class="target-breakup-row-value" id="available-hours-value">
              <span>{{ targetHours.ubiTargetBaselineHours / targetHours.worldwide_WorkDay }} days X {{ targetHours.worldwide_WorkDay }} hours =</span>
              <span>{{ customNumber(targetHours.ubiTargetBaselineHours) }} hours</span>
            </div>
          </div>
          <div class="utilization-metric-adjustment-hours-title">
            <div>Adjustments are made for:</div>
          </div>
          <div class="target-breakup-row">
            <div class="target-breakup-row-label">
              <div>Legal work week hours</div>
              <div>((Legal work week - Worldwide workweek) / 5) X # of WorkDays in Fiscal Year</div>
            </div>
            <div class="target-breakup-row-value">
              <span>(({{ targetHours.country_LegalWorkWeekHours }} hours - {{ targetHours.worldwide_WorkWeek }} hours) / 5) X {{ targetHours.workingDays }} days =</span>
              <span>{{ customNumber(convertToNegative(targetHours.ubiLegalWorkweekHours)) }} hours</span>
            </div>
          </div>
          <div class="target-breakup-row">
            <div class="target-breakup-row-label">
              <div>Standard Tasks by Standard Title</div>
              <div>(Tasks by weeks X Legal workweek)</div>
            </div>
            <div class="target-breakup-row-value">
              <span>-{{ targetHours.standardTaskHours }} weeks X {{ targetHours.country_LegalWorkWeekHours }} hours =</span>
              <span>{{ customNumber(convertToNegative(targetHours.ubiStandardTaskHours)) }} hours</span>
            </div>
          </div>
          <div class="target-breakup-row">
            <div class="target-breakup-row-label">
              <div>Subsidiary Public Holiday</div>
              <div>(Holidays x Legal Work Day); Legal Work Day=(Legal Work Week / 5)</div>
            </div>
            <div class="target-breakup-row-value">
              <span>-{{ targetHours.country_Holidays }} days X {{ targetHours.country_LegalWorkWeekHours / 5 }} hours =</span>
              <span>{{ customNumber(convertToNegative(targetHours.ubiPublicHolidayHours)) }} hours</span>
            </div>
          </div>
          <div class="target-breakup-row">
            <div class="target-breakup-row-label">
              <div>Employee Vacation</div>
              <div>(Vacation days x Legal Work Day); Legal Work Day=(Legal Work Week / 5)</div>
            </div>
            <div class="target-breakup-row-value">
              <span>-{{ targetHours.vacationDays }} days X {{ targetHours.country_LegalWorkWeekHours / 5 }} hours =</span>
              <span>{{ customNumber(convertToNegative(targetHours.ubiVacationHours)) }} hours</span>
            </div>
          </div>
          <div class="target-breakup-row" *ngIf="targetHours.ubiTimeWorkPercentage != null">
            <div class="target-breakup-row-label"><div>Part Time Work Percentage</div></div>
            <div class="target-breakup-row-value">
              =<span> {{ customNumber(targetHours.ubiTimeWorkPercentage) }} %</span>
            </div>
          </div>
          <div class="target-breakup-row" *ngIf="targetHours.ubiTargetAdjustment != null">
            <div class="target-breakup-row-label">
              <div>Individual adjustments</div>
              <div>(other adjustments due to applicable factors such as family status, seniority, etc.)</div>
            </div>
            <div class="target-breakup-row-value">
              = <span> {{ customNumber(targetHours.ubiTargetAdjustment) }} hours</span>
            </div>
          </div>
          <div class="target-breakup-row" id="target-hours-section">
            <div class="target-breakup-row-label"><div>Total Full Year Target</div></div>
            <div class="target-breakup-row-value">
              =<span> {{ customNumber(targetHours.target) }} hours</span>
            </div>
          </div>
        </div>
        <div id="utilization-footer"><span class="text-danger">*</span>Note: The information above is your individual full year UBI target hours.</div>
      </div>
    </ng-template>
  </div>
  <div [ngbNavItem]="1">
    <a ngbNavLink>Consumption Metric</a>
    <ng-template ngbNavContent>
      <div class="consumption-metric-container">
        <mint-mobile-quota-summary [quotaPeriods]="quotaPeriods"></mint-mobile-quota-summary>
      </div>
    </ng-template>
  </div>
</div>

<div [ngbNavOutlet]="compliancenav"></div>
