import { Action } from '@ngrx/store';

export enum ITAInformationActionTypes {
  GetITAInformation = 'Get ITA Information',
  GetITAInformationSuccess = 'Get ITA Information Success',
  GetITAInformationFailure = 'Get ITA Information Failure'
}

export class GetITAInformation implements Action {
  readonly type = ITAInformationActionTypes.GetITAInformation;
  constructor(public payload: any) {}
}

export class GetITAInformationSuccess implements Action {
  readonly type = ITAInformationActionTypes.GetITAInformationSuccess;
  constructor(public payload: { data: any }) {}
}

export class GetITAInformationFailure implements Action {
  readonly type = ITAInformationActionTypes.GetITAInformationFailure;
  constructor(public payload: { error: any }) {}
}

export type ITAInformationActions = GetITAInformation | GetITAInformationSuccess | GetITAInformationFailure;
