import { Component, OnInit, OnDestroy } from '@angular/core';
import { MetricTile, PCGMetrics } from '@mint-libs/components';
import { NgbModal, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { PayoutSummaryState } from '../../shared/shared-dashboard/payout-summary/state/payout-summary.model';
import { Store } from '@ngrx/store';
import { PaylineSummaryState } from '../../shared/shared-dashboard/payline-summary/state/payline-summary.model';
import { selectPaylineSummaryState, selectPaylineWithFilterLabel } from '../../shared/shared-dashboard/payline-summary/state/payline-summary.selectors';
import { AppState } from '../../reducers';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MetricViewComponent } from '../../shared/shared-dashboard/metric-view/metric-view.component';
import { Payline, Formatter, PercentagePipe } from '@mint-libs/common';
import { LegendsService } from '../../shared/shared-dashboard/legends/legends.service';
import { LoggerService } from '../../core/logging/logger.service';
import { selectTargetAndRefreshState } from '../../shared/shared-dashboard/target-summary/state/target-summary.selectors';
import { BaseSellerDashboardComponent } from '../base-seller-dashboard.component';

@Component({
  selector: 'mint-mobile-rbi-dashboard',
  templateUrl: './rbi-seller-dashboard.component.html',
  styleUrls: ['../base-seller-dashboard.component.scss'],
  providers: [LegendsService, NgbNavConfig]
})
export class RbiSellerDashboardComponent extends BaseSellerDashboardComponent implements OnInit, OnDestroy {
  ytdPCGs: PCGMetrics[];
  weightedAttainmentKPI: MetricTile;
  isOptedOut: boolean;

  constructor(
    protected modalService: NgbModal,
    protected appState: Store<AppState>,
    protected payoutSummaryState: Store<PayoutSummaryState>,
    protected paylineSummaryState: Store<PaylineSummaryState>,
    private bottomSheet: MatBottomSheet,
    protected formatter: Formatter,
    private percentagePipe: PercentagePipe,
    private legends: LegendsService,
    protected logger: LoggerService,
    protected navConfig: NgbNavConfig
  ) {
    super(appState, formatter, logger, paylineSummaryState, payoutSummaryState, modalService);
    navConfig.keyboard = true;
  }
  ngOnInit() {
    super.loadData();
    this.resetKPIs();
    this.loadKPIs();
    super.getConfigData();
    this.setMetricTiles();
  }

  resetKPIs() {
    super.resetKPIs();
    this.weightedAttainmentKPI = new MetricTile();
    this.weightedAttainmentKPI.isMobile = true;
    this.attainmentTileLoaded = false;
  }

  loadKPIs() {
    super.loadKPIs();
    this.paylineSummaryState.select(selectPaylineWithFilterLabel).subscribe(paylineWithFilterLabel => {
      const paylineSummary = paylineWithFilterLabel.paylineSummary;
      if (paylineSummary && paylineSummary.totalWeightedAttainment) {
        this.attainmentTileLoaded = true;
        this.setWeightedAttainmentKPI(paylineSummary, paylineWithFilterLabel.filterLabel);
      }
    });
  }

  setWeightedAttainmentKPI(allPaylines: any, filterLabel: string) {
    this.weightedAttainmentKPI = new MetricTile();
    // this.weightedAttainmentKPI.svg = this.sanitizer.bypassSecurityTrustHtml(this.svg.iconTrendArrow);
    this.weightedAttainmentKPI.amount = this.percentagePipe.transform(allPaylines.totalWeightedAttainment);
    this.weightedAttainmentKPI.amountTitle = `Weighted Attainment (${filterLabel})`;
    this.weightedAttainmentKPI.isMobile = true;
    this.weightedAttainmentKPI.iconName = 'trending_up';
  }

  setMetricTiles() {
    this.paylineSummaryState.select(selectPaylineSummaryState).subscribe((paylineSummary: PaylineSummaryState) => {
      this.ytdPCGs = [];
      if (!paylineSummary) {
        return;
      }
      this.paylinesLoaded = true;
      paylineSummary.paylines.forEach(function(payline: Payline) {
        const pcgMetrics = new PCGMetrics();
        pcgMetrics.pcgName = payline.pcgName;
        pcgMetrics.pcgWeight = Math.round(payline.weightage).toString();
        pcgMetrics.attainmentPercent = payline.attainment.toFixed(2);
        pcgMetrics.attainmentStatus = this.legends.getCssClass(payline);
        pcgMetrics.attainmentTitle = 'Attainment';
        pcgMetrics.incentiveType = null;

        // Check for OCP PPM Metric in metric card
        const isZeroWeight = false;
        if (pcgMetrics.pcgWeight === '0') {
          this.isZeroWeight = true;
        }

        pcgMetrics.earningsBeforeCap = this.formatter.formatWithCurrencyAndRoundOff(payline.pcgEarnings, payline.pcgCurrency, this.isZeroWeight);
        pcgMetrics.currencyCode = payline.pcgCurrency;
        pcgMetrics.earningsTitle = 'Earnings';
        this.ytdPCGs.push(pcgMetrics);
      }, this);
      super.setActiveSlide(0);
    });
  }

  onTileClick(pcgMetrics: PCGMetrics) {
    this.openBottomSheet(pcgMetrics);
  }

  openBottomSheet(pcgMetrics: PCGMetrics): void {
    const targetAndRefreshSummary$ = this.appState.select(selectTargetAndRefreshState);
    this.bottomSheet.open(MetricViewComponent, {
      data: { bucketName: pcgMetrics.pcgName, attainmentStatus: pcgMetrics.attainmentStatus, metricViewData: targetAndRefreshSummary$, quotaToGoLabel: 'Quota To Go' }
    });
  }

  ngOnDestroy(): void {
    this.bottomSheet.dismiss();
  }
}
