import { Injectable } from '@angular/core';
import { FiltersService } from '../layout/filters/filters.service';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ComplianceNavigationPaths } from './models/compliance-navigation-paths.enum';
import { ComplianceStatusState } from './compliance-dashboard/state/compliance-status.reducer';
import { selectComplianceStatusState } from './compliance-dashboard/state/compliance-status.selectors';
import { DocumentType } from './models/document-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ComplianceService {
  selectedTab = 0;
  complianceTabs: any;
  envelopeId: string;
  documentStatusId: any;
  allPPAs = [];
  isPPAFiltersApplicable: boolean;
  signedDate: any;
  showMainContentHeader = true;
  participationId: any;

  constructor(public filtersService: FiltersService, private router: Router, private activateRoute: ActivatedRoute, private complianceStatusState: Store<ComplianceStatusState>) {}

  getTabs(selectedFiscalYearLabel: string, titles: any) {
    this.isPPAFiltersApplicable = this.getPPAList().isMultiplePPA;
    this.complianceTabs = [
      {
        title: 'My Compliance Status for ' + selectedFiscalYearLabel,
        applicableFilters: this.filtersService.initializeApplicableFiltersConfig(true, false, false, false)
      },
      {
        title: titles[0],
        applicableFilters: this.filtersService.initializeApplicableFiltersConfig(false, false, false, this.isPPAFiltersApplicable)
      },
      {
        title: titles[1],
        applicableFilters: this.filtersService.initializeApplicableFiltersConfig(true, true, false, false)
      }
    ];
  }

  loadPPA($event) {
    this.envelopeId = $event.EnvelopeId;
    this.documentStatusId = $event.DocumentStatusId;
    this.router.navigateByUrl(this.activateRoute.snapshot['_routerState'].url + ComplianceNavigationPaths.PlanParticipantAgreement);
  }

  getPPAList() {
    let list: any;
    this.complianceStatusState.select(selectComplianceStatusState).subscribe(complianceStatusState => {
      if (!complianceStatusState) {
        return;
      }
      list = complianceStatusState;
    });

    this.allPPAs = [];
    list.forEach(document => {
      if (document.documentType === DocumentType.PlanParticipantAgreement) {
        this.allPPAs.push(document);
      }
    });
    return { ppaList: this.allPPAs, isMultiplePPA: this.allPPAs.length > 1 };
  }
}
