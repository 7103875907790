import { GetTargetSummaryActions, GET_TARGET_SUMMARY, GET_TARGET_SUMMARY_SUCCESS, GET_TARGET_SUMMARY_FAILURE } from './target-summary.actions';
import { TargetSummaryState } from './target-summary.model';

export const TargetSummaryKey = 'targetSummary';

export const initialState: TargetSummaryState = null;

export function TargetSummaryReducer(state = initialState, action: GetTargetSummaryActions): any {
  switch (action.type) {
    case GET_TARGET_SUMMARY:
      return null;
    case GET_TARGET_SUMMARY_SUCCESS:
      const targetSummary = action.payload.data;
      return targetSummary;
    case GET_TARGET_SUMMARY_FAILURE:
      return state;
    default:
      return state;
  }
}
