import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectImpersonationStateWithProfile } from '../core/context/impersonation/state/impersonation.selectors';
import { ImpersonationState } from '../core/context/impersonation/state/impersonation.state';
import { NavigationPaths } from '../core/routing/state/navigation-paths.enum';
import { ApplicableFiltersConfig } from '../layout/filters/applicable-filters-config';
import { FiltersService } from '../layout/filters/filters.service';
import { SidebarService } from '../layout/sidebar/sidebar.service';
import { GetComplianceStatus } from './compliance-dashboard/state/compliance-status.actions';
import { ComplianceStatusState } from './compliance-dashboard/state/compliance-status.reducer';
import { selectComplianceStatusWithProfileandFilterLabels } from './compliance-dashboard/state/compliance-status.selectors';
import { ComplianceService } from './compliance.service';
import { DocumentType } from './models/document-type.enum';

@Component({
  selector: 'mint-mobile-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnInit {
  complianceDocumentsStatus: any;
  modalRef;
  complianceFiltersConfig: ApplicableFiltersConfig;
  selectedFisclaYearLabel: string;
  isLoaded = false;
  applicableFilters: any;
  tabTitle: any;
  showMainContentHeader: any;
  showFilters: any;

  constructor(public filtersService: FiltersService, private complianceStatusState: Store<ComplianceStatusState>, private complianceService: ComplianceService, private activeRoute: ActivatedRoute,
    private impersonationState: Store<ImpersonationState>,  private sidebarService: SidebarService) {}

  ngOnInit() {
    this.complianceStatusState.dispatch(new GetComplianceStatus(null));
    this.complianceStatusState.select(selectComplianceStatusWithProfileandFilterLabels).subscribe(complianceStatusState => {
      if (!(complianceStatusState && complianceStatusState.complianceStatus)) {
        return;
      }
      this.complianceDocumentsStatus = complianceStatusState;
      this.selectedFisclaYearLabel = this.complianceDocumentsStatus.fiscalYearLabel;
      this.complianceService.getTabs(this.selectedFisclaYearLabel, this.getTabTitles());
      this.isLoaded = true;
    });
    this.refreshNavigationState();
  }

  // Revalidates sidebar on every reloads
  private refreshNavigationState() {
    const impersonationWithUserProfileState$ = this.impersonationState.select(selectImpersonationStateWithProfile);
    impersonationWithUserProfileState$.subscribe(impersonationWithUserProfileState => {
      if (!(impersonationWithUserProfileState && impersonationWithUserProfileState.userProfile)) {
        return false;
      }
      // Show all when impersonating
      if (impersonationWithUserProfileState.impersonationState.isImpersonating) {
        this.sidebarService.setSidebarItemList(NavigationPaths.Dashboard);
      }
      // Show based on compliance status
      if (!impersonationWithUserProfileState.userProfile.isQuotaAcknowledgementPending() && !impersonationWithUserProfileState.userProfile.isQuotaFCAApprovalPending()) {
        this.sidebarService.setSidebarItemList(NavigationPaths.Dashboard);
      } else {
        this.sidebarService.setSidebarItemList(NavigationPaths.Compliance);
      }
    });
  }

  getTabTitles() {
    const titleList = this.complianceDocumentsStatus.complianceStatus
      .map(title => {
        return [title.docType, title.documentType];
      })
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort(function(a, b) {
        return a.documentType - b.documentType;
      });

    const incentiveType = this.complianceDocumentsStatus.complianceStatus
      .map(document => {
        return document.incentiveType;
      })
      .filter(document => {
        return document !== undefined && document !== null;
      })
      .toString();

    if (incentiveType === 'UBI') {
      titleList.map(title => {
        if (title[1] === DocumentType.QuotaAcknowledgement) {
          title[0] = 'Quota and Target Acknowlegement';
        }
      });
    }
    return titleList.map(titles => {
      return titles[0];
    });
  }

  changeOfRoutes() {
    this.activeRoute.firstChild.data.subscribe(t => (this.complianceService.selectedTab = t['tabId'] - 1));
    this.getTabInfo();
  }

  getTabInfo() {
    this.applicableFilters = this.complianceService.complianceTabs[this.complianceService.selectedTab].applicableFilters;
    this.tabTitle = this.complianceService.complianceTabs[this.complianceService.selectedTab].title;
    this.showFilters = this.complianceService.isPPAFiltersApplicable || this.complianceService.selectedTab !== 1;
  }
}
