import { Payline } from './payline.model';
import { PaylinePoint } from './payline-point.model';
import { Type, Expose } from 'class-transformer';
import { IDeserializable } from '../../base/iDeserializable';
import { BaseModel } from '../../base/base.model';
import { plainToClass } from 'class-transformer';

export class PaylineDetail extends BaseModel implements IDeserializable<PaylineDetail> {
  @Expose() planName: string;
  @Expose() planStartDate: Date | string | null;
  @Expose() planEndDate: Date | string | null;
  @Expose()
  @Type(getPaylineType)
  @Expose()
  payline: Payline;
  @Expose()
  @Type(getPaylinePointType)
  @Expose()
  points: PaylinePoint[];
  @Expose()
  @Type(getPaylinePointType)
  @Expose()
  paylinePoints: PaylinePoint[];
  @Expose() totalWeightedAttainment: number | null;
  @Type(getPaylineType)
  @Expose()
  paylines: Payline[];

  deserialize(from: object): PaylineDetail {
    const paylineDetail = plainToClass<PaylineDetail, object>(PaylineDetail, from, this.classTransformOptions);
    return paylineDetail;
  }
}

export function getPaylineType() {
  return Payline;
}

export function getPaylinePointType() {
  return PaylinePoint;
}
