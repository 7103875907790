import { Action } from '@ngrx/store';

export enum UtilizationMetricActionTypes {
  GetUtilizationMetric = 'Get Utilization Metric',
  GetUtilizationMetricSuccess = 'Get Utilization Metric Success',
  GetUtilizationMetricFailure = 'Get Utilization Metric Failure'
}

export class GetUtilizationMetric implements Action {
  readonly type = UtilizationMetricActionTypes.GetUtilizationMetric;
  constructor(public payload: any, public periodStatus: string) {}
}

export class GetUtilizationMetricSuccess implements Action {
  readonly type = UtilizationMetricActionTypes.GetUtilizationMetricSuccess;
  constructor(public payload: { data: any }) {}
}

export class GetUtilizationMetricFailure implements Action {
  readonly type = UtilizationMetricActionTypes.GetUtilizationMetricFailure;
  constructor(public payload: { error: any }) {}
}

export type UtilizationMetricActions = GetUtilizationMetric | GetUtilizationMetricSuccess | GetUtilizationMetricFailure;
