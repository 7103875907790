<div class="metric metric__container">
  <div class="metric__header">
    <span class="metric__header__title" tabindex="0" role="heading">{{ bucketName }}</span>
    <button class="mat-icon-container" aria-label="Close view" tabindex="0" (click)="close()" (keyup.enter)="close()" analytics-tracker [tracker]="'Close Metric View'" [value]="'Button_Click'">
      <mat-icon aria-hidden="false">close</mat-icon>
    </button>
  </div>
  <div>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let pccMetrics of pccMetricsList; first as isFirst" [expanded]="isSingleMetric">
        <mat-expansion-panel-header>
          <div style="width: 100%; margin-right:10px;">
            <div class="metric__attainment">
              <span class="metric__attainment__name">{{ pccMetrics.name }}</span>
              <span class="metric__attainment__value">{{ pccMetrics.attainment }}%</span>
            </div>
            <div class="metric__progress">
              <mat-progress-bar mode="determinate" class="mat-progress-bar" [ngClass]="attainmentStatus" value="{{ pccMetrics.attainment }}"></mat-progress-bar>
            </div>
          </div>
        </mat-expansion-panel-header>
        <div>
          <div class="metric__detail">
            <span class="metric__detail__header" tabindex="0" role="heading" aria-label="Metric Performance" aria-level="3">Metric Performance</span>
            <div class="metric__detail__content">
              <div class="metric__measure" *ngIf="pccMetrics.isTargetDetailVisible">
                <span class="metric__measure__text" tabindex="0">{{ pccMetrics.targetLabel }}</span>
                <span class="metric__measure__val" tabindex="0">{{ pccMetrics.totalTarget }}</span>
              </div>
              <div class="metric__measure" *ngIf="pccMetrics.isActualsDetailVisible">
                <span class="metric__measure__text" tabindex="0">{{ pccMetrics.actualsLabel }}</span>
                <span class="metric__measure__val" tabindex="0">{{ pccMetrics.totalActuals }}</span>
              </div>
              <div class="metric__measure" *ngIf="pccMetrics.isQuotaToGoDetailVisible">
                <span class="metric__measure__text" tabindex="0">{{ pccMetrics.quotaToGoLabel }} </span>
                <span class="metric__measure__val" tabindex="0">{{ pccMetrics.quotaToGo }}</span>
              </div>
              <div class="metric__measure">
                <span class="metric__measure__text" tabindex="0">Weight: </span>
                <span class="metric__measure__val" tabindex="0">{{ pccMetrics.weight }}%</span>
              </div>
            </div>
          </div>
          <div class="metric__datasource">
            <span class="metric__datasource__header" tabindex="0" role="heading" aria-label="Metric Data Source" aria-level="3">Metric Data Source</span>
            <div class="metric__datasource__grid">
              <div class="metric__datasource__grid__row" *ngFor="let sourceRefreshInfo of pccMetrics.sourceRefreshInfoList">
                <div class="metric__datasource__grid__row__cell">
                  <div>
                    <div class="metric__datasource__name" tabindex="0" role="heading" [attr.aria-label]="sourceRefreshInfo.name" aria-level="4">{{ sourceRefreshInfo.name }}</div>
                    <div class="metric__datasource__date">
                      <span class="last" tabindex="0">Last: </span> <span class="last" tabindex="0">{{ sourceRefreshInfo.lastRefresh }}</span>
                    </div>
                    <div class="metric__datasource__date">
                      <span class="next" tabindex="0">Next: </span> <span class="next" tabindex="0">{{ sourceRefreshInfo.nextRefresh }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
