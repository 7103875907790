import { SourceRefreshInfo } from './source-refresh.model';

export class PCCMetrics {
  name: string;
  attainment: string;
  totalTarget: string;
  targetLabel: string;
  isTargetDetailVisible: boolean = true;
  totalActuals: string;
  actualsLabel: string;
  isActualsDetailVisible: boolean = true;
  actual: string;
  target: string;
  quotaToGoLabel: string;
  quotaToGo: string;
  isQuotaToGoDetailVisible: boolean = true;
  measuringUnit: string;
  weight: string;
  totalRecurring: string;
  sourceRefreshInfoList: SourceRefreshInfo[];
}
