import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Store } from '@ngrx/store';
import { FilterState } from '../../../../../core/context/filters/state/filters.reducer';
import { selectFiltersState } from '../../../../../core/context/filters/state/filters.selectors';
import { CustomNumberPipe } from '@mint-libs/common';

@Component({
  selector: 'mint-mobile-utilization-metric-tooltip',
  templateUrl: './utilization-metric-tooltip.component.html',
  styleUrls: ['./utilization-metric-tooltip.component.scss']
})
export class UtilizationMetricTooltipComponent {
  targetHours: any;
  fiscalYearLabel: any;
  fiscalYear: any;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<UtilizationMetricTooltipComponent>,
    private filtersState: Store<FilterState>,
    private customNumberPipe: CustomNumberPipe,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.targetHours = data.targetHours;
    this.filtersState.select(selectFiltersState).subscribe(state => {
      if (!state) {
        return;
      }
      const filters: any = state;
      this.fiscalYearLabel = filters.fiscalYearLabel;
      this.fiscalYear = filters.selectedFilters.fiscalYear;
    });
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  customNumber(number) {
    return this.customNumberPipe.transform(number);
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}
