import { SafeHtml } from '@angular/platform-browser';

export class MetricTile {
  amount?: string;
  amountTitle?: string;
  currencyCode?: string;
  fiscalQuarter?: string;
  svg?: SafeHtml;
  iconName: string;
  toolTipHeading?: string;
  toolTipText?: string;

  isMobile?: boolean;
}
