import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { QuotaService } from '../../quota.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'mint-mobile-quota-summary',
  templateUrl: './quota-summary.component.html',
  styleUrls: ['./quota-summary.component.scss']
})
export class QuotaSummaryComponent implements OnInit {
  @Input() quotaPeriods: any;

  public supportHref: string;

  constructor(private router: Router, private activateRoute: ActivatedRoute, public quotaService: QuotaService) {}

  ngOnInit() {
    this.supportHref = environment.supportHref;
  }

  navigateToQuotaDetails(period) {
    this.quotaService.selectedTimePeriod = period;
    this.router.navigateByUrl(this.activateRoute.snapshot['_routerState'].url + '/' + period.Name);
  }
}
