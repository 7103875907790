import { Component, OnInit } from '@angular/core';
import { Formatter, ToDateFormatPipe } from '@mint-libs/common';
import { PayoutSummaryState } from './state/payout-summary.model';
import { selectPayoutSummaryState } from './state/payout-summary.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { GetPayoutSummary } from './state/payout-summary.actions';
import { BaseComponent } from '../../../shared/base/base.component';
import { selectConfigurationState } from '@mint-libs/configuration';
import { MessagesService } from '@mint-libs/context';

@Component({
  selector: 'mint-mobile-payout-summary',
  templateUrl: './payout-summary.component.html',
  styleUrls: ['./payout-summary.component.scss']
})
export class PayoutSummaryComponent extends BaseComponent implements OnInit {
  slideConfig = { slidesToShow: 1, slidesToScroll: 1, arrows: true, waitForAnimate: false, accessibility: false };
  payoutData: any[] = [];
  rowData = [];
  payoutSummaryDataset = [];
  helper = {
    prev: null,
    index: 0,
    codePeriod: '',
    adjustment: false
  };
  filters: any;
  payoutSummary: PayoutSummaryState;
  config: any;
  isPayoutUnavailable: boolean;
  payoutUnavailableMessage = <any>{};
  halfYearlyQuotaNotSupportedPartitions = ['Q3', 'Q4', 'H2'];

  constructor(
    protected appState: Store<AppState>,
    private payoutSummaryState: Store<PayoutSummaryState>,
    private formatter: Formatter,
    private formatDate: ToDateFormatPipe,
    private messagesService: MessagesService
  ) {
    super(appState);
  }

  ngOnInit() {
    this.getConfigData();
    this.getPayoutData();
  }

  private getConfigData() {
    this.appState.select(selectConfigurationState).subscribe(state => {
      if (!state) {
        return;
      }
      this.config = state;
      this.isPayoutUnavailable = this.config.isPayoutUnavailable && this.config.configs.latestFiscalYear === this.selectedFiscalYear;
      if (this.isPayoutUnavailable) {
        this.messagesService.getFullMessage('PayoutUnavailibiltyMessage').subscribe(msg => {
          this.payoutUnavailableMessage['Title'] = msg[0]['title'];
          this.payoutUnavailableMessage['Text'] = msg[0]['text'];
        });
      }
    });
  }

  private getPayoutData() {
    this.payoutSummary = null;
    this.payoutSummaryDataset = [];
    this.payoutData = [];
    this.appState.dispatch(new GetPayoutSummary(null));
    this.payoutSummaryState.select(selectPayoutSummaryState).subscribe(payout => {
      if (!payout) {
        return;
      }
      this.payoutSummary = payout;
      if (this.payoutSummary && this.payoutSummary.applicablePayouts) {
        this.payoutSummary.applicablePayouts.forEach(applicablePayout => {
          this.payoutSummaryDataset.push(this.setPayoutData(applicablePayout));
        });
        this.payoutData = this.payoutSummaryDataset;
        // Remove H2 timeframes if H2 Quota is unavailable
        if (this.config.isHalfYearlyQuotaSupported && this.config.configs.latestFiscalYear === this.selectedFiscalYear) {
          this.payoutData = this.payoutData.filter(function(val) {
            return this.halfYearlyQuotaNotSupportedPartitions.indexOf(val.codePeriod.split('-')[1].split(' ')[0]) === -1 ? true : false;
          }, this);
        }
      }
    });
  }

  private setPayoutData(payoutSummaryItem) {
    // detailViewTableIndex used to indicate the correct payout report incase of multiple payment in same quarter
    let detailViewTabIndex = 0;

    if (this.helper.prev === payoutSummaryItem.CodePeriod) {
      this.helper.adjustment = true;
      this.helper.codePeriod =
        payoutSummaryItem.codePeriod + ' (' + this.getShortDate(payoutSummaryItem.partition.duration.startDate) + ' - ' + this.getShortDate(payoutSummaryItem.partition.duration.endDate) + ')';
      detailViewTabIndex++;
    } else {
      this.helper.codePeriod =
        payoutSummaryItem.codePeriod + ' (' + this.getShortDate(payoutSummaryItem.partition.duration.startDate) + ' - ' + this.getShortDate(payoutSummaryItem.partition.duration.endDate) + ')';
      this.helper.prev = payoutSummaryItem.codePeriod;
      this.helper.adjustment = false;
      detailViewTabIndex = 0;
    }

    let payrollPaymentAmount = payoutSummaryItem.payrollPayment;
    let payrollPaymentCode = payoutSummaryItem.currencyCode;
    if (payoutSummaryItem.isCountryChange) {
      payrollPaymentAmount = payoutSummaryItem.transferredCurrencyAmount;
      payrollPaymentCode = payoutSummaryItem.transferredCurrencyCode;
    }

    const yTDEarnings = this.getFormattedCurrency(payoutSummaryItem.ytdEarnings, payoutSummaryItem.currencyCode);
    const prevQuarterPayout = this.getFormattedCurrency(payoutSummaryItem.advances, payoutSummaryItem.currencyCode);
    let covidAdjustmentAmount = 0;
    if (payoutSummaryItem.covidAdjustments) {
      payoutSummaryItem.covidAdjustments.forEach(adjustment => {
        covidAdjustmentAmount += adjustment.PayrollAdjustment;
      });
    }

    const ytdPaymentAdjustmentAndRecoveries = this.getFormattedCurrency(payoutSummaryItem.adjustments + payoutSummaryItem.recoveryAmount + covidAdjustmentAmount, payoutSummaryItem.currencyCode);
    let netPayment: any;
    let publicationDate: any;
    let payoutDone: any;
    let stepId: any;

    if (payoutSummaryItem.partition && payoutSummaryItem.partition.stepId) {
      stepId = payoutSummaryItem.partition.stepId;
    } else {
      // Fallback machanism in case the stepid is NOT received from API,
      // This logic is borrowed from Old Dashboard (Angular JS)
      stepId = this.getStep(this.helper.codePeriod);
    }

    if (payoutSummaryItem.paymentStatus === 'Published') {
      netPayment = this.getFormattedCurrency(payrollPaymentAmount, payrollPaymentCode);
      if (payoutSummaryItem.publicationDate) {
        publicationDate = this.formatDate.transform(payoutSummaryItem.publicationDate, 'MMM d, yyyy').toLocaleString();
      }

      payoutDone = true;
    } else {
      netPayment = this.getFormattedCurrency(payoutSummaryItem.netPaymentAmount, payoutSummaryItem.currencyCode) + ' (Projected)';

      if (payoutSummaryItem.paymentStatus === 'Paid In Next Quarter') {
        // This condition indicates that the Partipant for this Quarter is account in next Quarter
        // There could be instances where we do not pay a seller in Q1 but pay him/her in Q2.
        // The pending payment from Q1 is accounted for in Q2.
        publicationDate = 'Not Eligible';
      } else {
        // This condition indicates that payment is not done for this Quarter as well as any of the future Quarter
        publicationDate = 'Pending payment issuance';
      }

      payoutDone = false;
    }
    return {
      codePeriod: this.helper.codePeriod,
      adjustment: this.helper.adjustment,
      yTDEarnings: yTDEarnings,
      prevQuarterPayout: prevQuarterPayout,
      ytdPaymentAdjustmentAndRecoveries: ytdPaymentAdjustmentAndRecoveries,
      netPaymentAmount: netPayment,
      calcDate: publicationDate,
      payoutDone: payoutDone,
      publicationId: payoutSummaryItem.PublicationId,
      stepId: stepId,
      detailViewTabIndex: detailViewTabIndex
    };
  }

  private getShortDate(date: string) {
    return this.formatDate.transform(date, 'MMM');
  }

  private getFormattedCurrency(amount: any, currency: any) {
    return this.formatter.formatWithCurrencyAndRoundOff(amount, currency);
  }

  private getStep(data) {
    let stepId = 1;
    if (data.row.TimeFrame.indexOf('Q2') !== -1) {
      stepId = 2;
    } else if (data.row.TimeFrame.indexOf('Q3') !== -1) {
      stepId = 3;
    } else if (data.row.TimeFrame.indexOf('Q4') !== -1) {
      stepId = 4;
    }
    return stepId;
  }

  slickInit(e) {
    console.log('slick initialized');
    setTimeout(() => {
      e.slick.currentSlide = this.getActiveSlide();
    });
  }

  getActiveSlide(): number {
    if (!this.payoutData || this.payoutData.length === 0) {
      return;
    }
    let activePayoutIndex = 0;
    const duePayout = this.payoutData.find((payout, index) => {
      if (!payout.payoutDone) {
        activePayoutIndex = index;
        payout.isActive = true;
        return true;
      }
    }, activePayoutIndex);

    if (!duePayout || this.isOptedOut) {
      this.payoutData.forEach(payout => (payout.isActive = 0));
      activePayoutIndex = this.payoutData.findIndex(payout => {
        if (payout.stepId === this.selectedStepId) {
          payout.isActive = true;
          return true;
        }
      }, this);
    }
    return activePayoutIndex;
  }

  breakpoint() {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
    this.payoutData.forEach((element, index) => {
      element.isActive = index === e.currentSlide ? true : false;
    });
  }

  beforeChange() {
    console.log('beforeChange');
  }

  dragStart() {
    console.log('clicked in');
  }
  dragEnd() {
    console.log('clicked out');
  }
}
