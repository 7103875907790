<span class="skip-mainContent" role="link" (keyup.enter)="skipMainContent()" (click)="skipMainContent()" tabindex="0" aria-label="Skip to main content">Skip</span>
<header id="header" role="banner">
  <mint-mobile-back [isBackIconVisible]="headerService.headerConfig.isBackIconVisible"></mint-mobile-back>
  <div>
    <mat-icon
      *ngIf="headerService.headerConfig.isHamburgerIconVisible"
      name="Navigation Menu"
      role="button"
      style="font-size: 14pt"
      class="hamburger-icon-container"
      aria-label="Navigation Menu"
      tabindex="0"
      id="hamburger-menu"
      (click)="toggleSidebar()"
      (keyup.enter)="toggleSidebar()"
      aria-hidden="false"
      >menu</mat-icon
    >
    <h1 class="title" id="mobile_title" *ngIf="title !== 'Notifications' && !headerService.headerConfig.isHelpIconVisible" [attr.aria-label]="title" tabindex="0">{{ title }}</h1>
    <h1 class="title" id="mobile_title" *ngIf="title === 'Notifications'" tabindex="-1">{{ title }}({{ activeNotifications }})</h1>
    <h1 *ngIf="headerService.headerConfig.isHelpIconVisible" class="title" id="mobile_title">Error: {{ headerService.title }}</h1>
  </div>
  <mint-impersonation-notification *ngIf="headerService.headerConfig.isImpersonationBannerVisible"> </mint-impersonation-notification>
  <div style="margin: auto 0 auto auto;">
    <div class="pull-right" style="display: flex;">
      <div
        *ngIf="headerService.headerConfig.isNotificationIconVisible"
        aria-label="Notification Menu"
        tabindex="0"
        class="notification-container"
        id="NotifButton"
        role="button"
        title="Notifications"
        (click)="navigateToNotification('notifications')"
        (keyup.enter)="navigateToNotification('notifications')"
      >
        <div class="btn-icon" (click)="navigateToNotification('notifications')">
          <span class="icon icon-bell-outline" analytics-tracker [tracker]="'NotificationClick'" [value]="'Icon_Click'"></span>
          <span class="note-num" *ngIf="activeNotifications">{{ activeNotifications }}</span>
        </div>
      </div>
      <div
        *ngIf="headerService.headerConfig.isHelpIconVisible"
        role="link"
        class="support-button"
        tabindex="0"
        aria-label="Support icon"
        analytics-tracker
        [tracker]="'ErrorScreen_SupportLink'"
        [value]="'SupportIcon_Clicked'"
        (keyup.enter)="onSuportIconClick(supportLink)"
      >
        <mat-icon aria-hidden="false"> <a href="https://microsoft.service-now.com/wwic?id=sc_cat_item&sys_id=b34393d9131fd2042620f0912244b0e6" target="_blank">help_outline</a></mat-icon>
      </div>
      <div *ngIf="headerService.headerConfig.isAvatarVisible" class="avatar-container" [ngClass]="{ 'avtaar-adj': activeNotifications != 0 }">
        <mint-mobile-avatar></mint-mobile-avatar>
      </div>
      <!-- <div class="list__item__icon" style="margin:5px;">
        <ngx-avatar name="{{ name }}" initialsSize="2" size="35" [round]="true" src="data:image/jpeg;base64,{{ thumbnailPhoto }}" textSizeRatio="2.5"></ngx-avatar>
      </div> -->
    </div>
  </div>
</header>
