import { Action, createReducer, on } from '@ngrx/store';
import * as FiltersActions from './filters.actions';
// import { Filters } from '../filters.model';
import { act } from '@ngrx/effects';
import { UserPreference, Filters } from '@mint-libs/context';

export const filtersFeatureKey = 'filters';

export interface FilterState extends Filters {}

export const initialState: FilterState = null;

const reducer = createReducer(
  initialState,
  on(FiltersActions.SetFilters, (state, action) => {
    const filters: Filters = {
      filterLabel: getFilterLabel(action.userProfile.defaultFilter),
      fiscalYearLabel: getFiscalYearLabel(action.userProfile.defaultFilter.fiscalYear),
      fiscalYears: action.userProfile.fiscalYears,
      standardTitles: action.userProfile.standardTitles,
      defaultFilters: action.userProfile.defaultFilter,
      selectedFilters: action.selectedFilters ? action.selectedFilters : action.userProfile.defaultFilter
    };
    return filters;
  }),
  on(FiltersActions.UpdateFilters, (state, action) => {
    return {
      ...state,
      filterLabel: getFilterLabel(action.selectedFilters),
      fiscalYearLabel: getFiscalYearLabel(action.selectedFilters.fiscalYear),
      selectedFilters: action.selectedFilters
    };
  })
);

const getFilterLabel = function(selectedFilters: UserPreference): string {
  const fiscalyear = getFiscalYearLabel(selectedFilters.fiscalYear);
  const selectedStep = 'Q' + selectedFilters.stepId;
  const filterLabel = `${fiscalyear}-${selectedStep}`;
  return filterLabel;
};

const getFiscalYearLabel = function(fiscalYear: string): string {
  const fiscalYearLabel = 'FY' + fiscalYear.substring(2);
  return fiscalYearLabel;
};

export function filtersReducer(state: FilterState | undefined, action: Action) {
  return reducer(state, action);
}
