<div *ngIf="!payoutSummary"><mint-content-loader-medium></mint-content-loader-medium></div>
<div *ngIf="payoutSummary && !isPayoutUnavailable">
  <ngx-slick-carousel
    id="payout-tile"
    class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    (init)="slickInit($event)"
    (breakpoint)="breakpoint($event)"
    (afterChange)="afterChange($event)"
    (beforeChange)="beforeChange($event)"
    (mousedown)="dragStart()"
    (mouseup)="dragEnd()"
    analytics-tracker
    [tracker]="'Dashboard_PayoutSlide'"
    [value]="'PayoutCarousel_Slide'"
  >
    <div ngxSlickItem *ngFor="let payout of payoutData; let i = index" class="slide">
      <div class="payout-tile" [ngClass]="{ done: payout.payoutDone }">
        <div class="payout-tile-title">
          <div class="payout-tile-cell left">
            <span class="payout-tile-header">Time Frame</span> <span>{{ payout.codePeriod }}</span>
          </div>
          <div class="payout-tile-cell right payout-tile-column-gap">
            <span class="payout-tile-header right">Calculation Date</span> <span class="payout-tile-header-value right">{{ payout.calcDate }}</span>
          </div>
        </div>
        <div class="payout-tile-splitter"></div>
        <div class="payout-tile-content">
          <div class="mainContent-row">
            <span class="payout-tile-leftcolumn-cell">YTD Earnings (A)</span> <span class="payout-tile-rightcolumn-cell payout-tile-column-gap">{{ payout.yTDEarnings }}</span>
          </div>
          <div class="mainContent-row">
            <span class="payout-tile-leftcolumn-cell">Paid in Previous quarters (B)</span> <span class="payout-tile-rightcolumn-cell payout-tile-column-gap">{{ payout.prevQuarterPayout }}</span>
          </div>
          <div class="mainContent-row">
            <span class="payout-tile-leftcolumn-cell">YTD Payment Adjustments and Recoveries (C)</span>
            <span class="payout-tile-rightcolumn-cell  payout-tile-column-gap">{{ payout.ytdPaymentAdjustmentAndRecoveries }}</span>
          </div>
        </div>
        <div class="payout-tile-splitter"></div>
        <div class="payout-tile-sum">
          <div class="sum-row">
            <span class="payout-tile-leftcolumn-cell">Net Payment (A-B+C)</span> <span class="payout-tile-rightcolumn-cell  payout-tile-column-gap">{{ payout.netPaymentAmount }}</span>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
  <div class="carousel_container" style="padding-top: 4vw;">
    <div class="carousel_container carousel_container_rect" [ngClass]="{ rect_active: payout.isActive }" *ngFor="let payout of payoutData; let i = index"></div>
  </div>
</div>
<div *ngIf="isPayoutUnavailable" id="payout-tile-unavailable" class="payout-tile">
  <span>{{ payoutUnavailableMessage.Title }}</span
  ><span>{{ payoutUnavailableMessage.Text }}</span>
</div>
