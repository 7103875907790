import { Action } from '@ngrx/store';

export enum QuotaDetailActionTypes {
  GetQuotaDetail = '[Compliance] Get Quota Detail',
  GetQuotaDetailSuccess = '[Compliance] Get Quota Detail Success',
  GetQuotaDetailFailure = '[Compliance] Get Quota Detail Failure'
}

export class GetQuotaDetail implements Action {
  readonly type = QuotaDetailActionTypes.GetQuotaDetail;
  constructor(public payload: any, public route: string) {}
}

export class GetQuotaDetailSuccess implements Action {
  readonly type = QuotaDetailActionTypes.GetQuotaDetailSuccess;
  constructor(public payload: { data: any }) {}
}

export class GetQuotaDetailFailure implements Action {
  readonly type = QuotaDetailActionTypes.GetQuotaDetailFailure;
  constructor(public payload: { error: any }) {}
}

export type QuotaDetailActions = GetQuotaDetail | GetQuotaDetailSuccess | GetQuotaDetailFailure;
