import { Action } from '@ngrx/store';

export const GET_REFRESH_SUMMARY = 'Get Refresh Summary';
export const GET_REFRESH_SUMMARY_SUCCESS = 'Get Refresh Summary Success';
export const GET_REFRESH_SUMMARY_FAILURE = 'Get Refresh Summary Failure';

export class GetRefreshSummary implements Action {
  readonly type: string = GET_REFRESH_SUMMARY;

  constructor(public payload: any) {
    console.log(`ACTION ${GET_REFRESH_SUMMARY} ${payload}`);
  }
}

export class GetRefreshSummarySuccess implements Action {
  readonly type: string = GET_REFRESH_SUMMARY_SUCCESS;

  constructor(public payload: any) {
    console.log(`ACTION ${GET_REFRESH_SUMMARY_SUCCESS} ${payload}`);
  }
}

export class GetRefreshSummaryFailure implements Action {
  readonly type: string = GET_REFRESH_SUMMARY_FAILURE;

  constructor(public payload: any) {
    console.log(`ACTION ${GET_REFRESH_SUMMARY_FAILURE} ${payload}`);
  }
}

export type GetRefreshSummaryActions = GetRefreshSummary | GetRefreshSummarySuccess | GetRefreshSummaryFailure;
