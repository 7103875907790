import { GET_PAYOUT_SUMMARY, GET_PAYOUT_SUMMARY_SUCCESS, GET_PAYOUT_SUMMARY_FAILURE, GetPayoutSummayActions } from './payout-summary.actions';
import { PayoutSummaryState } from './payout-summary.model';

export const PayoutSummaryKey = 'payoutSummary';

export const initialState: PayoutSummaryState = null;

export function PayoutSummaryReducer(state = initialState, action: GetPayoutSummayActions): any {
  switch (action.type) {
    case GET_PAYOUT_SUMMARY:
      return null;
    case GET_PAYOUT_SUMMARY_SUCCESS:
      const payoutSummary = action.payload.data;
      return payoutSummary;
    case GET_PAYOUT_SUMMARY_FAILURE:
      return state;
    default:
      return state;
  }
}
