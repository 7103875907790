import { Expose } from 'class-transformer';

export class PaylinePoint {
  @Expose() payout: number;
  @Expose() payoutValue: number;
  @Expose() attainment: number;
  @Expose() payoutAtLevel: number;
  @Expose() payoutAt: string;
  @Expose() measuringUnits: string;
}
