import { selectNotificationsState } from '../../notifications/state/notifications.selectors';
import { GetNotifications } from '../../notifications/state/notifications.actions';
import { Notifications } from '../../notifications/Models/notifications.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectAuthInfo, selectAuthInfoFullName } from '../../core/authentication/state/auth.selectors';
import { HeaderConfig } from './header-config';
import { HeaderService } from './header.service';

@Component({
  selector: 'mint-mobile-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  thumbnailPhoto = '';
  name = '';
  notificationsData: Notifications;
  notifSelected = false;
  notifSeen = false;
  activeNotifications = 0;
  supportLink = 'https://microsoft.service-now.com/wwic?id=sc_cat_item&sys_id=b34393d9131fd2042620f0912244b0e6';

  @Input() title: string;
  @Input() headerConfig: HeaderConfig;
  @Output() openClickEvent = new EventEmitter<any>();

  constructor(private router: Router, private appState: Store<AppState>, private notificationsState: Store<Notifications>, public headerService: HeaderService) {}

  ngOnInit() {
    this.headerService.headerConfig = { ...this.headerService.defaultHeaderConfig, ...this.headerConfig };
    const authInfoSelector = this.appState.select(selectAuthInfo);
    const fullNameSelector = this.appState.select(selectAuthInfoFullName);
    authInfoSelector.subscribe(state => {
      this.thumbnailPhoto = state.userInfo.profile.thumbnailPhoto;
    });
    fullNameSelector.subscribe(fullName => (this.name = fullName));

    this.notificationsState.dispatch(new GetNotifications());
    this.notificationsState.select(selectNotificationsState).subscribe(notifications => {
      if (!notifications) {
        return;
      }
      this.notificationsData = notifications;
      this.activeNotifications = this.notificationsData.notifications ? this.notificationsData.notifications.length : 0;
    });
  }

  toggleSidebar() {
    this.openClickEvent.emit('toggleSideNav');
  }

  navigateToNotification(url) {
    this.router.navigateByUrl('/' + url);
    this.notifSelected = true;
    this.notifSeen = true;
  }

  navigateBack(url) {
    this.router.navigateByUrl('/' + url);
    this.notifSelected = false;
  }

  skipMainContent() {
    document.getElementById('filters-menu').focus();
  }

  onSuportIconClick(url: string) {
    window.open(url, '');
  }
}
