import { SidebarItem } from '../../layout/sidebar/sidebar-item.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { Component, ViewChild } from '@angular/core';
import { HeaderConfig } from '../../layout/header/header-config';
import { HeaderService } from '../../layout/header/header.service';

@Component({
  template: ''
})
export class BaseContainerComponent {
  title: string;
  sidebarItemList: SidebarItem[];
  headerConfig: HeaderConfig;
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(protected activeRoute: ActivatedRoute, protected router: Router, public headerService: HeaderService) {
    this.closeSideNav();
  }

  openSideNav(data: string) {
    this.toggleFeedbackModule(data);
    this.toggleSideNav();
  }

  closeSideNav() {
    this.router.events.subscribe(event => {
      if (this.sidenav) {
        this.sidenav.close();
      }
    });
  }

  toggleSideNav() {
    this.sidenav.toggle();
  }

  changeOfRoutes() {
    this.activeRoute.firstChild.data.subscribe(t => (this.headerService.title = t['title']));
  }

  getActiveUrl(activatedRoute: ActivatedRoute) {
    return activatedRoute.snapshot['_routerState'].url;
  }

  toggleFeedbackModule(data: string) {
    if (this.getActiveUrl(this.activeRoute).includes('/feedback')) {
      const elem = document.getElementById('obf-OverlayBackground');
      if (elem) {
        elem.style.zIndex = data;
      }
    }
  }
}
