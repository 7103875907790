import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { AuthState } from '../../authentication/state/auth.reducer';
import { selectAuthInfo } from '../../authentication/state/auth.selectors';
import { navigate } from '../../routing/state/navigation.actions';
import { AddUserRoleInfoAction } from '../../authentication/state/auth.actions';
import { NavigationPaths } from '../../routing/state/navigation-paths.enum';
import { selectUserProfileState, UserProfileState, UserRoleInfo, UserPreference, ContextState, FilterState, SetFilters as ContextSetFilters } from '@mint-libs/context';
import { SetFilters } from '../filters/state/filters.actions';

@Injectable({
  providedIn: 'root'
})
export class InitializationService {
  userInfoList: any[];
  constructor(private appState: Store<AppState>, private contextState: Store<ContextState>, private filterState: Store<FilterState>, private authState: Store<AuthState>, private userProfileState: Store<UserProfileState>) { }
  initialize(selectedFilters?: UserPreference, landingPage = NavigationPaths.Dashboard) {
    this.setUserRoleInfo(landingPage);
    this.setFilters(selectedFilters);
  }

  private setUserRoleInfo(landingPage: any) {
    this.userProfileState.select(selectUserProfileState).subscribe(state => {
      if (!state) {
        return;
      }
      if (state.firstName && state.lastName && state.userAlias) {
        this.storeToRecentlyImpersonatedUsers(state.firstName, state.lastName, state.userAlias);
      }
      this.authState.select(selectAuthInfo).subscribe(authInfo => {
        // If already initialized then skip
        if (authInfo.userRoleInfo) {
          this.appState.dispatch(navigate({ path: landingPage }));
          return;
        }
        const userRoleInfo = new UserRoleInfo();
        userRoleInfo.isFCA = state.isFCA;
        userRoleInfo.isSupportAdmin = state.settings.isSupportAdmin;
        userRoleInfo.isSuperAdmin = state.settings.isSuperAdmin;
        userRoleInfo.canSignOnBehalf = state.settings.canSignOnBehalf;
        this.authState.dispatch(new AddUserRoleInfoAction({ userRoleInfo: userRoleInfo }));
        this.appState.dispatch(navigate({ path: landingPage }));
      });
    });
  }
  private storeToRecentlyImpersonatedUsers(firstName, lastName, userAlias) {
    this.userInfoList = JSON.parse(window.localStorage.getItem('impersonationListMobile') ?? '[]');
    var userInfo = { userAlias: userAlias.toLowerCase(), userName: firstName + ' ' + lastName, thumbnailPhoto: '' };
    if (!this.userInfoList) {
      // this.userInfoList.push(userInfo);
      window.localStorage.setItem('impersonationListMobile', JSON.stringify([userInfo]));
      return;
    }
    var user = this.userInfoList.find(x => x.userAlias.toLowerCase() == userAlias.toLowerCase());
    if (user == undefined && this.userInfoList.length <= 10) {
      this.userInfoList.push(userInfo);
      this.userInfoList = this.userInfoList.reverse();
      window.localStorage.setItem('impersonationListMobile', JSON.stringify(this.userInfoList));
    }
  }

  private setFilters(selectedFilters: UserPreference) {
    this.userProfileState.select(selectUserProfileState).subscribe(state => {
      if (!state) {
        return;
      }
      this.contextState.dispatch(SetFilters({ userProfile: state, selectedFilters: selectedFilters }));

      if (state.defaultFilter.standardTitle || selectedFilters) {
        this.filterState.dispatch(new ContextSetFilters({ userProfile: state, selectedFilters: selectedFilters ?? state.defaultFilter }));
      }
    });
  }
}
