<div>
  <div class="table-container">
    <table class="table quota-detail-table" aria-label="Shows Quota for Plan Component Combinations data">
      <thead role="rowgroup">
        <tr class="rbi-table-rows" role="row">
          <th role="columnheader" class="text-left w_20" aria-label="Bucket">Bucket</th>
          <th role="columnheader" class="text-left w_20" aria-label="Metric">Metric</th>
          <th role="columnheader" class="text-left w_10" aria-label="Quota Type" title="Baseline quota is based on a Q4 run rate">Quota Type</th>
          <th role="columnheader" class="text-left w_10">{{ quotaService.selectedTimePeriod.Name }}{{ quotaService.selectedTimePeriod.Text }}</th>
        </tr>
      </thead>
      <tbody role="rowgroup" tabindex="0">
        <tr *ngFor="let pcc of quotaDetail; index as i" class="app-table-rows rbi-table-rows" role="row">
          <th scope="row" role="rowheader" class="text-left th-left" *ngIf="pcc.PCGRowSpan != -1" [attr.rowspan]="pcc.PCGRowSpan" [attr.aria-label]="pcc.PCGName + 'Bucket'">
            <span [hidden]="!pcc.IsPIT">(<span class="text-danger">*</span>) </span>{{ pcc.PCGName }}
          </th>
          <th scope="row" role="rowheader" class="text-left th-left" *ngIf="pcc.RowSpan == 2" [attr.rowspan]="pcc.RowSpan" [attr.aria-label]="pcc.Name + 'Metric'">
            <span [hidden]="!pcc.IsPIT">(<span class="text-danger">*</span>) </span>{{ pcc.Name }}<span [hidden]="!pcc.isQuotaMultiplier" class="quota-multiplier"> ++</span>
          </th>
          <th scope="row" role="rowheader" class="text-left th-left" *ngIf="pcc.RowSpan != 2 && pcc.RowSpan != -1" [attr.aria-label]="pcc.Name + 'Metric'">
            <span [hidden]="!pcc.IsPIT">(<span class="text-danger">*</span>) </span>{{ pcc.Name }}<span [hidden]="!pcc.isQuotaMultiplier" class="quota-multiplier"> ++</span>
          </th>
          <th scope="row" role="rowheader" class="text-left th-left" [attr.aria-label]="pcc.MetricType">{{ pcc.MetricType }}</th>
          <td role="gridcell" tabindex="-1" class="text-left" *ngFor="let metric of pcc.Metrics; index as j" [ngStyle]="{ 'min-width': metric.Value === 'N/A' ? '20vw' : '0' }">
            {{ metric.Value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="quota-detail-footer">
    <div *ngIf="quotaService.hasPIT">
      <span>(<span class="text-danger">*</span>) Note: </span>{{ quotaService.qaConfigData.tableInfoBottom }}
    </div>
    <div *ngIf="quotaService.hasQuotaMultiplier">
      ++ Note: Product Group has a Quota Multiplier applied to it. Please click on "Export Quota by Metric to Excel" to see the Quota multiplier amount for the Product Group (PCC). To see the
      multipliers applied, please check the
    </div>
  </div>
</div>
