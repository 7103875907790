import { Action, createReducer, on } from '@ngrx/store';
import { FilterActions, FilterActionTypes } from './filters.actions';
import { Filters } from '../filters.model';
import { act } from '@ngrx/effects';
import { UserPreference } from '../../user-profile/models/user-preference.model';
import { StandardTitle } from '../../user-profile/models/standard-title.model';

export const filtersFeatureKey = 'filters';

export interface FilterState extends Filters {}

export const filterInitialState: FilterState = null;

const getFilterLabel = function(selectedFilters: UserPreference): string {
  const fiscalyear = getFiscalYearLabel(selectedFilters.fiscalYear);
  const selectedStep = 'Q' + selectedFilters.stepId;
  const filterLabel = `${fiscalyear}-${selectedStep}`;
  return filterLabel;
};

const getFiscalYearLabel = function(fiscalYear: string): string {
  const fiscalYearLabel = 'FY' + fiscalYear.substring(2);
  return fiscalYearLabel;
};

export function filtersReducer(state = filterInitialState, action: FilterActions): FilterState {
  switch (action.type) {
    case FilterActionTypes.SetFilters:
      const filters: Filters = {
        filterLabel: getFilterLabel(action.payload.userProfile.defaultFilter),
        fiscalYearLabel: getFiscalYearLabel(action.payload.userProfile.defaultFilter.fiscalYear),
        fiscalYears: action.payload.userProfile.fiscalYears,
        standardTitles: action.payload.userProfile.standardTitles,
        defaultFilters: action.payload.userProfile.defaultFilter,
        // If selected filters is passed as payload use that.
        // If that doesn't exist try to reuse what we have before.
        // If we've not set filters before, we'll use the default filters from the user profile
        selectedFilters:
          action.payload.selectedFilters ??
          (state?.selectedFilters && state.selectedFilters.fiscalYear && state.selectedFilters.stepId > 0 ? state.selectedFilters : action.payload.userProfile.defaultFilter)
      };
      return filters;

    case FilterActionTypes.UpdateFilters:
      return {
        ...state,
        filterLabel: getFilterLabel(action.payload.selectedFilters),
        fiscalYearLabel: getFiscalYearLabel(action.payload.selectedFilters.fiscalYear),
        selectedFilters: action.payload.selectedFilters
      };

    case FilterActionTypes.ResetFilters:
      return {
        ...state,
        selectedFilters: null
      };

    default:
      return state;
  }
}
