import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@mint-libs/authentication';
import { Store } from '@ngrx/store';
import { ConfigurationService } from '@mint-libs/configuration';
import { AppState } from '../reducers';
import { AuthState } from '../core/authentication/state/auth.reducer';
import { PerformLoginAction, LoginSuccessAction } from '../core/authentication/state/auth.actions';
import { LoadUserProfile, ResetUserProfile, UserProfileState } from '@mint-libs/context';
import { BaseViewComponent } from '../shared/base/base-view.component';
import { LoggerService } from '../core/logging/logger.service';
import { InitializationService } from '../core/context/initialization/initialization.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'mint-mobile-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseViewComponent implements OnInit, OnDestroy {
  underImpersonation: boolean;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private authService: AuthenticationService,
    private configuration: ConfigurationService,
    protected appState: Store<AppState>,
    private authState: Store<AuthState>,
    private userProfileState: Store<UserProfileState>,
    protected logger: LoggerService,
    private initializationService: InitializationService
  ) {
    super(appState, logger);
    this.handleLoginCallback();
  }

  private handleLoginCallback() {
    const obs = this.authService.getLoginCallback(this._destroying$);
    obs.subscribe(
      result => {
        this.initialize();
      },
      error => {
        console.log(error);
      }
    );
  }

  ngOnInit() {
    if (!this.isAuthenticated()) {
      this.authState.dispatch(new PerformLoginAction());
    } else {
      this.initialize();
    }
  }

  private initialize() {
    this.authState.dispatch(new LoginSuccessAction({ userInfo: this.authService.getUserInfo() }));
    this.userProfileState.dispatch(new ResetUserProfile());
    this.userProfileState.dispatch(new LoadUserProfile(null));
    this.configuration.init();
    this.initializationService.initialize();
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  logOut() {
    this.authService.logOut();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
