import { Directive, ViewContainerRef, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserProfileState, selectUserProfileState } from '@mint-libs/context';

@Directive({
  selector: '[mintApplicableForUBI]'
})
export class ApplicableForUBIDirective {
  userProfile: any;
  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, private userProfileState: Store<UserProfileState>) {
    this.userProfileState.select(selectUserProfileState).subscribe(userProfileState => {
      if (!userProfileState) {
        return;
      }
      this.userProfile = userProfileState;
      // Let view be embedded for UBI sellers
      if (this.userProfile && this.userProfile.isUBIPersona()) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        // Else clear it
        this.viewContainer.clear();
      }
    });
  }
}
