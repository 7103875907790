import { Component, OnInit, Input } from '@angular/core';
import { AttainmentLegends } from './legends.enum';

@Component({
  selector: 'mint-mobile-legends',
  templateUrl: './legends.component.html'
})
export class LegendsComponent implements OnInit {
  isBelowthreshold: boolean;
  isAboveThreshold: boolean;
  isAboveTarget: boolean;
  isAboveExcellence: boolean;
  isOCPMetric: boolean;
  @Input() attainmentStatus: AttainmentLegends;
  @Input() componentId: number;
  constructor() {}

  ngOnInit() {
    this.isBelowthreshold = this.isAttainmentStatus(AttainmentLegends.BelowThreshold);
    this.isAboveThreshold = this.isAttainmentStatus(AttainmentLegends.AboveThreshold);
    this.isAboveTarget = this.isAttainmentStatus(AttainmentLegends.AboveTarget);
    this.isAboveExcellence = this.isAttainmentStatus(AttainmentLegends.AboveExcellence);
    this.isOCPMetric = this.isAttainmentStatus(AttainmentLegends.OCPMetric);
  }

  isAttainmentStatus(legend: AttainmentLegends): boolean {
    return this.attainmentStatus === legend;
  }
}
