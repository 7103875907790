import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ITAInformationActions, ITAInformationActionTypes, GetITAInformationSuccess, GetITAInformationFailure } from './ita-information.actions';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@mint-libs/context';

@Injectable()
export class ITAInformationEffects {
  @Effect()
  loadITAInformation$ = this.actions$.pipe(
    ofType(ITAInformationActionTypes.GetITAInformation),
    concatMap(() => {
      return this.dataService.getWithGlobalFilters(this.endpoints.GetITAInformation.uri).pipe(
        map(data => new GetITAInformationSuccess({ data })),
        catchError(error => of(new GetITAInformationFailure({ error })))
      );
    })
  );

  constructor(private actions$: Actions<ITAInformationActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
