import { Action } from '@ngrx/store';

export const GET_TARGET_SUMMARY = 'Get Target Summary';
export const GET_TARGET_SUMMARY_SUCCESS = 'Get Target Summary Success';
export const GET_TARGET_SUMMARY_FAILURE = 'Get Target Summary Failure';

export class GetTargetSummary implements Action {
  readonly type: string = GET_TARGET_SUMMARY;

  constructor(public payload: any) {
    console.log(`ACTION ${GET_TARGET_SUMMARY} ${payload}`);
  }
}

export class GetTargetSummarySuccess implements Action {
  readonly type: string = GET_TARGET_SUMMARY_SUCCESS;

  constructor(public payload: any) {
    console.log(`ACTION ${GET_TARGET_SUMMARY_SUCCESS} ${payload}`);
  }
}

export class GetTargetSummaryFailure implements Action {
  readonly type: string = GET_TARGET_SUMMARY_FAILURE;

  constructor(public payload: any) {
    console.log(`ACTION ${GET_TARGET_SUMMARY_FAILURE} ${payload}`);
  }
}

export type GetTargetSummaryActions = GetTargetSummary | GetTargetSummarySuccess | GetTargetSummaryFailure;
