import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { PayoutSummaryState } from '../../shared/shared-dashboard/payout-summary/state/payout-summary.model';
import { Store } from '@ngrx/store';
import { PaylineSummaryState } from '../../shared/shared-dashboard/payline-summary/state/payline-summary.model';
import { GetPaylineSummary } from '../../shared/shared-dashboard/payline-summary/state/payline-summary.actions';
import { selectPaylineSummaryState } from '../../shared/shared-dashboard/payline-summary/state/payline-summary.selectors';
import { PCGMetrics } from '@mint-libs/components';
import { AppState } from '../../reducers';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MetricViewComponent } from '../../shared/shared-dashboard/metric-view/metric-view.component';
import { Payline, Formatter, CustomCurrencyPipe } from '@mint-libs/common';
import { LegendsService } from '../../shared/shared-dashboard/legends/legends.service';
import { LoggerService } from '../../core/logging/logger.service';
import { GetUtilizationMetric } from './utilization-metric/state/utilization-metric.actions';
import { UtilizationMetricState } from './utilization-metric/state/utilization-metric.reducer';
import { selectUtilizationMetricState } from './utilization-metric/state/utilization-metric.selectors';
import { selectITAInformationState } from './ita-information/state/ita-information.selectors';
import { ITAInformationState } from './ita-information/state/ita-information.reducer';
import { selectWorkingHoursState } from './working-hours/state/working-hours.selectors';
import { WorkingHoursState } from './working-hours/state/working-hours.reducer';
import { TargetSummaryState } from '../../shared/shared-dashboard/target-summary/state/target-summary.model';
import { selectTargetAndRefreshState, selectTargetSummaryState } from '../../shared/shared-dashboard/target-summary/state/target-summary.selectors';
import { GetITAInformation } from './ita-information/state/ita-information.actions';
import { GetWorkingHours } from './working-hours/state/working-hours.actions';
import { GetRubiTargetSummary } from './rubi-target-summary/state/rubi-target-summary.actions';
import { selectRubiTargetAndRefreshState } from './rubi-target-summary/state/rubi-target-summary.selectors';
import { BaseSellerDashboardComponent } from '../base-seller-dashboard.component';
import { selectFiltersState } from '../../core/context/filters/state/filters.selectors';
import { FilterState } from '../../core/context/filters/state/filters.reducer';
import { Filters } from '@mint-libs/context';

@Component({
  selector: 'mint-mobile-ubi-dashboard',
  templateUrl: './ubi-seller-dashboard.component.html',
  styleUrls: ['./ubi-seller-dashboard.component.scss'],
  providers: [LegendsService, NgbNavConfig]
})
export class UbiSellerDashboardComponent extends BaseSellerDashboardComponent implements OnInit, OnDestroy {
  ytdPCGs: PCGMetrics[];
  isTAApplicable = false;
  isActiveApplicable = false;
  isTAwithoutActive = false;
  activeStatusTabId;
  activeStatus;
  workingHoursTileLoaded: boolean;
  utilizationMetric: any;
  workinghourdetails: any;
  target: any;
  currentUtilization: any = {};
  periodStatus = 'Active';
  filters: Filters;
  targetAndRefreshSummary$: any;
  utilizationDetails$: any;

  constructor(
    protected modalService: NgbModal,
    protected appState: Store<AppState>,
    protected payoutSummaryState: Store<PayoutSummaryState>,
    protected paylineSummaryState: Store<PaylineSummaryState>,
    private utilizationMetricState: Store<UtilizationMetricState>,
    private itaInformationState: Store<ITAInformationState>,
    private workingHoursState: Store<WorkingHoursState>,
    private targetSummaryState: Store<TargetSummaryState>,
    private filtersState: Store<FilterState>,
    private customCurrencyPipe: CustomCurrencyPipe,
    private bottomSheet: MatBottomSheet,
    protected formatter: Formatter,
    private legends: LegendsService,
    protected logger: LoggerService,
    protected navConfig: NgbNavConfig
  ) {
    super(appState, formatter, logger, paylineSummaryState, payoutSummaryState, modalService);
    navConfig.keyboard = true;
  }
  ngOnInit() {
    this.loadData();
    this.resetKPIs();
    this.loadKPIs();
    super.getConfigData();
    this.setMetricTiles();
    this.activeStatusTabId = 'dashboard_active';
    this.activeStatus = true;
  }

  loadData() {
    super.loadData();
    this.appState.dispatch(new GetWorkingHours(null));
    this.appState.dispatch(new GetUtilizationMetric(null, null));
    this.appState.dispatch(new GetITAInformation(null));
    this.appState.dispatch(new GetRubiTargetSummary(null));
    this.getFilters();
  }

  resetKPIs() {
    super.resetKPIs();
    this.currentUtilization.isMobile = true;
    this.workingHoursTileLoaded = false;
  }

  loadKPIs() {
    super.loadKPIs();
    this.itaInformationState.select(selectITAInformationState).subscribe((itaInfo: any) => {
      if (!itaInfo) {
        return;
      }
      this.checkForTA(itaInfo);
      this.checkForActive(itaInfo);
    });

    this.targetSummaryState.select(selectTargetSummaryState).subscribe((targetSummary: any) => {
      if (!targetSummary) {
        return;
      }
      this.target = targetSummary;
      this.getWorkingHours(this.target);
    });
  }

  getWorkingHours(data: any) {
    this.workingHoursState.select(selectWorkingHoursState).subscribe((workingHours: any) => {
      if (!workingHours) {
        return;
      }
      this.workingHoursTileLoaded = true;
      this.workinghourdetails = workingHours;
      this.setData(data, this.workinghourdetails);
    });
  }

  setMetricTiles() {
    this.ytdPCGs = [];
    this.paylineSummaryState.select(selectPaylineSummaryState).subscribe((paylineSummary: PaylineSummaryState) => {
      if (!paylineSummary) {
        return;
      }
      this.paylinesLoaded = true;
      paylineSummary.paylines.forEach(function(payline: Payline) {
        const isUtilization = payline.pcgName.toLowerCase() === 'utilization' ? true : false;
        const pcgMetrics = new PCGMetrics();
        pcgMetrics.pcgName = payline.pcgName;
        pcgMetrics.pcgWeight = payline.weightage.toString();
        this.utilizationMetricState.select(selectUtilizationMetricState).subscribe((utilization: any) => {
          if (!utilization) {
            return;
          }
          this.utilizationMetric = utilization.utilizationTotals.filter(x => x.fiscalWeekText === 'Week-To-Date Total' && x.periodStatus === this.periodStatus)[0];
          pcgMetrics.attainmentPercent = isUtilization ? this.utilizationMetric.utilizationPercentage.toFixed(2) : payline.attainment.toFixed(2);
        });
        pcgMetrics.attainmentStatus = isUtilization ? this.legends.getRubiNeutralClass() : this.legends.getCssClass(payline);
        pcgMetrics.attainmentTitle = isUtilization ? 'Utilization' : 'Attainment';
        pcgMetrics.incentiveType = isUtilization ? null : 'RUBI';
        pcgMetrics.timeSpan = isUtilization ? '(Week-To-Date)' : '(Year-To-Date)';

        this.metricCardConfig.shouldShowEarnings = false;
        this.metricCardConfig.shouldShowTimeSpan = true;

        this.ytdPCGs.push(pcgMetrics);
        this.ytdPCGs = this.ytdPCGs.filter((pcg, pcgIndex) => this.ytdPCGs.findIndex(ytdPCG => ytdPCG.pcgName == pcg.pcgName) === pcgIndex);
      }, this);
      super.setActiveSlide(0);
    });
    this.appState.dispatch(new GetPaylineSummary(null, 'RUBI'));
  }

  getFilters() {
    this.filtersState.select(selectFiltersState).subscribe(state => {
      if (!state) {
        return;
      }
      this.filters = state;
    });
  }

  setData(result: any, workingHourDetails: any) {
    const selectedStepId = this.filters.selectedFilters.stepId;

    const workingDaysString = `H${selectedStepId / 2} Till Date`;
    const remainingDaysString = `H${selectedStepId / 2} Remaining`;

    let workingDaysCompleted;

    if (workingHourDetails.some(x => x.period === workingDaysString)) {
      workingDaysCompleted = this.getWorkingDaysCompleted(workingHourDetails, workingDaysString);
    } else {
      workingDaysCompleted = null;
    }

    // set current Utilization data
    if (workingDaysCompleted === 0 || workingDaysCompleted == null) {
      this.currentUtilization.amount = this.isTAwithoutActive ? '' : '0 Hrs';
      this.currentUtilization.alternate = this.isTAwithoutActive ? 'Not Applicable during Transition Allowance' : '';
    } else {
      this.currentUtilization.amount = this.customCurrencyPipe.transform(result.planComponentGroups[0].planComponentCombinations[0].metrics.actuals / workingDaysCompleted, 'Hrs');
    }
    this.currentUtilization.amountTitle = `Current Daily Utilization Rate`;
    this.currentUtilization.isMobile = true;
    this.currentUtilization.iconName = 'trending_up';
  }

  getWorkingDaysCompleted(workingHourDetails: any, workingDaysString: string) {
    let activeWorkingDays = null;
    // Active number of Days Excluding TA Days.
    const activeWorkingHourDetails = workingHourDetails.filter(x => x.period === workingDaysString && x.periodStatus === 'Active');
    if (activeWorkingHourDetails && activeWorkingHourDetails.length > 0) {
      activeWorkingDays = activeWorkingHourDetails[0].days;
    }
    return activeWorkingDays;
  }

  checkForTA(allITAs: any) {
    if (allITAs.taitAs && allITAs.taitAs.length > 0) {
      this.isTAApplicable = true;
      const currDate = new Date();
      allITAs.taitAs.forEach(x => {
        const fromDateSituation = new Date(x.FromDateSituation);
        const toDateSituation = new Date(x.ToDateSituation);
        if (currDate >= fromDateSituation && currDate <= toDateSituation) {
          this.activeStatusTabId = 'dashboard_ta';
          this.activeStatus = false;
        }
      });
    }
  }

  checkForActive(allITAs: any) {
    if (allITAs.activeITAs && allITAs.activeITAs.length > 0) {
      this.isActiveApplicable = true;
      this.activeStatusTabId = 'dashboard_active';
      this.activeStatus = true;
    } else {
      this.activeStatusTabId = 'dashboard_ta';
      this.activeStatus = false;
      if (this.isTAApplicable) {
        this.isTAwithoutActive = true;
      }
    }
  }

  onPeriodStatusChange($event) {
    this.activeStatus = $event.target.id === 'dashboard_active' ? true : false;
    this.activeStatusTabId = $event.target.id;
    this.periodStatus = this.getSelectedPeriodStatus();
    this.appState.dispatch(new GetUtilizationMetric(null, this.periodStatus));
  }

  private getSelectedPeriodStatus() {
    if (this.activeStatus) {
      return 'Active';
    } else {
      return 'TA';
    }
  }

  onTileClick(pcgMetrics: PCGMetrics) {
    this.openBottomSheet(pcgMetrics);
  }

  getMetricViewDetails(pcgMetrics: PCGMetrics) {
    if (pcgMetrics.incentiveType === 'RUBI') {
      this.targetAndRefreshSummary$ = this.appState.select(selectRubiTargetAndRefreshState);
      this.utilizationDetails$ = null;
    } else {
      this.targetAndRefreshSummary$ = this.appState.select(selectTargetAndRefreshState);
      this.utilizationDetails$ = this.utilizationMetric;
    }
  }

  openBottomSheet(pcgMetrics: PCGMetrics): void {
    this.getMetricViewDetails(pcgMetrics);
    this.bottomSheet.open(MetricViewComponent, {
      data: {
        bucketName: pcgMetrics.pcgName,
        attainmentStatus: pcgMetrics.attainmentStatus,
        metricViewData: this.targetAndRefreshSummary$,
        quotaToGoLabel: 'Target Remaining',
        utilization: this.utilizationDetails$
      }
    });
  }

  ngOnDestroy(): void {
    this.bottomSheet.dismiss();
  }
}
