import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRubiTargetSummary from './rubi-target-summary.reducer';
import { RubiTargetSummaryState } from './rubi-target-summary.model';
import { selectRefreshSummaryState } from '../../../../shared/shared-dashboard/refresh-summary/state/refresh-summary.selectors';

export const selectRubiTargetSummaryState = createFeatureSelector<RubiTargetSummaryState>(fromRubiTargetSummary.RubiTargetSummaryKey);

export const selectRubiTargetAndRefreshState = createSelector(selectRubiTargetSummaryState, selectRefreshSummaryState, (rubiTargetSummary, refreshSummary) => {
  return { rubiTargetSummary, refreshSummary };
});
