import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LegendsService {
  legends = {
    neutral: '#00AE56',
    belowThreshold: '#EB5757',
    belowTarget: '#FFB900',
    belowExcellence: '#27AE60',
    aboveExcellence: '#2F80ED',
    rubiNeutral: '#000000',
    ocpMetric: '#999999'
  };

  legendDetails = {
    neutral: {
      text: 'Neutral',
      color: this.legends.neutral,
      cssClass: 'neutral'
    },
    belowThreshold: {
      text: 'Below Threshold',
      color: this.legends.belowThreshold,
      cssClass: 'below-threshold'
    },
    belowTarget: {
      text: 'Above Threshold and Below Target',
      color: this.legends.belowTarget,
      cssClass: 'above-threshold'
    },
    belowExcellence: {
      text: 'Above Target and Below Excellence Point',
      color: this.legends.belowExcellence,
      cssClass: 'above-target'
    },
    aboveExcellence: {
      text: 'Above Excellence Point',
      color: this.legends.aboveExcellence,
      cssClass: 'above-excellence'
    },
    rubiNeutral: {
      test: 'RUBI_Neutral',
      color: this.legends.rubiNeutral,
      cssClass: 'rubi_neutral'
    },
    ocpMetric: {
      test: 'OCP_Metric',
      color: this.legends.ocpMetric,
      cssClass: 'ocp_metric'
    }
  };

  constructor() {}

  getText(payline) {
    return this.getValueByPayline(payline, 'text');
  }
  getColor(payline) {
    return this.getValueByPayline(payline, 'color');
  }

  getCssClass(payline: any) {
    return this.getValueByPayline(payline, 'cssClass');
  }

  getValueByPayline(payline, valueType) {
    // If attainmentClass is present then simply return it
    const value = this.getAttainmentStatusForBucket(payline, valueType);
    if (value) {
      return value;
    }
    if (payline.isNeutral) {
      return this.legendDetails.neutral[valueType];
    }
    if (payline.incentiveType === 'RUBI' || payline.incentiveType === 'UBI') {
      return this.legendDetails.rubiNeutral[valueType];
    }

    return this.getValue(payline.attainment, payline.threshold, payline.target, payline.excellence, payline.weightage, valueType);
  }

  getValue(attainment, threshold, target, excellence, weightage, valueType) {
    let value = this.legendDetails.belowThreshold[valueType]; // Default below threshold

    // Check on zero weight to assign OCP PPM Metric css class
    if (weightage === 0) {
      value = this.legendDetails.ocpMetric[valueType];
    } else if (attainment < threshold) {
      value = this.legendDetails.belowThreshold[valueType];
    } else if (attainment < target) {
      value = this.legendDetails.belowTarget[valueType];
    } else if (attainment < excellence) {
      value = this.legendDetails.belowExcellence[valueType];
    } else if (attainment >= excellence) {
      value = this.legendDetails.aboveExcellence[valueType];
    }
    return value;
  }

  getNeutralColor() {
    return this.legendDetails.neutral.color;
  }

  getNeutralClass() {
    return this.legendDetails.neutral.cssClass;
  }

  getRubiNeutralClass() {
    return this.legendDetails.rubiNeutral.cssClass;
  }

  // TODO: Currently duplicated, to be refactored with dedicated effort
  getAttainmentStatusForBucket(payline, valueType) {
    if (payline.bucketType === 'Payout Adjustment Bucket') {
      if (Number(payline.payoutImpact.replace('%', '')) < 0) {
        return this.legendDetails.belowThreshold[valueType];
      } else {
        return this.legendDetails.belowExcellence[valueType];
      }
    } else if (payline.bucketType === 'Payout Gate Bucket') {
      if (Number(payline.attainment) < Number(payline.threshold)) {
        return this.legendDetails.belowThreshold[valueType];
      } else {
        return this.legendDetails.belowExcellence[valueType];
      }
    }
  }
}
