import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { PCCMetrics } from './pcc-metrics.model';
import { Formatter } from '@mint-libs/common';
import { MeasureTypeMappingState, selectMeasureTypeMappingState, MeasureType } from '@mint-libs/context';
import { SourceRefreshInfo } from './source-refresh.model';
@Component({
  selector: 'mint-mobile-metric-view',
  templateUrl: './metric-view.component.html',
  styleUrls: ['./metric-view.component.scss']
})
export class MetricViewComponent implements OnInit {
  bucketName: string;
  attainmentStatus: string;
  metricViewData: any;
  panelOpenState: boolean;
  pccMetricsList: PCCMetrics[];
  isSingleMetric = false;
  quotaToGoLabel: string;
  utilization: any;
  metricDataState: any;
  measureTypeMapping: MeasureTypeMappingState = new MeasureTypeMappingState([]);


  constructor(private bottomSheetRef: MatBottomSheetRef<MetricViewComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private appState: Store<AppState>, private formatter: Formatter,
  protected measureTypeMappingState: Store<MeasureTypeMappingState>) {
    this.bucketName = data.bucketName;
    this.attainmentStatus = data.attainmentStatus;
    this.metricViewData = data.metricViewData;
    this.quotaToGoLabel = data.quotaToGoLabel;
    this.utilization = data.utilization;
    
    this.measureTypeMappingState.select(selectMeasureTypeMappingState).subscribe((measureTypeMapping) => {
      this.measureTypeMapping = measureTypeMapping;
      if (this.measureTypeMapping.isFetched) {
        this.getData(this.metricDataState, measureTypeMapping);
      }
    });
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngOnInit() {
    this.metricViewData.subscribe(state => {
      this.metricDataState = state; 
      this.getData(state, this.measureTypeMapping);
    });
  }

  getData(state, measureTypeMapping) {
    if (!state) return;
    const targetSummary = state.rubiTargetSummary ? state.rubiTargetSummary : state.targetSummary;
    const refreshSummary = state.refreshSummary;
    if (!targetSummary) {
      return;
    }
    const buckets: any[] = targetSummary.planComponentGroups.filter((pcg: any) => pcg.name === this.bucketName);
    if (!buckets || buckets.length === 0) {
      return;
    }
    this.pccMetricsList = [];
    const bucket = buckets[0];
    bucket.planComponentCombinations.forEach(pcc => {
      const isBaseline = measureTypeMapping.getMeasureType(pcc.id) === MeasureType.Baseline;
      const metricInfo = new PCCMetrics();
      metricInfo.name = pcc.name;
      metricInfo.attainment = this.formatter.roundOff(pcc.metrics.attainment).toString();
      metricInfo.totalTarget = this.getTotalTargetValue(pcc);
      metricInfo.totalActuals = this.getTotalActualValue(pcc);
      metricInfo.targetLabel = this.getTargetLabel(pcc, isBaseline);
      metricInfo.actualsLabel = this.getActualsLabel(pcc, isBaseline);
      metricInfo.target = this.formatter.formatWithCurrencyAndRoundOff(pcc.metrics.totalTarget, pcc.metrics.pccUnit);
      metricInfo.actual = this.formatter.formatWithCurrencyAndRoundOff(pcc.metrics.actuals, pcc.metrics.pccUnit);
      metricInfo.totalRecurring = pcc.metrics.totalRecurringTarget > 0 ? this.formatter.formatWithCurrencyAndRoundOff(pcc.metrics.totalRecurringTarget, pcc.metrics.pccUnit) : null;
      metricInfo.quotaToGoLabel = this.quotaToGoLabel + ':';
      metricInfo.quotaToGo = this.formatter.formatWithCurrencyAndRoundOff(pcc.metrics.quotaToGo, pcc.metrics.pccUnit);
      metricInfo.isQuotaToGoDetailVisible = !pcc.isDynamicMetric;
      metricInfo.measuringUnit = pcc.measuringUnits;
      metricInfo.weight = this.formatter.roundOff(pcc.weightage * 100).toString();
      metricInfo.sourceRefreshInfoList = this.getsourceRefreshInfo(pcc.name, refreshSummary);
      if (this.utilization) {
        metricInfo.attainment = this.formatter.roundOff(this.utilization.utilizationPercentage).toString();
        metricInfo.totalTarget = this.formatter.formatWithCurrencyAndRoundOff(this.utilization.targetHours, pcc.metrics.pccUnit);
        metricInfo.totalActuals = this.formatter.formatWithCurrencyAndRoundOff(this.utilization.actualHours, pcc.metrics.pccUnit);
        const quotaToGo = this.utilization.actualHours >= this.utilization.targetHours ? 0 : (this.utilization.targetHours - this.utilization.actualHours).toFixed(2);
        metricInfo.quotaToGo = this.formatter.formatWithCurrencyAndRoundOff(quotaToGo, pcc.metrics.pccUnit);
      }
      this.pccMetricsList.push(metricInfo);
    }, this);
    this.isSingleMetric = this.pccMetricsList.length === 1;
  }

  getsourceRefreshInfo(metricName: string, refreshSummary: any): SourceRefreshInfo[] {
    const sourceRefreshInfoList: SourceRefreshInfo[] = [];
    if (!refreshSummary) {
      return sourceRefreshInfoList;
    }
    const list = refreshSummary.filter(item => item.pccName === metricName);
    if (!list || list.length === 0) {
      return sourceRefreshInfoList;
    }
    const dataSourceList = list[0].dataSource;
    if (!dataSourceList || dataSourceList.length === 0) {
      return sourceRefreshInfoList;
    }
    const UtcDate = this.formatter.getUtcDate();
    dataSourceList.forEach(dataSource => {
      const sourceRefreshInfo = new SourceRefreshInfo();
      sourceRefreshInfo.name = dataSource.sourceName;
      sourceRefreshInfo.lastRefresh =
        dataSource.sourceLastRefreshTime == null ? 'Not available.' : this.formatter.transformToLocalDateTz(dataSource.sourceLastRefreshTime, 'MMM D, YYYY, h:mm A ', true);
      sourceRefreshInfo.nextRefresh =
        dataSource.sourceNextRefreshTime == null
          ? 'Not available.'
          : this.formatter.getDate(new Date(dataSource.sourceNextRefreshTime)) < UtcDate
          ? this.formatter.transformToLocalDateTz(dataSource.sourceNextRefreshTime, 'MMM D, YYYY ', true) + ' (In Progress)'
          : this.formatter.transformToLocalDateTz(dataSource.sourceNextRefreshTime, 'MMM D, YYYY ', true);
      sourceRefreshInfoList.push(sourceRefreshInfo);
    });
    return sourceRefreshInfoList;
  }

  getTargetLabel(pcc, isBaseline = false) {
    let targetLabel: string;
    if (pcc.isDynamicMetric) {
      targetLabel = 'Commitments Quota: ';
      return targetLabel;
    }
    const recurringStr = isBaseline ? 'Baseline' : 'Recurring Target';
    targetLabel = pcc.metrics.totalRecurringTarget > 0 ? `Total Target - ${recurringStr}: ` : 'Total Target: ';
    return targetLabel;
  }

  getActualsLabel(pcc, isBaseline = false) {
    let actualsLabel: string;
    if (pcc.isDynamicMetric) {
      actualsLabel = 'Total Value of Commitments Landed: ';
      return actualsLabel;
    }
    const recurringStr = isBaseline ? 'Baseline' : 'Recurring Target';
    actualsLabel = pcc.metrics.totalRecurringTarget > 0 ? `Total Actuals - ${recurringStr}: ` : 'Total Actuals: ';
    return actualsLabel;
  }

  getTotalActualValue(pcc) {
    let totalActuals;
    if (pcc.isDynamicMetric) {
      totalActuals = this.formatter.formatWithCurrencyAndRoundOff(pcc.metrics.actuals, pcc.metrics.pccUnit);
      return totalActuals;
    }

    totalActuals = this.formatter.formatWithCurrencyAndRoundOff(pcc.metrics.actuals - pcc.metrics.totalRecurringTarget, pcc.metrics.pccUnit);
    return totalActuals;
  }

  getTotalTargetValue(pcc) {
    let totalTarget;
    if (pcc.isDynamicMetric) {
      totalTarget = this.formatter.formatWithCurrencyAndRoundOff(pcc.metrics.totalTarget, pcc.metrics.pccUnit);
      return totalTarget;
    }

    totalTarget = this.formatter.formatWithCurrencyAndRoundOff(pcc.metrics.totalTarget - pcc.metrics.totalRecurringTarget, pcc.metrics.pccUnit);
    return totalTarget;
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}
