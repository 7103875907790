import { Action } from '@ngrx/store';

export const GET_PAYLINE_SUMMARY = 'Get Payline Summary';
export const GET_PAYLINE_SUMMARY_SUCCESS = 'Get Payline Summary Success';
export const GET_PAYLINE_SUMMARY_FAILURE = 'Get Payline Summary Failure';
export const RESET = 'Reset';

export class GetPaylineSummary implements Action {
  readonly type = GET_PAYLINE_SUMMARY;

  constructor(public payload: any, public incentiveType: string) {
    console.log(`ACTION ${GET_PAYLINE_SUMMARY} payload`);
  }
}

export class GetPaylineSummarySuccess implements Action {
  readonly type = GET_PAYLINE_SUMMARY_SUCCESS;

  constructor(public payload: { data: any }) {
    console.log(`ACTION ${GET_PAYLINE_SUMMARY_SUCCESS} payload`);
  }
}

export class GetPaylineSummaryFailure implements Action {
  readonly type = GET_PAYLINE_SUMMARY_FAILURE;

  constructor(public payload: { error: any }) {
    console.log(`ACTION ${GET_PAYLINE_SUMMARY_FAILURE} payload`);
  }
}

export class ResetPaylineSummary implements Action {
  readonly type = RESET;

  constructor(public payload: { error: any }) {
    console.log(`ACTION ${RESET} payload`);
  }
}

export type GetPaylineSummayActions = GetPaylineSummary | GetPaylineSummarySuccess | GetPaylineSummaryFailure;
