import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar/avatar.component';
import { AvatarModule } from 'ngx-avatar';
import { MatIconModule } from '@angular/material/icon';
import { DirectivesModule } from './directives/directives.module';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, AvatarModule, MatIconModule, DirectivesModule],
  exports: [AvatarComponent]
})
export class SharedModule {}
