import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationPaneComponent } from './notification-pane.component';
import { ImpersonationNotificationComponent } from './impersonation/impersonation-notification.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared/shared.module';
import { DirectivesModule } from '../../shared/directives/directives.module';

@NgModule({
  imports: [CommonModule, MatIconModule, DirectivesModule, SharedModule],
  declarations: [ImpersonationNotificationComponent, NotificationPaneComponent],
  exports: [ImpersonationNotificationComponent]
})
export class NotificationModule {}
