import { Injectable } from '@angular/core';
import { SidebarListFromPaths } from './sidebar-icons.config';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  sidebarList: any;
  sidebarItemList: any;

  constructor() {}

  public setSidebarItemList(landingPage: any, routePrefix = null) {
    let url = '/';
    if (routePrefix) {
      url = url + routePrefix + '/';
    }
    this.sidebarList = SidebarListFromPaths.get(landingPage);
    this.sidebarItemList = [];
    Object.keys(this.sidebarList).forEach(function(key) {
      let sidebarItem = {};
      sidebarItem = {
        url: url + key.toLowerCase(),
        title: key,
        icon: this.sidebarList[key]
      };
      this.sidebarItemList.push(sidebarItem);
    }, this);
    return this.sidebarItemList;
  }
}
