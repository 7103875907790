import { GetRefreshSummaryActions, GET_REFRESH_SUMMARY, GET_REFRESH_SUMMARY_SUCCESS, GET_REFRESH_SUMMARY_FAILURE } from './refresh-summary.actions';
import { RefreshSummaryState } from './refresh-summary.model';

export const RefreshSummaryKey = 'refreshSummary';

export const initialState: RefreshSummaryState = null;

export function RefreshSummaryReducer(state = initialState, action: GetRefreshSummaryActions): any {
  switch (action.type) {
    case GET_REFRESH_SUMMARY:
      return null;
    case GET_REFRESH_SUMMARY_SUCCESS:
      const refreshSummary = action.payload.data;
      return refreshSummary;
    case GET_REFRESH_SUMMARY_FAILURE:
      return state;
    default:
      return state;
  }
}
