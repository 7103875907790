import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { MintAuthenticationModule } from '@mint-libs/authentication';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from './reducers';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AvatarModule } from 'ngx-avatar';
import { MintComponentsModule } from '@mint-libs/components';
import { MeasureTypeMappingEffect, measureTypeMappingFeatureKey, measureTypeMappingReducer } from '@mint-libs/context';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { EffectsModule } from '@ngrx/effects';
import { configurationFeatureKey, configurationReducer, ConfigurationEffects } from '@mint-libs/configuration';
import { StoreRouterConnectingModule, NavigationActionTiming } from '@ngrx/router-store';
import { CustomRouteSerializer } from './core/routing/custom-route-serializer';
import { CoreModule } from './core/core.module';
import { MatIconModule } from '@angular/material/icon';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { ImpersonationGuard } from './shared/shared-dashboard/guards/impersonation.guard';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MintCommonModule } from '@mint-libs/common';
import { MetricViewComponent } from './shared/shared-dashboard/metric-view/metric-view.component';
import { InitializationService } from './core/context/initialization/initialization.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LayoutModule } from './layout/layout.module';
import { ComplianceModule } from './compliance/compliance.module';
import { DisclaimerModule } from './disclaimer/disclaimer.module';
import { disclaimerFeatureKey, disclaimerReducer } from './disclaimer/state/disclaimer.reducer';
import { DisclaimerEffects } from './disclaimer/state/disclaimer.effects';
import { DashboardModule } from './dashboard/dashboard.module';
import { DirectivesModule } from './shared/directives/directives.module';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';

const providers = [{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }, ImpersonationGuard, InitializationService];

@NgModule({
  declarations: [AppComponent, LoginComponent, HomeComponent, MetricViewComponent],
  imports: [
    BrowserModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: environment.aadClientId,
          authority: 'https://login.microsoftonline.com/' + environment.authConfig.tenant,
          redirectUri: window.location.origin + '/login',
          postLogoutRedirectUri: window.location.origin + '/login'
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage
        },
        system: {
          loggerOptions: {
            loggerCallback: () => {},
            piiLoggingEnabled: false
          }
        }
      }),
      {// MSAL Guard Configuration
        interactionType: InteractionType.Redirect
      },
      {// MSAL Interceptor Configuration
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/*', ['user.read', 'user.readbasic.all']],
          [environment.mintapiBaseUrl, [environment.aadResource + '/access_as_user']],
          [environment.mintapiDetailBaseUrl, [environment.aadResource + '/access_as_user']],
          [environment.demoapiBaseUrl, [environment.aadResource + '/access_as_user']]
        ])
      }
    ),
    MatIconModule,
    MatBottomSheetModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatSidenavModule,
    AppRoutingModule,
    CoreModule,
    MintComponentsModule,
    SharedModule,
    MintAuthenticationModule.forRoot(environment),
    AvatarModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    MintCommonModule,
    LayoutModule,
    DashboardModule,
    ComplianceModule,
    DisclaimerModule,
    DirectivesModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouteSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    StoreModule.forFeature(configurationFeatureKey, configurationReducer),
    StoreModule.forFeature(measureTypeMappingFeatureKey, measureTypeMappingReducer),
    StoreModule.forFeature(disclaimerFeatureKey, disclaimerReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([ConfigurationEffects, DisclaimerEffects, MeasureTypeMappingEffect]),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enablePWA
    })
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class MintMobileAppModule {
  constructor() {}
}
