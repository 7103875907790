import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap } from 'rxjs/operators';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '@mint-libs/context';
import * as QuotaAcknowledgmentActions from './quota-acknowledgement.action';
import { of } from 'rxjs';
import * as NavigationActions from '../../../../core/routing/state/navigation.actions';
import { NavigationPaths } from '../../../../core/routing/state/navigation-paths.enum';
import { Action } from '@ngrx/store';
import { CachingService } from '../../../../core/caching/caching.service';

@Injectable()
export class QuotaAcknowledgementEffects {
  @Effect()
  setQuotaAsViewed$ = this.actions$.pipe(
    ofType(QuotaAcknowledgmentActions.SetQuotaAsViewed),
    concatMap(() => {
      return this.dataService.postWithGlobalFilters(this.endpoints.MarkQuotaAsViewed.uri).pipe(
        map(data => QuotaAcknowledgmentActions.SetQuotaAsViewedSuccess({ data })),
        catchError(error => of(QuotaAcknowledgmentActions.SetQuotaAsViewedFailure({ error })))
      );
    })
  );

  @Effect()
  acknowledgeQuota$ = this.actions$.pipe(
    ofType(QuotaAcknowledgmentActions.AcknowledgeQuota),
    concatMap(action => {
      let navigateAction: Action = null;
      let actions: Action[] = null;
      return this.dataService.postWithGlobalFilters(this.endpoints.AcknowledgeQuotaDocument.uri, action).pipe(
        map(data => data),
        switchMap((data: any) => {
          actions = [QuotaAcknowledgmentActions.AcknowledgeQuotaSuccess({ data })];
          this.cachingService.clear();
          navigateAction = NavigationActions.navigate({ path: NavigationPaths.Login });
          actions = [...actions, navigateAction];
          return actions;
        }),
        catchError(error => {
          actions = [QuotaAcknowledgmentActions.AcknowledgeQuotaFailure({ error })];
          navigateAction = NavigationActions.navigate({ path: NavigationPaths.Compliance });
          actions = [...actions, navigateAction];
          return actions;
        })
      );
    })
  );

  constructor(private actions$: Actions, private dataService: DataService, private endpoints: EndpointService, private cachingService: CachingService) {}
}
