import { Injectable } from '@angular/core';
import { ApplicableFiltersConfig } from './applicable-filters-config';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  filtersConfig: ApplicableFiltersConfig;
  constructor() {}

  initializeApplicableFiltersConfig(isFiscalYearApplicable: boolean, isRolesApplicable: boolean, isPartitionApplicable: boolean, isPPAParticipationsApplicable: boolean) {
    this.filtersConfig = new ApplicableFiltersConfig();
    this.filtersConfig.isFiscalYearApplicable = isFiscalYearApplicable;
    this.filtersConfig.isRolesApplicable = isRolesApplicable;
    this.filtersConfig.isPartitionApplicable = isPartitionApplicable;
    this.filtersConfig.isPPAParticipationsApplicable = isPPAParticipationsApplicable;
    return this.filtersConfig;
  }
}
