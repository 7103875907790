import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { PPAInformationState } from './state/ppa-information.reducer';
import { selectPPAInformationState } from './state/ppa-information.selectors';
import { environment } from '../../../environments/environment';
import { ComplianceService } from '../compliance.service';
import { ToDateFormatPipe } from '@mint-libs/common';

@Component({
  selector: 'mint-mobile-ppa',
  templateUrl: './ppa.component.html',
  styleUrls: ['./ppa.component.scss']
})
export class PpaComponent implements OnInit {
  isPPAAvailable = false;
  isURLAvailable = false;
  showURL = false;
  isMultiplePPA = false;
  isOffline = false;
  blobURL: any;
  isLoaded = false;
  selectedPPADetails: any;
  adobeOriginUri: string;
  responseFromAPI: any;
  ppaList: any;
  isDemoMode2: boolean;
  selectedPPA = '';
  isLivePPA: boolean;
  isOfflinePPA: any;
  isPPASigned: boolean;
  isPPANotSigned: boolean;
  isPPANotAvailable: boolean;
  isPPAShown: boolean;

  constructor(private ppaInformationState: Store<PPAInformationState>, private sanitizer: DomSanitizer, private complianceService: ComplianceService, private formatDate: ToDateFormatPipe) {
    this.complianceService.signedDate = this.complianceService.signedDate !== null ? this.formatDate.transform(this.complianceService.signedDate, 'mediumDate') : this.complianceService.signedDate;
  }

  ngOnInit() {
    this.adobeOriginUri = environment.adobeOriginUri;
    this.isDemoMode2 = environment.isDemoMode2;

    this.ppaInformationState.select(selectPPAInformationState).subscribe(
      ppaInformationState => {
        if (!ppaInformationState) {
          return;
        }
        this.isURLAvailable = true;
        this.isPPAAvailable = true;
        this.isLoaded = true;
        this.showURL = ppaInformationState.showURL;
        this.isOffline = ppaInformationState.isOffline;

        if (this.isOffline === true && ppaInformationState.offlinePPAPdf !== null) {
          const file = this.b64ToBlob(ppaInformationState.offlinePPAPdf, 'application/pdf', null);
          const fileURL = URL.createObjectURL(file);
          this.blobURL = fileURL;
        } else {
          this.responseFromAPI = this.sanitizer.bypassSecurityTrustResourceUrl(ppaInformationState.url);
        }
      },
      error => {
        this.isURLAvailable = false;
        this.showURL = false;
      }
    );
    this.setPPAExpressions();
  }

  b64ToBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  setPPAExpressions() {
    this.isPPASigned = !this.isDemoMode2 && this.isPPAAvailable && this.complianceService.documentStatusId == 2;
    this.isPPANotSigned = !this.isDemoMode2 && !this.showURL && this.isPPAAvailable && this.complianceService.documentStatusId != 2;
    this.isPPANotAvailable = !this.isDemoMode2 && !this.isPPAAvailable && !this.showURL;
    this.isOfflinePPA = this.isOffline && this.blobURL;
    this.isPPAShown = !this.isURLAvailable || !this.showURL;
    this.isLivePPA = this.responseFromAPI && this.isURLAvailable && this.showURL;
  }

  // @HostListener('window:message', ['$event'])
  // stopAdobeRedirectionAfterUserSigned(e: any) {
  //   if (e.origin != this.adobeOriginUri) {
  //     return;
  //   }
  //   const adobeEvent = JSON.parse(e.data);
  //   if (adobeEvent.type == 'ESIGN') {
  //     // flush all variable and reload
  //     this.isLoaded = false;
  //     this.selectedPPA = '';
  //     this.selectedPPADetails = null;
  //     this.isPPAAvailable = false;
  //     this.isMultiplePPA = false;
  //     this.complianceService.getPPAList().ppaList;
  //   }
  // }
}
