<div class="tooltip-container">
  <div class="tooltip-header">
    <b><span>The factors below are defined as:</span></b>
    <mat-icon aria-hidden="false" (click)="close()">close</mat-icon>
  </div>
  <ul>
    <li>
      <b>Available Hours for UBI Role —</b> The number Of available hours in {{ fiscalYearLabel }} is {{ customNumber(targetHours.ubiTargetBaselineHours) }}. These are calculated by multiplying
      {{ targetHours.worldwide_WorkDay }} hours per day by {{ targetHours.ubiTargetBaselineHours / targetHours.worldwide_WorkDay }} working days in {{ fiscalYearLabel }}.
    </li>
    <li>
      <b>Work Week —</b> The legal work week hours as approved by HR in the employee's country. For countries with less than a {{ targetHours.worldwide_WorkWeek }}-hour week, an adjustment is made to
      reflect the correct amount.
    </li>
    <li>
      <b>Standard Tasks by Role —</b> A standard allowance given to an employee to spend in training, administrative and other tasks, such as travelling. Standard tasks are defined by the services
      business.
    </li>
    <li><b>Public Holiday —</b> The approved number of public holidays which fall during the work week as defined by HR in the employee's country.</li>
    <li>
      <b>Employee Vacation —</b> The number Of paid vacation days that an individual employee is allotted in a year. Employee vacation may vary by country and years Of service. Years Of service are
      determined as Of December 31st, {{ fiscalYear - 1 }}. Note that this measure does not count banked vacation, but only includes what the employee accrues in a year
    </li>
  </ul>
</div>
