import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CachingService } from './caching.service';

// TODO: Can be moved to a related lib to have this common for both desktop and mobile. Currently libs are not consumed by desktop site
// so first we need to do that before taking this up
@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: CachingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cachedResponse = this.cache.get(req);
    return cachedResponse ? of(cachedResponse) : this.cacheRequest(req, next);
  }

  cacheRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (req.method !== 'POST' && !req.url.includes('GetPPAReceipientViewUrl') && !req.url.includes('GetComplianceStatus')) {
          if (event instanceof HttpResponse) {
            this.cache.put(req, event);
          }
        }
      })
    );
  }
}
