import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoggerService } from '../../../core/logging/logger.service';
import { HeaderConfig } from '../../../layout/header/header-config';
import { HeaderService } from '../../../layout/header/header.service';
import { AppState } from '../../../reducers';
import { BaseViewComponent } from '../../../shared/base/base-view.component';
import { QuotaService } from '../quota.service';

@Component({
  selector: 'mint-mobile-quota-detail',
  templateUrl: './quota-detail.component.html',
  styleUrls: ['./quota-detail.component.scss']
})
export class QuotaDetailComponent extends BaseViewComponent implements OnInit, AfterContentChecked {
  pageTitle = 'Quota by Metric';
  quotaDetailHeaderConfig: HeaderConfig;
  quotaDetail: any;
  userProfile: any;

  constructor(
    protected activeRoute: ActivatedRoute,
    protected router: Router,
    public headerService: HeaderService,
    public quotaService: QuotaService,
    protected loggerService: LoggerService,
    protected appState: Store<AppState>
  ) {
    super(appState, loggerService);
  }

  ngOnInit() {
    this.getQuotaDetails();
  }

  ngAfterContentChecked() {
    this.initializeHeaderConfig();
    this.headerService.headerConfig = this.quotaDetailHeaderConfig;
  }

  initializeHeaderConfig() {
    this.quotaDetailHeaderConfig = new HeaderConfig();
    this.quotaDetailHeaderConfig.isBackIconVisible = true;
    this.quotaDetailHeaderConfig.isHamburgerIconVisible = false;
    this.quotaDetailHeaderConfig.isImpersonationBannerVisible = false;
    this.quotaDetailHeaderConfig.isNotificationIconVisible = false;
    this.quotaDetailHeaderConfig.isAvatarVisible = false;
    this.quotaDetailHeaderConfig.isHelpIconVisible = false;
  }

  getQuotaDetails() {
    this.quotaDetail = this.quotaService.pccs;
    this.quotaDetail = this.quotaService.pccs.filter(pcc => {
      return pcc.PCGIncentiveType !== 'UBI';
    });
    const eligibleTimePeriods = this.quotaDetail[0].Metrics.map(timePeriod => {
      return timePeriod.CodePeriod;
    });

    const isSummedUpTimePeriod = eligibleTimePeriods.find(timePeriod => timePeriod && timePeriod.includes(this.quotaService.selectedTimePeriod.Name));

    this.quotaDetail.forEach(pcc => {
      pcc.Metrics = pcc.Metrics.filter(metric => {
        if (metric.CodePeriod && metric.CodePeriod.split('-').pop() === this.quotaService.selectedTimePeriod.Name) {
          return true;
        } else if (!metric.CodePeriod && !isSummedUpTimePeriod && this.quotaService.selectedTimePeriod.Name !== 'H2') {
          return true;
        } else if (!metric.CodePeriod && this.quotaService.selectedTimePeriod.Name === 'H2' && this.quotaService.isHalfYearlyQuotaSupported) {
          metric.Value = 'N/A';
          return true;
        }
      });
    });
  }
}
