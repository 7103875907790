import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetPayoutSummarySuccess, GetPayoutSummaryFailure, GetPayoutSummayActions, GET_PAYOUT_SUMMARY } from './payout-summary.actions';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@mint-libs/context';

@Injectable()
export class PayoutSummayEffects {
  @Effect()
  loadPayoutSummary$ = this.actions$.pipe(
    ofType(GET_PAYOUT_SUMMARY),
    concatMap(() => {
      let params = new HttpParams();
      params = params.append('FromFreezeSnapshot', 'true');
      params = params.append('ForSummaryView', 'true');
      return this.dataService.getWithGlobalFilters(this.endpoints.GetPayoutSummary.uri, params).pipe(
        map(data => new GetPayoutSummarySuccess({ data })),
        catchError(error => of(new GetPayoutSummaryFailure({ error })))
      );
    })
  );

  constructor(private actions$: Actions<GetPayoutSummayActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
