import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetPaylineSummarySuccess, GetPaylineSummaryFailure, GetPaylineSummayActions, GET_PAYLINE_SUMMARY } from './payline-summary.actions';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@mint-libs/context';

@Injectable()
export class PaylineSummayEffects {
  @Effect()
  loadPaylines$ = this.actions$.pipe(
    ofType(GET_PAYLINE_SUMMARY),
    concatMap(action => {
      let params = null;
      if (action) {
        params = new HttpParams();
        params = params.append('IncentiveType', action.incentiveType);
      }
      return this.dataService.getWithGlobalFilters(this.endpoints.GetAllPaylines.uri, params).pipe(
        map(data => new GetPaylineSummarySuccess({ data })),
        catchError(error => of(new GetPaylineSummaryFailure({ error })))
      );
    })
  );

  constructor(private actions$: Actions<GetPaylineSummayActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
