export class UserRoleInfo {
  isSupportAdmin: boolean;
  isSuperAdmin: boolean;
  isFCA: boolean;
  canSignOnBehalf: boolean;
  isAdminPersona() {
    return this.isSupportAdmin || this.isFCA || this.isSuperAdmin;
  }

  getRoleName(withArticle = false) {
    let roleName = '';
    if (this.isSuperAdmin) {
      roleName = withArticle ? 'a Super Admin' : 'Super Admin';
    } else if (this.isSupportAdmin) {
      roleName = withArticle ? 'an Administrator' : 'Administrator';
    } else if (this.isFCA) {
      roleName = withArticle ? 'an FCA' : 'FCA';
    }
    return roleName;
  }
}
