import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@mint-libs/context';
import { ComplianceStatusActions, ComplianceStatusActionTypes, GetComplianceStatusFailure, GetComplianceStatusSuccess } from './compliance-status.actions';

@Injectable()
export class ComplianceStatusEffects {
  @Effect()
  loadComplianceStatus$ = this.actions$.pipe(
    ofType(ComplianceStatusActionTypes.GetComplianceStatus),
    concatMap(() => {
      return this.dataService.getWithGlobalFilters(this.endpoints.GetComplianceStatus.uri).pipe(
        map(data => new GetComplianceStatusSuccess({ data })),
        catchError(error => of(new GetComplianceStatusFailure({ error })))
      );
    })
  );
  constructor(private actions$: Actions<ComplianceStatusActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
