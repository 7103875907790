import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectFiltersState, selectUserProfileState } from '@mint-libs/context';

export class BaseComponent {
  isOptedOut = false;
  selectedStepId = 0;
  selectedFiscalYear: string;
  constructor(protected appState: Store<AppState>) {
    this.setOptOut();
    this.setSelectedFilters();
  }

  setOptOut() {
    this.appState.select(selectUserProfileState).subscribe(userProfile => {
      if (!userProfile) {
        return;
      }
      this.isOptedOut = userProfile.optoutDetails && userProfile.optoutDetails.isOptOut;
    });
  }

  setSelectedFilters() {
    this.appState.select(selectFiltersState).subscribe(filters => {
      if (!filters || !filters.selectedFilters) {
        return;
      }
      this.selectedStepId = filters.selectedFilters.stepId;
      this.selectedFiscalYear = filters.selectedFilters.fiscalYear;
    });
  }
}
