import { Expose } from 'class-transformer';
import { IDeserializable, BaseModel } from '@mint-libs/common';
import { plainToClass } from 'class-transformer';

export class PPAInformation extends BaseModel implements IDeserializable<PPAInformation> {
  @Expose() isOffline: boolean;
  @Expose() showURL: boolean;
  @Expose() url: string;
  @Expose() offlinePPAPdf: Uint8Array;

  deserialize(from: object): PPAInformation {
    const pPAInformation = plainToClass<PPAInformation, object>(PPAInformation, from, this.classTransformOptions);
    return pPAInformation;
  }
}
