import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mint-mobile-main-content-header',
  templateUrl: './main-content-header.component.html',
  styleUrls: ['./main-content-header.component.scss']
})
export class MainContentHeaderComponent implements OnInit {
  @Input() modalRef: any;
  @Input() template: any;
  @Input() heading: string;
  @Input() showFilters = true;
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.open(template, { windowClass: 'mint-mobile-modal-fullscreen' });
  }
}
