import { AuthActions, AuthActionTypes } from './auth.actions';
import { UserRoleInfo } from '@mint-libs/context';

export const loginFeatureKey = 'authInfo';

export interface AuthState {
  userRoleInfo: UserRoleInfo;
  userInfo: any;
}

export const initialState: AuthState = { userRoleInfo: null, userInfo: null };

export function authReducer(state = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.PerformLogin:
      return state;

    case AuthActionTypes.LoginSuccess:
      const userInfo = action.payload.userInfo;
      return { ...state, userInfo: userInfo };
    case AuthActionTypes.AddUserRoleInfo:
      const userRoleInfo = action.payload.userRoleInfo;
      return { ...state, userRoleInfo: userRoleInfo };

    case AuthActionTypes.LoginFailure:
      return state;

    default:
      return state;
  }
}
