import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTargetSummary from './target-summary.reducer';
import { TargetSummaryState } from './target-summary.model';
import { selectRefreshSummaryState } from '../../refresh-summary/state/refresh-summary.selectors';

export const selectTargetSummaryState = createFeatureSelector<TargetSummaryState>(fromTargetSummary.TargetSummaryKey);

export const selectTargetAndRefreshState = createSelector(selectTargetSummaryState, selectRefreshSummaryState, (targetSummary, refreshSummary) => {
  return { targetSummary, refreshSummary };
});
