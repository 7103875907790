import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { UserProfile } from '../user-profile/models/user-profile.model';
import { ContextState } from '../reducers';
import { selectUserProfileState } from './state/user-profile.selectors';
import { Observable } from 'rxjs';
import { map, filter, take, skip } from 'rxjs/operators';

@Injectable()
export class UserProfileResolver implements Resolve<UserProfile> {
  constructor(private contextStore: Store<ContextState>) {}

  resolve(): Observable<UserProfile> {
    return this.contextStore.select(selectUserProfileState).pipe(
      filter(userProfile => userProfile !== undefined || userProfile !== null),
      map(userProfile => userProfile),
      take(1)
    );
  }
}
