import { Action, createReducer, on } from '@ngrx/store';
import * as ImpersonationActions from './impersonation.actions';
import { ImpersonationState } from './impersonation.state';

export const impersonationFeatureKey = 'impersonation';

export const initialState: ImpersonationState = { isImpersonating: false, impersonatedAlias: '' };

const reducer = createReducer(
  initialState,

  // on(ImpersonationActions.startImpersonation, (state, action) => ()),
  on(ImpersonationActions.stopImpersonation, (state, action) => initialState),
  on(ImpersonationActions.resetImpersonation, (state, action) => initialState),
  on(ImpersonationActions.impersonationSuccess, (state, action) => {
    if (action.data && action.data.isAuthorized) {
      // this.router.navigate(['dashboard']);
      return { isImpersonating: true, impersonatedAlias: action.alias };
    } else {
      // this.router.navigate(['error'], { queryParams: { errorType: ErrorTypes.RoleNotSupported } });
      return state;
    }
  })
);

export function impersonationReducer(state: ImpersonationState | undefined, action: Action) {
  return reducer(state, action);
}
