import { Component } from '@angular/core';
import { ComplianceService } from '../compliance.service';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../layout/header/header.service';

@Component({
  selector: 'mint-mobile-quota',
  templateUrl: './quota.component.html'
})
export class QuotaComponent {
  constructor(private complianceService: ComplianceService, private activateRoute: ActivatedRoute, public headerService: HeaderService) {}

  changeOfRoutes() {
    this.activateRoute.firstChild.data.subscribe(t => (this.headerService.title = t['title']));
    this.activateRoute.firstChild.data.subscribe(s => (this.complianceService.showMainContentHeader = s['showMainContentHeader']));
  }
}
