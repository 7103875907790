import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GraphService, MeasureTypeMappingState, selectMeasureTypeMappingState } from '@mint-libs/context';
import { selectFiltersState } from '../../../core/context/filters/state/filters.selectors';
import { ToDateFormatPipe } from '@mint-libs/common';
import { ComplianceService } from '../../compliance.service';
import { ComplianceStatusContent } from '../../models/compliance-status-content.enum';
import { DocumentStatusLegends } from '../../models/document-status-legends.enum';
import { QuotaDetailState } from './state/quota-detail.reducer';
import { selectQuotaDetailState } from './state/quota-detail.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../../layout/header/header.service';
import { QuotaService } from '../quota.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImpersonationState } from '../../../core/context/impersonation/state/impersonation.state';
import { selectImpersonationStateWithProfile } from '../../../core/context/impersonation/state/impersonation.selectors';
import { BaseViewComponent } from '../../../shared/base/base-view.component';
import { LoggerService } from '../../../core/logging/logger.service';
import { AppState } from '../../../reducers';
import { selectUserRoleInfo } from '../../../core/authentication/state/auth.selectors';

@Component({
  selector: 'mint-mobile-quota-dashboard',
  templateUrl: './base-quota-dashboard.component.html',
  styleUrls: ['./base-quota-dashboard.component.scss']
})
export class BaseQuotaDashboardComponent extends BaseViewComponent implements OnInit {
  userProfile: any;
  fullName: any;
  filters: any;
  thumbnailPhoto = '';
  role: any;
  documentStatusText: any;
  documentStatusContentClass = Object.values(DocumentStatusLegends)[0];
  isHalfYearlyParticipation = false;
  qaDisclaimer: any;
  quotaInfo: any;
  quotaPeriods: any;
  isImpersonating: any;
  ubiTargetBreakup: any;
  isAcknowledgementSectionVisible: any;
  canSignOnBehalf: any;
  measureTypeMapping: MeasureTypeMappingState = new MeasureTypeMappingState([]);

  constructor(
    private graphService: GraphService,
    private formatDate: ToDateFormatPipe,
    private complianceService: ComplianceService,
    private quotaDetailState: Store<QuotaDetailState>,
    private router: Router,
    private activateRoute: ActivatedRoute,
    public headerService: HeaderService,
    public quotaService: QuotaService,
    private sanitizer: DomSanitizer,
    private impersonationState: Store<ImpersonationState>,
    protected loggerService: LoggerService,
    protected appState: Store<AppState>,
    protected measureTypeMappingState: Store<MeasureTypeMappingState>
  ) {
    super(appState, loggerService);
    this.measureTypeMappingState.select(selectMeasureTypeMappingState).subscribe((measureTypeMapping) => {
      this.measureTypeMapping = measureTypeMapping;
      this.setQuotaDetails(this.quotaInfo, this.measureTypeMapping);
    });
  }

  ngOnInit() {
    this.complianceService.showMainContentHeader = true;
    this.headerService.headerConfig = this.headerService.initializeHeaderConfig();
    this.appState.select(selectUserRoleInfo).subscribe(userRoleInfo => {
      if (!userRoleInfo) {
        return;
      }
      this.canSignOnBehalf = userRoleInfo.canSignOnBehalf;
    });
    this.impersonationState.select(selectImpersonationStateWithProfile).subscribe(impersonationWithUserProfileState => {
      if (!(impersonationWithUserProfileState && impersonationWithUserProfileState.userProfile)) {
        return false;
      }
      this.isImpersonating = impersonationWithUserProfileState.impersonationState.isImpersonating;
      this.userProfile = impersonationWithUserProfileState.userProfile;
      this.fullName = this.userProfile.firstName + ' ' + this.userProfile.lastName;
    });

    this.appState.select(selectFiltersState).subscribe(state => {
      if (!state) {
        return;
      }
      const filters: any = state;
      this.filters = filters.selectedFilters.standardTitle;
      this.role = this.filters.name + ': ' + this.formatDate.transform(this.filters.startDate, 'mediumDate') + ' - ' + this.formatDate.transform(this.filters.endDate, 'mediumDate');
    });

    if (this.userProfile) {
      this.graphService.getThumbnailPhoto(this.userProfile.userAlias).subscribe(data => {
        this.thumbnailPhoto = `data:image/jpeg;base64,${data}`;
      });
    }

    this.complianceService.signedDate !== null && this.complianceService.documentStatusId === 2
      ? (this.documentStatusText = ComplianceStatusContent.QuotaAcknowledged + this.formatDate.transform(this.complianceService.signedDate, 'mediumDate'))
      : ((this.documentStatusText = ComplianceStatusContent.QuotaPending), (this.documentStatusContentClass = DocumentStatusLegends.Pending));

    this.quotaDetailState.select(selectQuotaDetailState).subscribe(
      quotaDetailState => {
        this.setQuotaDetails(quotaDetailState, this.measureTypeMapping);
      },
      error => {
        this.quotaService.notAvailable = true;
        this.quotaService.notAvailableMessage = 'An error occurred while getting the Quota details. Please try again later.';
      }
    );
  }

  private setQuotaDetails(quotaDetailState, measureTypeMapping) {
    if (!quotaDetailState) {
      return;
    }
    this.quotaInfo = quotaDetailState;
    this.quotaService.qaConfigData = this.quotaInfo.qaConfigData;
    this.quotaService.isHalfYearlyQuotaSupported = this.quotaInfo.isHalfYearlyQuotaSupported;
    if (this.userProfile && this.userProfile.isUBIPersona()) {
      this.getUBITargetBreakup();
    }
    this.qaDisclaimer = this.sanitizer.bypassSecurityTrustHtml(this.quotaService.qaConfigData.disclaimer);
    this.quotaPeriods = this.getTitles(this.quotaInfo.stepTitle, this.filters.planStartDate, this.filters.planEndDate, this.quotaInfo.isHalfYearlyQuotaSupported);
    this.quotaService.getQuotaDetails(this.quotaInfo.quotaDetail, this.quotaInfo.isHalfYearlyQuotaSupported, measureTypeMapping);
  }

  private getTitles(stepTitles: any, startDate: any, endDate: any, isHalfYearlyQuotaSupported: boolean) {
    const planStartMonth = new Date(startDate).getMonth();
    const planEndMonth = new Date(endDate).getMonth();
    return this.getPeriod(stepTitles, planStartMonth, planEndMonth, isHalfYearlyQuotaSupported);
  }

  private getPeriod(stepTitles, planStartMonth: number, planEndMonth: number, isHalfYearlyQuotaSupported: boolean) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let fyStart = null,
      fyEnd = null,
      index = 0;

    const quarters = Object.keys(stepTitles).sort();

    let titles = quarters.map(period => {
      const startDate = new Date(stepTitles[period].stepStartDate);
      const endDate = new Date(stepTitles[period].stepEndDate);
      const pos = stepTitles[period].codePeriod.indexOf('-');
      if (index === 0) {
        fyStart = monthNames[startDate.getMonth()];
        index++;
      }
      fyEnd = monthNames[endDate.getMonth()];
      return {
        Name: stepTitles[period].codePeriod.slice(pos + 1, stepTitles[period].codePeriod.length),
        Text: '(' + monthNames[startDate.getMonth()] + ' - ' + monthNames[endDate.getMonth()] + ')'
      };
    });

    if (planEndMonth - planStartMonth === 5) {
      const title = planEndMonth === 5 ? 'H2' : 'H1';
      titles.push({
        Name: title,
        Text: '(' + fyStart + ' - ' + fyEnd + ')'
      });
      this.isHalfYearlyParticipation = true;
    } else if (!isHalfYearlyQuotaSupported) {
      titles.push({
        Name: 'FY',
        Text: '(' + fyStart + ' - ' + fyEnd + ')'
      });
    }

    if (isHalfYearlyQuotaSupported && this.userProfile.isRBIPersona()) {
      titles = titles.filter(title => {
        return this.quotaService.halfYearlyQuotaNotSupportedPartitions.indexOf(title.Name) === -1 ? true : false;
      }, this);
      if (titles.length > 0 && !titles.some(title => title.Name === 'H1') && !titles.some(title => title.Name === 'H2')) {
        titles.push({ Name: 'H1', Text: '(Jul - Dec)' });
        titles.push({ Name: 'H2', Text: '(Jan - Jun)' });
      }
    }

    return { titles: titles, isHalfYearlyParticipation: this.isHalfYearlyParticipation };
  }

  getUBITargetBreakup() {
    const metrics = Object.values(this.quotaInfo.quotaDetail.planComponentGroups[0].planComponentCombinations[0].allMetrics);
    this.ubiTargetBreakup = {
      ...this.quotaInfo.ubiQuotaHoursDetails,
      ...Object.assign({}, metrics[metrics.length - 1])
    };
  }

  navigateToQuotaDetails(period) {
    this.quotaService.selectedTimePeriod = period;
    this.router.navigateByUrl(this.activateRoute.snapshot['_routerState'].url + '/' + period.Name);
  }

  h2MessageOnTabChange(isH2MessageHidden) {
    return isH2MessageHidden;
  }
}
