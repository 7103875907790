import { Component, Input, OnInit } from '@angular/core';
import { QuotaAcknowledgementState } from './state/quota-acknowledgement.reducer';
import { AcknowledgeQuota, SetQuotaAsViewed } from './state/quota-acknowledgement.action';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { QuotaService } from '../quota.service';
import { selectQuotaAcknowledgementState } from './state/quota-acknowledgement.selectors';
import { MessagesService } from '@mint-libs/context';
import { ComplianceService } from '../../compliance.service';

@Component({
  selector: 'mint-mobile-quota-acknowledgement',
  templateUrl: './acknowledgement-section.component.html',
  styleUrls: ['./acknowledgement-section.component.scss']
})
export class AcknowledgementSectionComponent implements OnInit {
  @Input() complianceInfo: any;
  @Input() incentiveType: any;
  @Input() isImpersonating: any;
  @Input() canSignOnBehalf: boolean;
  h2QuotaMessage: string;

  constructor(
    private quotaAcknowledgementState: Store<QuotaAcknowledgementState>,
    private router: Router,
    public quotaService: QuotaService,
    private messagesService: MessagesService,
    public complianceService: ComplianceService
  ) {}

  ngOnInit() {
    if (this.quotaService.isHalfYearlyQuotaSupported) {
      this.getH2Message();
    }
  }

  postpone() {
    this.quotaAcknowledgementState.dispatch(SetQuotaAsViewed());
    this.router.navigateByUrl('/compliance');
  }

  acknowledge() {
    let convertToJSONObject = function(input) {
      if (typeof input !== 'object') return input;
      if (Array.isArray(input)) return input.map(convertToJSONObject);
      return Object.keys(input).reduce(function(newObj, key) {
        let val = input[key];
        let newVal = val && typeof val === 'object' ? convertToJSONObject(val) : val;
        newObj[key.charAt(0).toUpperCase() + key.slice(1)] = newVal;
        return newObj;
      }, {});
    };

    this.quotaAcknowledgementState.dispatch(AcknowledgeQuota(convertToJSONObject(this.complianceInfo)));
    this.quotaAcknowledgementState.pipe(select(selectQuotaAcknowledgementState)).subscribe(state => {
      if (!state) {
        return;
      }
      this.quotaService.isQuotaAcknowledgeSuccess = state.isQuotaAcknowledgeSuccess;
    });
  }

  getH2Message() {
    const page = 'Quota';
    this.messagesService.get('H2QuotaMessages').subscribe(msg => {
      this.h2QuotaMessage = JSON.parse(<any>msg)[page][this.incentiveType];
    });
  }
}
