import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FilterState } from '../../core/context/filters/state/filters.reducer';
import { selectFiltersState } from '../../core/context/filters/state/filters.selectors';
import { ToDateFormatPipe } from '@mint-libs/common';
import { UpdateFilters } from '../../core/context/filters/state/filters.actions';
import { Router } from '@angular/router';
import { LoggerService } from '../../core/logging/logger.service';
import { selectConfigurationState } from '@mint-libs/configuration';
import { AppState } from '../../reducers';
import { Filters, UserPreference, StandardTitle, UserProfileState, LoadUserProfile, ResetUserProfile, selectUserProfileState, FilterState as ContextFilterState, UpdateFilters as ContextUpdateFilters } from '@mint-libs/context';
import { ApplicableFiltersConfig } from './applicable-filters-config';
import { ComplianceService } from '../../compliance/compliance.service';

@Component({
  selector: 'mint-mobile-filter',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  userProfile: any;
  filters: Filters;
  applicableFiltersCount = 0;
  fiscalyears = [];
  selectedFiscalYear: string;
  standardtitles = [];
  selectedStandardTitle: StandardTitle;
  selectedStandardTitleStartDate: Date | string;
  partitions = [];
  selectedPartition: number;
  selectedFiscalQuarterId: number;
  resetUserProfile = false;
  config: any;
  halfYearlyQuotaNotSupportedQuarters = ['Q3', 'Q4'];
  filtersLoaded = false;
  ppaFiltersLoaded = false;
  allPPAs = [];
  ppaList = [];
  selectedPPADetails: any;
  resetPPA = false;
  documentStatusId: number;
  @Input() selectedEnvelopeId: any = null;
  @Input() applicableFilters: ApplicableFiltersConfig = new ApplicableFiltersConfig();
  @Output() loadPPA = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal,
    private filtersState: Store<FilterState>,
    private formatDate: ToDateFormatPipe,
    private router: Router,
    private logger: LoggerService,
    private userProfileState: Store<UserProfileState>,
    private appState: Store<AppState>,
    private complianceService: ComplianceService,
    private contextfiltersState: Store<ContextFilterState>,
  ) {}

  ngOnInit() {
    this.logger.trackPageView('filters');
    for (const key in this.applicableFilters) {
      if (this.applicableFilters[key]) {
        this.applicableFiltersCount++;
      }
    }

    this.userProfileState.select(selectUserProfileState).subscribe(userProfileState => {
      if (!userProfileState) {
        return;
      }
      this.userProfile = userProfileState;
    });

    this.filtersState.select(selectFiltersState).subscribe(state => {
      if (!state) {
        return;
      }
      this.filters = state;
    });

    this.appState.select(selectConfigurationState).subscribe(state => {
      if (!state) {
        return;
      }
      this.config = state;
    });

    if (this.applicableFilters.isPPAParticipationsApplicable) {
      this.getPPAFilters();
    }
    this.getSelectedFilters();
    this.getFilters();
  }

  private getFilters() {
    this.populateFiscalYearList(this.filters.fiscalYears);
    this.populateStandarTitleList(this.filters.selectedFilters.fiscalYear, this.filters.standardTitles, false);
    this.setPartition(this.filters.selectedFilters.standardTitle.applicablePartitions, this.filters.selectedFilters.standardTitle.participationID);
  }

  private getSelectedFilters() {
    this.selectedFiscalYear = this.filters.selectedFilters.fiscalYear;
    this.selectedStandardTitle = this.filters.selectedFilters.standardTitle;
    this.selectedStandardTitleStartDate = this.filters.selectedFilters.standardTitle.startDate;
    this.selectedPartition = this.filters.selectedFilters.stepId;
    this.selectedFiscalQuarterId = this.filters.selectedFilters.fiscalQuarterId;
  }

  private populateFiscalYearList(fiscalYears: any) {
    this.fiscalyears = [];
    fiscalYears = fiscalYears.slice().sort((a, b) => {
      return <any>new Date(a.duration.startDate) - <any>new Date(b.duration.startDate);
    });
    fiscalYears.forEach(fiscalYear => {
      this.fiscalyears.push({ key: fiscalYear.key.toString(), text: 'FY' + fiscalYear.key.toString().substr(2) });

      // This condition has been added supporting fiscal years only beyond 2020 for UBI
      if (this.userProfile.isUBIPersona()) {
        this.fiscalyears = this.fiscalyears.filter(fiscalYear => fiscalYear.key > 2020);
        this.selectedFiscalYear = this.config.configs.latestFiscalYear;
      }
      this.filtersLoaded = true;
    });
  }

  private populateStandarTitleList(selectedFiscalYear: string, standardTitles: any, manualSelection: boolean) {
    let applicableStandardTitles = standardTitles.filter(standardTitle => {
      return standardTitle.fiscalYear.key === Number(selectedFiscalYear);
    });

    applicableStandardTitles = applicableStandardTitles.slice().sort((a, b) => {
      return <any>new Date(a.startDate) - <any>new Date(b.startDate);
    });

    this.standardtitles = [];

    applicableStandardTitles.forEach(standardTitle => {
      this.standardtitles.push({
        value: standardTitle,
        text: standardTitle.name + ' (' + this.formatDate.transform(standardTitle.startDate, 'mediumDate') + ' - ' + this.formatDate.transform(standardTitle.endDate, 'mediumDate') + ')'
      });
    });
    if (manualSelection) {
      this.selectedStandardTitle = this.getTitlesByDate(applicableStandardTitles);
      this.selectedStandardTitleStartDate = this.selectedStandardTitle.startDate;
      this.setPartition(this.selectedStandardTitle.applicablePartitions, this.selectedStandardTitle.participationID);
      this.resetUserProfile = true;
    }
  }

  private onFiscalYearChange(manualSelection: boolean) {
    this.populateStandarTitleList(this.selectedFiscalYear, this.filters.standardTitles, manualSelection);
    this.resetUserProfile = true;
  }

  private onStandardTitleChange() {
    const applicableStandardTitle = this.filters.standardTitles.find(title => {
      this.selectedStandardTitle = title;
      return title.startDate === this.selectedStandardTitleStartDate;
    });
    this.resetUserProfile = true;
    this.setPartition(applicableStandardTitle.applicablePartitions, applicableStandardTitle.participationID);
  }

  private onPartitionChange() {
    this.partitions.find(partition => {
      this.selectedFiscalQuarterId = partition.fiscalQuarterId;
      return partition.stepId === this.selectedPartition;
    });
  }

  private getTitlesByDate(applicableStandardTitles: any[] = []) {
    const currentDate = new Date();
    let result = applicableStandardTitles.find(standardTitle => {
      return currentDate >= standardTitle.startDate && currentDate <= standardTitle.endDate;
    });

    if (result == null || result.length === 0) {
      result = applicableStandardTitles[applicableStandardTitles.length - 1];
    }

    return result;
  }

  private getApplicablePartitions(applicablePartitions: any[] = []) {
    const currentDate = new Date();
    let result = applicablePartitions.find(partition => {
      let startDate = new Date(partition.duration.startDate);
      const endDate = new Date(partition.duration.endDate);
      if (this.selectedStandardTitle.startDate > partition.duration.startDate) {
        startDate = new Date(this.selectedStandardTitle.startDate);
      }
      return currentDate.getTime() >= startDate.getTime() && currentDate <= endDate;
    });

    if (result == null || result.length === 0) {
      result = applicablePartitions[applicablePartitions.length - 1];
    }

    return result;
  }

  private setPartition(applicablePartitions: any, participationID: any) {
    this.partitions = [];
    applicablePartitions.forEach(partition => {
      let startDate = partition.duration.startDate;
      if (this.selectedStandardTitle.startDate > partition.duration.startDate) {
        startDate = this.selectedStandardTitle.startDate;
      }
      this.partitions.push({
        stepId: partition.stepId,
        fiscalQuarterId: partition.fiscalQuarterId,
        fiscalSemesterId: partition.fiscalSemesterId,
        startDate: startDate,
        endDate: partition.duration.endDate,
        text: this.setPartitionText(partition, startDate),
        a11yText: this.setPartitionText(partition, startDate, 'MMMM')
      });
    });
    const partitions = this.getApplicablePartitions(applicablePartitions);
    if (this.resetUserProfile) {
      this.selectedPartition = partitions.stepId;
      this.selectedFiscalQuarterId = partitions.fiscalQuarterId;
      this.filtersLoaded = false;
      this.reloadUserProfile(participationID.toString());
      this.userProfileState.select(selectUserProfileState).subscribe(userProfileState => {
        if (!userProfileState) {
          return;
        }
        this.populateFiscalYearList(this.filters.fiscalYears);
      });
    }
  }

  private setPartitionText(partition: any, startDate: string, monthFormat = 'MMM') {
    return partition.key.split('-')[1] + ' YTD (' + this.formatDate.transform(startDate, monthFormat) + ' - ' + this.formatDate.transform(partition.duration.endDate, monthFormat) + ')';
  }

  getPPAFilters() {
    this.allPPAs = this.complianceService.getPPAList().ppaList;
    this.ppaList = [];
    this.allPPAs.forEach(ppa => {
      this.ppaList.push({ selectedPPAText: ppa.docType, publishDate: this.formatDate.transform(ppa.publishDate, 'mediumDate'), envelopeId: ppa.envelopeId, documentStatusId: ppa.documentStatusId });
    });
    // select first PPA if Default one is not available
    this.selectedEnvelopeId = this.selectedEnvelopeId ? this.selectedEnvelopeId : this.ppaList[0].envelopeId;
    this.selectedPPADetails = this.ppaList.find(ppa => ppa.envelopeId === this.selectedEnvelopeId);
    this.documentStatusId = this.selectedPPADetails.documentStatusId;
    this.ppaFiltersLoaded = true;
  }

  onChangePPAParticipation() {
    this.ppaList.find(ppa => {
      this.selectedEnvelopeId = ppa.envelopeId;
      this.documentStatusId = ppa.documentStatusId;
    });
    this.resetPPA = true;
  }

  applyFilters() {
    const updatedFilters = new UserPreference().deserialize({
      fiscalYear: this.selectedFiscalYear.toString(),
      standardTitle: this.selectedStandardTitle,
      stepId: this.selectedPartition,
      fiscalQuarterId: this.selectedFiscalQuarterId,
      partition: null
    });
    if (this.resetUserProfile) {
      this.reloadUserProfile(updatedFilters.standardTitle.participationID.toString());
    }
    if (this.resetPPA) {
      this.loadPPA.emit({ EnvelopeId: this.selectedEnvelopeId, DocumentStatusId: this.documentStatusId });
      this.router.navigateByUrl(this.router.url);
    }
    this.filtersState.dispatch(UpdateFilters({ selectedFilters: updatedFilters, route: this.router.url }));
    this.contextfiltersState.dispatch(new ContextUpdateFilters({ selectedFilters: updatedFilters, route: this.router.url }));
    this.cancelModal();
  }

  reloadUserProfile(participationID: any) {
    this.userProfileState.dispatch(new ResetUserProfile());
    this.userProfileState.dispatch(new LoadUserProfile(participationID));
  }

  closeModal() {
    this.reloadUserProfile(null);
    this.cancelModal();
  }

  cancelModal() {
    this.modalService.dismissAll();
  }
}
