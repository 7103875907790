<div class="splash">
  <div class="loader">
    <div>
      <img src="../../assets/logos/Square200x200Logo_bg.png" alt="Mint Logo" class="loader__logo" />
      <div class="loader__text">
        <span *ngIf="!isAuthenticated()">Signing in...</span>
        <span *ngIf="isAuthenticated()">Initializing...</span>
      </div>
    </div>
  </div>
</div>
