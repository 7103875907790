<div *ngIf="!quotaService.isLoaded">
  <mint-content-loader-large></mint-content-loader-large>
</div>
<div *ngIf="quotaService.isLoaded">
  <div class="document-status-container" [ngClass]="documentStatusContentClass">
    <mat-icon *ngIf="complianceService.signedDate" class="acknowledged-quota-icon">done</mat-icon>{{ documentStatusText
    }}
  </div>
  <div *ngIf="complianceService.signedDate" class="acknowledged-quota-notification">
    This is a snapshot of your quota when you acknowledged QA. For more details please refer to your reports section in
    the desktop version of Mint.
  </div>
  <div class="employee-info-container">
    <div class="employee-info-title">Employee Information</div>
    <div class="employee-info-content-container">
      <ngx-avatar name="{{ fullName }}" initialsSize="2" size="57" [round]="true" alt="Employee Pic"
        src="{{ thumbnailPhoto }}" textSizeRatio="2"></ngx-avatar>
      <div class=" employee-info-content">
        <span>{{ fullName }}</span> <span>({{ role }})</span>
      </div>
    </div>
  </div>
  <div class="quota-summary-tile">
    <mint-mobile-rbi-quota-dashboard *mintApplicableForRBI [quotaPeriods]="quotaPeriods">
    </mint-mobile-rbi-quota-dashboard>
    <mint-mobile-ubi-target-dashboard *mintApplicableForUBI [quotaPeriods]="quotaPeriods"
      [targetHours]="ubiTargetBreakup"></mint-mobile-ubi-target-dashboard>
  </div>
  <mint-mobile-quota-acknowledgement [isImpersonating]="isImpersonating" [canSignOnBehalf]="canSignOnBehalf"
    class="quota-summary-ack-section" [complianceInfo]="quotaInfo" [incentiveType]="userProfile?.incentiveType"
    (h2MessageOnTabChange)="h2MessageOnTabChange($event)"></mint-mobile-quota-acknowledgement>
</div>