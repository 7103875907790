import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap, switchMap } from 'rxjs/operators';
import { EMPTY, of, empty } from 'rxjs';
import * as ImpersonationActions from './impersonation.actions';
import * as NavigationActions from '../../../routing/state/navigation.actions';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { EndpointService } from '@mint-libs/network';
import { Action } from '@ngrx/store';
import { ErrorTypes } from '../../error/error-type.enum';
import { DataService } from '@mint-libs/context';
import { NavigationPaths } from '../../../routing/state/navigation-paths.enum';
import * as UserProfileActions from '@mint-libs/context';

@Injectable()
export class ImpersonationEffects {
  startImpersonation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ImpersonationActions.startImpersonation),
      concatMap(action => {
        let params = new HttpParams();
        params = params.append('alias', action.alias);
        return this.dataService.get(this.endpoints.AuthorizeImpersonation.uri, params).pipe(
          map(data => data),
          switchMap((data: any) => {
            let navigateAction: Action = null;
            let graphAction: Action = null;
            let actions: Action[] = [ImpersonationActions.impersonationSuccess({ alias: action.alias, data: data })];
            if (data && data.isAuthorized) {
              navigateAction = NavigationActions.navigate({ path: NavigationPaths.Login });
              graphAction = new UserProfileActions.GetGraphDetails(action.alias);
              actions = [graphAction, ...actions, navigateAction];
            } else {
              navigateAction = NavigationActions.navigateWithParams({ path: NavigationPaths.Error, queryParams: { errorType: ErrorTypes.ImpersonationInvalidUser } });
              actions = [...actions, navigateAction];
            }
            return actions;
          }),
          catchError(error => of(ImpersonationActions.impersonationFailure({ alias: action.alias, error: error })))
        );
      })
    );
  });

  stopImpersonation$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ImpersonationActions.stopImpersonation),
        tap(payload => {
          this.router.navigate(['admin/impersonation']);
        })
      );
    },
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router, private dataService: DataService, private endpoints: EndpointService) {}
}
