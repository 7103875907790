import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { SharedModule } from '../shared/shared.module';
import { A11yModule } from '@angular/cdk/a11y';
import { HeaderComponent } from './header/header.component';
import { NotificationModule } from './notification/notification.module';
import { MainContentHeaderComponent } from './main-content-header/main-content-header.component';
import { FiltersComponent } from './filters/filters.component';
import { MintComponentsModule } from '@mint-libs/components';
import { DirectivesModule } from '../shared/directives/directives.module';

@NgModule({
  declarations: [SidebarComponent, BackButtonComponent, HeaderComponent, MainContentHeaderComponent, FiltersComponent],
  imports: [CommonModule, DirectivesModule, MatIconModule, FormsModule, RouterModule, SharedModule, A11yModule, NotificationModule, MintComponentsModule],
  exports: [SidebarComponent, BackButtonComponent, HeaderComponent, MainContentHeaderComponent, FiltersComponent]
})
export class LayoutModule {}
