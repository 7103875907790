import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { DisclaimerComponent } from './disclaimer.component';
import { DisclaimerRoutingModule } from './disclaimer-routing.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { MintComponentsModule } from '@mint-libs/components';

@NgModule({
  declarations: [DisclaimerComponent],
  imports: [CommonModule, LayoutModule, DirectivesModule, DisclaimerRoutingModule, MintComponentsModule]
})
export class DisclaimerModule {}
