import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetRefreshSummarySuccess, GetRefreshSummaryFailure, GetRefreshSummaryActions, GET_REFRESH_SUMMARY } from './refresh-summary.actions';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@mint-libs/context';

@Injectable()
export class RefreshSummaryEffects {
  @Effect()
  loadRefreshSummary$ = this.actions$.pipe(
    ofType(GET_REFRESH_SUMMARY),
    concatMap(() => {
      return this.dataService.getWithGlobalFilters(this.endpoints.GetRefreshTimesOfDataSources.uri).pipe(
        map(data => new GetRefreshSummarySuccess({ data })),
        catchError(error => of(new GetRefreshSummaryFailure({ error })))
      );
    })
  );

  constructor(private actions$: Actions<GetRefreshSummaryActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
