import { Notifications } from '../Models/notifications.model';
import { NotificationsActions, NotificationsActionTypes } from './notifications.actions';

export const NotificationsKey = 'notifications';

export class NotificationsState extends Notifications { }

export const initialState: NotificationsState = null;

export function NotificationsReducer(state = initialState, action: NotificationsActions): NotificationsState {
    switch (action.type) {
        case NotificationsActionTypes.GetNotifications:
            // console.log(`REDUCER ${action.type} ${state}`);
            return state;
        case NotificationsActionTypes.GetNotificationsSuccess:
            // console.log(`REDUCER ${action.type} ${state}`);
            // const notifications = action.payload.data;
            const notifications = new Notifications().deserialize(action.payload.data);
            return notifications;
        // return {
        //     ...state,
        //     ...action.payload.data
        // };
        // return state;
        case NotificationsActionTypes.GetNotificationsFailure:
            // console.log(`REDUCER ${action.type} ${state}`);
            console.log('Error: ' + action.payload.error);
            return state;
    }
}
