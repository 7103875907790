<div id="{{ 'legends_1' + componentId }}" class="legend">
  <div id="{{ 'legends_2' + componentId }}" class="legend__item" *ngIf="isBelowthreshold">
    <div id="{{ 'legends_3' + componentId }}" class="legend__item__clr legend__item__clr__legend1"></div>
    <div id="{{ 'legends_4' + componentId }}" class="legend__item__text">Below threshold</div>
  </div>
  <div id="{{ 'legends_5' + componentId }}" class="legend__item" *ngIf="isAboveThreshold">
    <div id="{{ 'legends_6' + componentId }}" class="legend__item__clr legend__item__clr__legend2"></div>
    <div id="{{ 'legends_7' + componentId }}" class="legend__item__text">Above threshold and below target</div>
  </div>
  <div id="{{ 'legends_8' + componentId }}" class="legend__item" *ngIf="isAboveTarget">
    <div id="{{ 'legends_9' + componentId }}" class="legend__item__clr legend__item__clr__legend3"></div>
    <div id="{{ 'legends_10' + componentId }}" class="legend__item__text">
      Above target and below excellence point
    </div>
  </div>
  <div id="{{ 'legends_11' + componentId }}" class="legend__item" *ngIf="isAboveExcellence">
    <div id="{{ 'legends_12' + componentId }}" class="legend__item__clr legend__item__clr__legend4"></div>
    <div id="{{ 'legends_13' + componentId }}" class="legend__item__text">Above excellence point</div>
  </div>
  <div id="{{ 'legends_14' + componentId }}" class="legend__item" *ngIf="isOCPMetric">
    <div id="{{ 'legends_15' + componentId }}" class="legend__item__clr legend__item__clr__legend5"></div>
    <div id="{{ 'legends_16' + componentId }}" class="legend__item__text">No Paylines Associated</div>
  </div>
</div>
