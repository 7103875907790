import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { NavigationPaths } from '../../../core/routing/state/navigation-paths.enum';
import { selectImpersonationStateWithProfile } from '../../../core/context/impersonation/state/impersonation.selectors';
import { InitializationService } from '../../../core/context/initialization/initialization.service';
import { ImpersonationState } from '../../../core/context/impersonation/state/impersonation.state';

@Injectable({
  providedIn: 'root'
})
export class PPADisclaimerGuard implements CanActivate {
  constructor(private initializationService: InitializationService, private impersonationState: Store<ImpersonationState>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const impersonationWithUserProfileState$ = this.impersonationState.select(selectImpersonationStateWithProfile);

    return impersonationWithUserProfileState$.pipe(
      map(impersonationWithUserProfileState => {
        if (!(impersonationWithUserProfileState && impersonationWithUserProfileState.userProfile)) {
          return false;
        }
        if (impersonationWithUserProfileState.userProfile.isEvergreenPPAAvailable() && !impersonationWithUserProfileState.impersonationState.isImpersonating) {
          this.initializationService.initialize(impersonationWithUserProfileState.userProfile.defaultFilter, NavigationPaths.Disclaimer);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
