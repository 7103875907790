<div id="mobile_seller_d_1">
  <mint-mobile-main-content-header [modalRef]="modalRef" [template]="filterstemplate" [heading]="'My Information'"></mint-mobile-main-content-header>
  <mint-mobile-rbi-dashboard *mintApplicableForRBI></mint-mobile-rbi-dashboard>
  <mint-mobile-ubi-dashboard *mintApplicableForUBI></mint-mobile-ubi-dashboard>
</div>

<!-- </ng-container> -->
<ng-template #filterstemplate let-modal>
  <div role="dialog" aria-label="Filters Dialog" tabindex="0"><mint-mobile-filter></mint-mobile-filter></div>
</ng-template>
