import { Action } from '@ngrx/store';

export enum PPAInformationActionTypes {
  GetPPAInformation = '[Compliance] Get PPA Information',
  GetPPAInformationSuccess = '[Compliance] Get PPA Information Success',
  GetPPAInformationFailure = '[Compliance] Get PPA Information Failure'
}

export class GetPPAInformation implements Action {
  readonly type = PPAInformationActionTypes.GetPPAInformation;
  constructor(public payload: any, public envelopeId: any, public documentStatusId: any, public route: string) {}
}

export class GetPPAInformationSuccess implements Action {
  readonly type = PPAInformationActionTypes.GetPPAInformationSuccess;
  constructor(public payload: { data: any }) {}
}

export class GetPPAInformationFailure implements Action {
  readonly type = PPAInformationActionTypes.GetPPAInformationFailure;
  constructor(public payload: { error: any }) {}
}

export type PPAInformationActions = GetPPAInformation | GetPPAInformationSuccess | GetPPAInformationFailure;
