import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { LoggerService } from '../../core/logging/logger.service';
import { Router } from '@angular/router';

@Directive({
  selector: '[analytics-tracker]'
})
export class AnalyticsTrackerDirective implements OnInit {
  @Input() tracker: string;
  @Input() value: string;
  source: string;
  parameters: any;

  constructor(protected loggerService: LoggerService, private router: Router) {}

  ngOnInit() {
    this.source = this.router.url;
  }

  logEvent(type: string) {
    this.parameters = { FromTracker: true, Value: this.value, Type: type, Source: this.source };
    this.loggerService.trackEvent(this.tracker, this.parameters);
  }

  @HostListener('click') onClick() {
    this.logEvent('Click');
  }

  @HostListener('change') onChange() {
    this.logEvent('Change');
  }
}
