import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PayoutSummaryKey, PayoutSummaryReducer } from './payout-summary/state/payout-summary.reducer';
import { PayoutSummayEffects } from './payout-summary/state/payout-summary.effects';
import { PaylineSummaryKey, PaylineSummaryReducer } from './payline-summary/state/payline-summary.reducer';
import { PaylineSummayEffects } from './payline-summary/state/payline-summary.effects';
import { TargetSummaryKey, TargetSummaryReducer } from './target-summary/state/target-summary.reducer';
import { TargetSummaryEffects } from './target-summary/state/target-summary.effects';
import { RefreshSummaryKey, RefreshSummaryReducer } from './refresh-summary/state/refresh-summary.reducer';
import { RefreshSummaryEffects } from './refresh-summary/state/refresh-summary.effects';
import { LegendsComponent } from './legends/legends.component';
import { LegendsService } from './legends/legends.service';
import { PayoutSummaryComponent } from './payout-summary/payout-summary.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  declarations: [LegendsComponent, PayoutSummaryComponent],
  imports: [
    CommonModule,
    SlickCarouselModule,
    DirectivesModule,
    StoreModule.forFeature(PayoutSummaryKey, PayoutSummaryReducer),
    StoreModule.forFeature(PaylineSummaryKey, PaylineSummaryReducer),
    StoreModule.forFeature(TargetSummaryKey, TargetSummaryReducer),
    StoreModule.forFeature(RefreshSummaryKey, RefreshSummaryReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([PayoutSummayEffects, PaylineSummayEffects, TargetSummaryEffects, RefreshSummaryEffects])
  ],
  exports: [LegendsComponent, PayoutSummaryComponent],
  providers: [LegendsService]
})
export class SharedDashboardModule {}
