import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComplianceStatusState } from './state/compliance-status.reducer';
import { selectComplianceStatusWithProfileandFilterLabels } from './state/compliance-status.selectors';
import { ToDateFormatPipe } from '@mint-libs/common';
import { ComplianceStatusContent } from '../models/compliance-status-content.enum';
import { DocumentLegends, DocumentStatusLegends } from '../models/document-status-legends.enum';
import { ComplianceService } from '../compliance.service';
import { ActivatedRoute } from '@angular/router';
import { ComplianceNavigationPaths } from '../models/compliance-navigation-paths.enum';
import { ToasterConfig } from 'angular2-toaster';
import { QuotaService } from '../quota/quota.service';
import { AcknowledgementToasterService } from '../quota/acknowledgement-section/acknowledgement-toaster.service';
import { QuotaAckToasterType } from '../models/quota-ack-toaster.enum';
import { UserPreference, StandardTitle, selectUserProfileState, UpdateFilters as ContextUpdateFilters, FilterState } from '@mint-libs/context';
import { UpdateFilters } from '../../core/context/filters/state/filters.actions';
import { QuotaDetailState } from '../quota/quota-dashboard/state/quota-detail.reducer';
import { GetQuotaDetail } from '../quota/quota-dashboard/state/quota-detail.action';
import { PPAInformationState } from '../ppa/state/ppa-information.reducer';
import { GetPPAInformation } from '../ppa/state/ppa-information.action';
import { GetComplianceStatus } from './state/compliance-status.actions';
import { LoggerService } from '../../core/logging/logger.service';
import { AppState } from '../../reducers';
import { BaseViewComponent } from '../../shared/base/base-view.component';

@Component({
  selector: 'mint-mobile-compliance-dashboard',
  templateUrl: './compliance-dashboard.component.html',
  styleUrls: ['./compliance-dashboard.component.scss'],
  providers: [AcknowledgementToasterService]
})
export class ComplianceDashboardComponent extends BaseViewComponent implements OnInit, AfterViewInit {
  userProfile: any;
  complianceDocumentsStatus: any;
  complianceDocuments = [];
  filters: any;
  config: ToasterConfig = new ToasterConfig({
    showCloseButton: false,
    tapToDismiss: true,
    positionClass: 'toast-center',
    timeout: 3000
  });
  updatedFilters: UserPreference;

  constructor(
    private complianceStatusState: Store<ComplianceStatusState>,
    private formatDate: ToDateFormatPipe,
    public complianceService: ComplianceService,
    private activateRoute: ActivatedRoute,
    private toasterService: AcknowledgementToasterService,
    private quotaService: QuotaService,
    private quotaDetailState: Store<QuotaDetailState>,
    private ppaInformationState: Store<PPAInformationState>,
    protected loggerService: LoggerService,
    protected appState: Store<AppState>,
    protected filtersState: Store<FilterState>,
  ) {
    super(appState, loggerService);
  }

  ngOnInit() {
    this.complianceStatusState.dispatch(new GetComplianceStatus(null));
    this.appState.select(selectUserProfileState).subscribe(userProfileState => {
      if (!userProfileState) {
        return;
      }
      this.userProfile = userProfileState;
    });
    this.complianceStatusState.select(selectComplianceStatusWithProfileandFilterLabels).subscribe(complianceStatusState => {
      if (!(complianceStatusState && complianceStatusState.complianceStatus && complianceStatusState.userProfile)) {
        return;
      }
      this.complianceDocumentsStatus = complianceStatusState;
      this.userProfile = complianceStatusState.userProfile;
      this.getComplianceDocumentsStatus(this.complianceDocumentsStatus.complianceStatus);
    });
  }

  ngAfterViewInit() {
    if (this.quotaService.isQuotaAcknowledgeSuccess !== undefined) {
      const index = this.quotaService.isQuotaAcknowledgeSuccess ? 1 : 0;
      this.toasterService.popToast(Object.values(QuotaAckToasterType)[index], Object.keys(QuotaAckToasterType)[index]);
      this.quotaService.isQuotaAcknowledgeSuccess = undefined;
    }
  }

  navigateTabs(document: any) {
    const route = this.activateRoute.snapshot['_routerState'].url + this.getDocumentRoute(document.DocumentType);
    this.complianceService.documentStatusId = document.DocumentStatusId;
    this.complianceService.signedDate = document.SignedDate;
    if (document.isPPADocument) {
      this.complianceService.envelopeId = document.EnvelopeId;
      this.ppaInformationState.dispatch(new GetPPAInformation(null, this.complianceService.envelopeId, this.complianceService.documentStatusId, route));
    }
    if (document.isQuotaDocument) {
      this.complianceService.participationId = document.ParticipationId;
      if (this.userProfile.isUBIPersona()) {
        this.quotaService.ubiSelectedTab = 0;
      }
      this.getUpdatedFilters();
      this.appState.dispatch(UpdateFilters({ selectedFilters: this.updatedFilters, route: this.activateRoute.snapshot['_routerState'].url + this.getDocumentRoute(document.DocumentType) }));
      this.filtersState.dispatch(new ContextUpdateFilters({ selectedFilters: this.updatedFilters, route: this.activateRoute.snapshot['_routerState'].url + this.getDocumentRoute(document.DocumentType) }));
      this.quotaDetailState.dispatch(new GetQuotaDetail(null, route));
    }
  }

  private getComplianceDocumentsStatus(complianceDocuments: any) {
    this.complianceDocuments = [];
    complianceDocuments.forEach(document => {
      const documentStatusContentClass = Object.values(DocumentLegends)[0];
      const buttonText = null;
      const documentStatus = this.getDocumentStatusTexts(document.signedDate, document.documentStatusId, document.isPPADocument(), documentStatusContentClass, buttonText);
      this.complianceDocuments.push({
        DocumentType: document.documentType,
        DocumentName: this.userProfile.isUBIPersona() && document.isQuotaDocument() ? 'Quota and Target Acknowledgement' : document.docType,
        DocumentStatusId: document.documentStatusId,
        EnvelopeId: document.envelopeId,
        ParticipationId: document.participationId,
        SignedDate: document.signedDate,
        DocumentStatusText: documentStatus.documentStatusText,
        DurationStartDate: this.formatDate.transform(document.participationStartDate, 'mediumDate'),
        DurationEndDate: this.formatDate.transform(document.participationEndDate, 'mediumDate'),
        ButtonText: documentStatus.buttonText,
        isPPADocument: document.isPPADocument(),
        isQuotaDocument: document.isQuotaDocument(),
        DocumentClass: this.getDocumentClass(document.documentType),
        DocumentStatusContentClass: documentStatus.documentStatusContentClass
      });
    });
  }

  private getDocumentStatusTexts(documentSignedDate: any, documentStatusId: number, isPPADocument: boolean, documentStatusContentClass: any, buttonText: string) {
    let documentStatusText = null;
    if (isPPADocument) {
      documentSignedDate !== null && documentStatusId === 2
        ? ((documentStatusText = ComplianceStatusContent.PPASigned + this.formatDate.transform(documentSignedDate, 'mediumDate')), (buttonText = 'View'))
        : this.userProfile.isPPANotAvailable()
          ? (documentStatusText = ComplianceStatusContent.PPANotAvailable)
          : ((documentStatusText = ComplianceStatusContent.PPAPending), (buttonText = 'View to Sign'), (documentStatusContentClass = DocumentStatusLegends.Pending));
    } else {
      documentSignedDate !== null && documentStatusId === 2
        ? ((documentStatusText = ComplianceStatusContent.QuotaAcknowledged + this.formatDate.transform(documentSignedDate, 'mediumDate')), (buttonText = 'View'))
        : documentStatusId === 1
          ? ((documentStatusText = ComplianceStatusContent.QuotaPending), (buttonText = 'View to Accept'), (documentStatusContentClass = DocumentStatusLegends.Pending))
          : (documentStatusText = ComplianceStatusContent.QuotaNotAvailable);
    }
    return { documentStatusText: documentStatusText, buttonText: buttonText, documentStatusContentClass: documentStatusContentClass };
  }

  private getDocumentClass(documentType: any) {
    return Object.values(DocumentLegends)[documentType - 1];
  }

  private getDocumentRoute(documentType: any) {
    return Object.values(ComplianceNavigationPaths)[documentType - 1];
  }

  private getUpdatedFilters() {
    const stds: StandardTitle = this.userProfile.standardTitles.filter(std => {
      return std.participationID == this.complianceService.participationId;
    });

    this.updatedFilters = new UserPreference().deserialize({
      fiscalYear: this.userProfile.defaultFilter.fiscalYear.toString(),
      standardTitle: stds[0],
      stepId: this.userProfile.defaultFilter.stepId,
      fiscalQuarterId: this.userProfile.defaultFilter.fiscalQuarterId,
      partition: null
    });
  }
}
