import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouterHistoryService } from './services/router-history.service';

@Component({
  selector: 'mint-mobile-back',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  @Input() isBackIconVisible: boolean;
  constructor(public router: Router, public routerHistoryService: RouterHistoryService) {}

  onClick($event: MouseEvent): void {
    $event.preventDefault();
    if (this.routerHistoryService.previousUrl.getValue() === '/login') {
      window.history.go(-2);
    } else {
      window.history.back();
    }
  }
}
