import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { ImpersonationState } from '../../../core/context/impersonation/state/impersonation.state';
import { selectImpersonationState } from '../../../core/context/impersonation/state/impersonation.selectors';
import { stopImpersonation } from '../../../core/context/impersonation/state/impersonation.actions';

@Component({
  selector: 'mint-impersonation-notification',
  styleUrls: ['./impersonation-notification.component.scss'],
  templateUrl: './impersonation-notification.component.html'
})
export class ImpersonationNotificationComponent implements OnInit {
  isImpersonating: any;
  impersonatedAlias: any;

  constructor(private appState: Store<AppState>, private impersonationState: Store<ImpersonationState>) {}

  ngOnInit(): void {
    this.appState.select(selectImpersonationState).subscribe(state => {
      this.isImpersonating = state.isImpersonating;
      this.impersonatedAlias = state.impersonatedAlias;
    });
  }

  stopImpersonation() {
    this.impersonationState.dispatch(stopImpersonation());
  }
}
