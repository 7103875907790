import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetTargetSummarySuccess, GetTargetSummaryFailure, GetTargetSummaryActions, GET_TARGET_SUMMARY } from './target-summary.actions';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@mint-libs/context';

@Injectable()
export class TargetSummaryEffects {
  @Effect()
  loadTargetSummary$ = this.actions$.pipe(
    ofType(GET_TARGET_SUMMARY),
    concatMap(() => {
      return this.dataService.getWithGlobalFilters(this.endpoints.GetTargetSummary.uri).pipe(
        map(data => new GetTargetSummarySuccess({ data })),
        catchError(error => of(new GetTargetSummaryFailure({ error })))
      );
    })
  );

  constructor(private actions$: Actions<GetTargetSummaryActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
