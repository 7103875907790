import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetRubiTargetSummarySuccess, GetRubiTargetSummaryFailure, GetRubiTargetSummaryActions, GET_RUBITARGET_SUMMARY } from './rubi-target-summary.actions';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@mint-libs/context';

@Injectable()
export class RubiTargetSummaryEffects {
  @Effect()
  loadRubiTargetSummary$ = this.actions$.pipe(
    ofType(GET_RUBITARGET_SUMMARY),
    concatMap(() => {
      let params = null;
      params = new HttpParams();
      params = params.append('IncentiveType', 'RUBI');
      params = params.append('FromFreezeSnapshot', 'false');
      return this.dataService.getWithGlobalFilters(this.endpoints.GetTargetSummary.uri, params).pipe(
        map(data => new GetRubiTargetSummarySuccess({ data })),
        catchError(error => of(new GetRubiTargetSummaryFailure({ error })))
      );
    })
  );

  constructor(private actions$: Actions<GetRubiTargetSummaryActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
