import { LoggerService } from '../../core/logging/logger.service';
import { BaseComponent } from './base.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

export class BaseViewComponent extends BaseComponent {
  constructor(protected appState: Store<AppState>, protected logger: LoggerService) {
    super(appState);
    logger.trackPageView();
  }
}
