import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ImpersonationGuard } from './shared/shared-dashboard/guards/impersonation.guard';
import { UserProfileResolver } from '@mint-libs/context';
import { IncentiveTypeGuard } from './shared/shared-dashboard/guards/incentive-type.guard';
import { ComplianceGuard } from './shared/shared-dashboard/guards/compliance.guard';
import { RollOverGuard } from './shared/shared-dashboard/guards/rollover.guard';
import { PPADisclaimerGuard } from './shared/shared-dashboard/guards/ppa-disclaimer.guard';
import { BaseSellerDashboardComponent } from './dashboard/base-seller-dashboard.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [ImpersonationGuard],
    canActivateChild: [MsalGuard],
    resolve: { userProfile: UserProfileResolver },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'dashboard',
        component: BaseSellerDashboardComponent,
        resolve: { userProfile: UserProfileResolver },
        canActivate: [IncentiveTypeGuard, RollOverGuard, ComplianceGuard, PPADisclaimerGuard],
        runGuardsAndResolvers: 'always',
        data: {
          title: 'Dashboard'
        }
      },
      {
        path: 'compliance',
        loadChildren: () => import('./compliance/compliance.module').then(m => m.ComplianceModule),
        resolve: { userProfile: UserProfileResolver },
        runGuardsAndResolvers: 'always',
        data: {
          title: 'Compliance'
        }
      },
      {
        path: 'feedback',
        loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
        data: {
          title: 'Feedback'
        }
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        data: {
          title: 'Notifications'
        }
      }
    ]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [MsalGuard],
    runGuardsAndResolvers: 'always',
    data: {
      title: 'Admin'
    }
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
    canActivate: [MsalGuard],
    runGuardsAndResolvers: 'always',
    data: {
      title: 'Error'
    }
  },
  {
    path: 'disclaimer',
    canActivate: [MsalGuard],
    loadChildren: () => import('./disclaimer/disclaimer.module').then(m => m.DisclaimerModule),
    data: {
      title: 'Disclaimer'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
