<div id="mobile-header">
  <div>
    <button
      id="mobile_close_icon"
      class="mat-icon-container"
      aria-label="Close Filter Modal"
      tabindex="0"
      ngbAutofocus
      (click)="cancelModal()"
      (keyup.enter)="cancelModal()"
      analytics-tracker
      [tracker]="'Filters_Close'"
      [value]="'Button_Click'"
    >
      <mat-icon aria-hidden="false">close</mat-icon>
    </button>
    <div class="title" id="mobile_filters_title" role="heading" tabindex="0" aria-label="Filters">Filters ({{ applicableFiltersCount }})</div>
  </div>
</div>
<div id="filters-content" fxLayout="column" fxLayoutGap="6.35vw" fxFlexFill>
  <div class="filter-modal-heading" aria-label="Filter By" role="heading" aria-level="3" tabindex="0">Filter by</div>
  <div class="filters-section" *ngIf="applicableFilters.isFiscalYearApplicable">
    <div class="filter-tile" role="radiogroup">
      <div id="fiscalYeartitle" class="filter-radiogroup-title" aria-label="Fiscal Year" tabindex="0">Fiscal Year</div>
      <div *ngIf="!filtersLoaded"><mint-content-loader-medium></mint-content-loader-medium></div>
      <div *ngIf="filtersLoaded" id="fiscalyears" fxLayout="column">
        <label
          class="form-check-label"
          *ngFor="let fiscalyear of fiscalyears; let k = index"
          for="{{ 'fiscalyear' + (k + 1) }}"
          [attr.aria-labelledby]="'fiscalYeartitle fiscalyearlabel' + (k + 1)"
          [attr.aria-checked]="fiscalyear.key === selectedFiscalYear"
          role="radio"
          ><input
            type="radio"
            class="form-check-input"
            id="{{ 'fiscalyear' + (k + 1) }}"
            name="optradioFY"
            [value]="fiscalyear.key"
            [(ngModel)]="selectedFiscalYear"
            (ngModelChange)="onFiscalYearChange(true)"
            analytics-tracker
            [tracker]="'Filters_FiscalYearChange'"
          />
          <label class="radioButtonText" id="{{ 'fiscalyearlabel' + (k + 1) }}" for="{{ 'fiscalyear' + (k + 1) }}" [attr.aria-label]="fiscalyear.text"> {{ fiscalyear.text }}</label>
          <label class="customRadioButton" for="{{ 'fiscalyear' + (k + 1) }}"></label>
        </label>
      </div>
    </div>
  </div>
  <div class="filters-section" *ngIf="applicableFilters.isRolesApplicable">
    <div class="filter-tile" role="radiogroup">
      <div id="roletitle" class="filter-radiogroup-title" aria-label="Role" tabindex="0">Role</div>
      <div id="roles" fxLayout="column">
        <label
          class="form-check-label"
          *ngFor="let standardtitle of standardtitles; let i = index"
          for="{{ 'standardtitle' + (i + 1) }}"
          [attr.aria-labelledby]="'roletitle standardtitlelabel' + (i + 1)"
          role="radio"
          [attr.aria-checked]="standardtitle.value.startDate === selectedStandardTitleStartDate"
          ><input
            type="radio"
            class="form-check-input"
            id="{{ 'standardtitle' + (i + 1) }}"
            name="optradiostandardtitle"
            [value]="standardtitle.value.startDate"
            [(ngModel)]="selectedStandardTitleStartDate"
            (ngModelChange)="onStandardTitleChange()"
            analytics-tracker
            [tracker]="'Filters_RoleChange'"/>
          <label id="{{ 'standardtitlelabel' + (i + 1) }}" class="radioButtonText" for="{{ 'standardtitle' + (i + 1) }}" [attr.aria-label]="standardtitle.text"> {{ standardtitle.text }}</label
          ><label class="customRadioButton" for="{{ 'standardtitle' + (i + 1) }}"></label
        ></label>
      </div>
    </div>
  </div>
  <div class="filters-section" *ngIf="applicableFilters.isPartitionApplicable">
    <div class="filter-tile" role="radiogroup">
      <div id="partitiontitle" class="filter-radiogroup-title" aria-label="YTD" tabindex="0">YTD</div>
      <div id="partitions" fxLayout="column">
        <label
          class="form-check-label"
          *ngFor="let partition of partitions; let j = index"
          for="{{ 'ytd' + (j + 1) }}"
          [attr.aria-label]="'Partition ' + partition.a11yText"
          role="radio"
          [attr.aria-checked]="partition.stepId === selectedPartition"
          ><input
            type="radio"
            class="form-check-input"
            id="{{ 'ytd' + (j + 1) }}"
            name="optradioytd"
            [value]="partition.stepId"
            [(ngModel)]="selectedPartition"
            (ngModelChange)="onPartitionChange()"
            analytics-tracker
            [tracker]="'Filters_PartitionChange'"/>
          <label id="{{ 'partitionlabel' + (j + 1) }}" class="radioButtonText" for="{{ 'ytd' + (j + 1) }}" [attr.aria-label]="partition.a11yText">{{ partition.text }}</label
          ><label class="customRadioButton" for="{{ 'ytd' + (j + 1) }}"></label
        ></label>
      </div>
    </div>
  </div>
  <div class="filters-section" *ngIf="applicableFilters.isPPAParticipationsApplicable">
    <div class="filter-tile" role="radiogroup">
      <div *ngIf="!ppaFiltersLoaded"><mint-content-loader-medium></mint-content-loader-medium></div>
      <div *ngIf="ppaFiltersLoaded" id="ppaparticipationtitle" class="filter-radiogroup-title" aria-label="PPA Participations" tabindex="0">PPA Participations</div>
      <div id="ppaparticipations" fxLayout="column">
        <label
          class="form-check-label"
          *ngFor="let ppa of ppaList; let i = index"
          for="{{ 'ppa' + (i + 1) }}"
          [attr.aria-labelledby]="'ppatitle ppalabel' + (i + 1)"
          role="radio"
          [attr.aria-checked]="ppa.envelopeId === selectedEnvelopeId"
          ><input
            type="radio"
            class="form-check-input"
            id="{{ 'ppa' + (i + 1) }}"
            name="optradioppa"
            [value]="ppa.envelopeId"
            [(ngModel)]="selectedEnvelopeId"
            (ngModelChange)="onChangePPAParticipation()"
            analytics-tracker
            [tracker]="'Filters_PPAParticipationChange'"/>
          <label id="{{ 'ppalabel' + (i + 1) }}" class="radioButtonText" for="{{ 'ppa' + (i + 1) }}" [attr.aria-label]="ppa.selectedPPAText"> {{ ppa.selectedPPAText }} - {{ ppa.publishDate }}</label
          ><label class="customRadioButton" for="{{ 'ppa' + (i + 1) }}"></label
        ></label>
      </div>
    </div>
  </div>
  <div id="filters-footer">
    <div fxLayout>
      <button class="footer__btn footer__btn-primary" (click)="applyFilters()" analytics-tracker [tracker]="'Filters_Apply'" [value]="'Button_Click'">Apply</button>
      <button class="footer__btn footer__btn-cancel" style="margin-left: 3vw;" (click)="cancelModal()" analytics-tracker [tracker]="'Filters_Cancel'" [value]="'Button_Click'">Cancel</button>
    </div>
  </div>
</div>