import { Action } from '@ngrx/store';

export const GET_RUBITARGET_SUMMARY = 'Get RubiTarget Summary';
export const GET_RUBITARGET_SUMMARY_SUCCESS = 'Get RubiTarget Summary Success';
export const GET_RUBITARGET_SUMMARY_FAILURE = 'Get RubiTarget Summary Failure';

export class GetRubiTargetSummary implements Action {
  readonly type = GET_RUBITARGET_SUMMARY;

  constructor(public payload: any) {}
}

export class GetRubiTargetSummarySuccess implements Action {
  readonly type = GET_RUBITARGET_SUMMARY_SUCCESS;

  constructor(public payload: any) {}
}

export class GetRubiTargetSummaryFailure implements Action {
  readonly type = GET_RUBITARGET_SUMMARY_FAILURE;

  constructor(public payload: any) {}
}

export type GetRubiTargetSummaryActions = GetRubiTargetSummary | GetRubiTargetSummarySuccess | GetRubiTargetSummaryFailure;
