import { Component, OnInit, Input } from '@angular/core';
import { MetricTile } from './metric-tile.model';

@Component({
  selector: 'lib-metric-tile',
  templateUrl: './metric-tile.component.html',
  styleUrls: ['./metric-tile.component.scss']
})
export class MintMetricTileComponent implements OnInit {
  @Input() componentId;
  @Input() tileData: MetricTile;

  tileDataValueClass: any;
  tileDataTextClass: any;

  constructor() {}

  ngOnInit() {
    if (this.tileData.isMobile) {
      this.tileDataValueClass = 'stat__details-value-mobile';
      this.tileDataTextClass = 'stat__details-desc-mobile';
    } else {
      this.tileDataValueClass = 'stat__details-value-web';
      this.tileDataTextClass = 'stat__details-desc-web';
    }
  }
}
