import { PPAInformationActions, PPAInformationActionTypes } from './ppa-information.action';
import { PPAInformation } from '../../models/ppa-information.model';

export const PPAInformationFeatureKey = 'PPAInformation';

export class PPAInformationState extends PPAInformation {}

export const pPAInformationState: PPAInformationState = null;

export function PPAInformationReducer(state = pPAInformationState, action: PPAInformationActions): PPAInformationState {
  switch (action.type) {
    case PPAInformationActionTypes.GetPPAInformation:
      return state;

    case PPAInformationActionTypes.GetPPAInformationSuccess:
      const pPAInformation = new PPAInformation().deserialize(action.payload.data);
      return pPAInformation;

    case PPAInformationActionTypes.GetPPAInformationFailure:
      console.log('Error: ' + action.payload.error);
      return state;

    default:
      return state;
  }
}
