import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPayoutSummary from './payout-summary.reducer';
import { PayoutSummaryState } from './payout-summary.model';
import { selectFiltersState } from '@mint-libs/context';

export const selectPayoutSummaryState = createFeatureSelector<PayoutSummaryState>(fromPayoutSummary.PayoutSummaryKey);

export const selectPayoutWithFilterLabels = createSelector(selectPayoutSummaryState, selectFiltersState, (payoutSummary, filters) => {
  return { payoutSummary: payoutSummary, filterLabel: filters.filterLabel, fiscalYearLabel: filters.fiscalYearLabel };
});
