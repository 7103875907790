<div class="compliance-dashboard-container">
  <div *ngFor="let compliancedocument of complianceDocuments" class="document-container" [ngClass]="compliancedocument.DocumentClass">
    <div class="documentName">{{ compliancedocument.DocumentName }}</div>
    <div class="documentStatusText" [ngClass]="compliancedocument.DocumentStatusContentClass">{{ compliancedocument.DocumentStatusText }}</div>
    <div *ngIf="compliancedocument.isQuotaDocument" class="durationText">Duration: {{ compliancedocument.DurationStartDate }} - {{ compliancedocument.DurationEndDate }}</div>
    <button
      *ngIf="compliancedocument.ButtonText"
      class="action-btn document-btn"
      (click)="navigateTabs(compliancedocument)"
      (keyup.enter)="navigateTabs(compliancedocument)"
      analytics-tracker
      [tracker]="compliancedocument.ButtonText"
      [value]="compliancedocument.DocumentName"
    >
      {{ compliancedocument.ButtonText }}
    </button>
  </div>
  <toaster-container [toasterconfig]="config"></toaster-container>
</div>
