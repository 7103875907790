import { GET_PAYLINE_SUMMARY, GET_PAYLINE_SUMMARY_SUCCESS, GET_PAYLINE_SUMMARY_FAILURE, RESET } from './payline-summary.actions';
import * as paylineSummaryActions from './payline-summary.actions';
import { PaylineSummaryState } from './payline-summary.model';
import { PaylineDetail } from '@mint-libs/common';

export const PaylineSummaryKey = 'paylineSummary';

export const initialState: PaylineSummaryState = null;

export function PaylineSummaryReducer(state = initialState, action: paylineSummaryActions.GetPaylineSummayActions): PaylineSummaryState {
  switch (action.type) {
    case GET_PAYLINE_SUMMARY:
      return null;
    case GET_PAYLINE_SUMMARY_SUCCESS:
      const paylineSummary = new PaylineDetail().deserialize(action.payload.data);
      return paylineSummary;
    case GET_PAYLINE_SUMMARY_FAILURE:
      return state;
    default:
      return state;
  }
}
