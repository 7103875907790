import { Action } from '@ngrx/store';

export const GET_NOTIFICATIONS = 'Get Notifications';
export const GET_NOTIFICATIONS_SUCCESS = 'Get Notifications Success';
export const GET_NOTIFICATIONS_FAILURE = 'Get Notifications Failure';

export enum NotificationsActionTypes {
    GetNotifications = '[Notifications] Get Notifications',
    GetNotificationsSuccess = '[Notifications] Get Notifications Success',
    GetNotificationsFailure = '[Notifications] Get Notifications Failure',
}

export class GetNotifications implements Action {
    readonly type = NotificationsActionTypes.GetNotifications;
    constructor() { }
}

export class GetNotificationsSuccess implements Action {
    readonly type = NotificationsActionTypes.GetNotificationsSuccess;
    constructor(public payload: { data: any }) { }
}

export class GetNotificationsFailure implements Action {
    readonly type = NotificationsActionTypes.GetNotificationsFailure;
    constructor(public payload: { error: any }) { }
}

export type NotificationsActions = GetNotifications | GetNotificationsSuccess | GetNotificationsFailure;
