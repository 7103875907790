import { NavigationPaths } from '../../core/routing/state/navigation-paths.enum';

export enum SidebarIcons {
  Dashboard = 'dashboard',
  Compliance = 'article'
}

export enum SidebarIconsCompliance {
  Compliance = 'article'
}

export enum SidebarIconsAdmin {
  Impersonation = 'people'
}

export const SidebarListFromPaths = new Map<NavigationPaths, any>([
  [NavigationPaths.Compliance, SidebarIconsCompliance],
  [NavigationPaths.Dashboard, SidebarIcons],
  [NavigationPaths.Impersonation, SidebarIconsAdmin]
]);
