import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { selectAuthInfo, selectUserRoleInfo } from '../authentication/state/auth.selectors';
import { AppState } from '../../reducers';
import { selectUserProfileState, CorrelatorService } from '@mint-libs/context';
import { TelemetryService } from '@mint-libs/logging';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  userAlias: string;
  isSeller: boolean;
  isNonParticipantManager: boolean;
  isManager: boolean;
  isFCA: boolean;
  isAdministrator: boolean;
  isSuperAdmin: boolean;
  incentiveType: string;

  constructor(protected telemetry: TelemetryService, protected appState: Store<AppState>, protected router: Router, private correlator: CorrelatorService) {
    this.appState.select(selectAuthInfo).subscribe(authInfo => {
      if (!authInfo || !authInfo.userInfo) {
        return;
      }
      const userName: string = authInfo.userInfo.userName;
      this.userAlias = userName.substring(0, userName.indexOf('@'));
    });
    this.getRoleInfo();
    if (!this.router.url.includes('/admin')) {
      this.getUserProfileData();
    }
  }

  trackPageView(name: string = null) {
    const routeName = name || this.router.url.substring(1);
    const url = window.location.origin;
    const props = {
      name: routeName,
      url: url
    };
    this.telemetry.trackPageview(routeName, this.getProps(props));
  }

  trackException(message: string, stack: string = null, severity = null, props: any = null) {
    const error = { name: 'exception', message: message, stack: stack };
    this.telemetry.trackException(error, severity, this.getProps(props));
  }

  trackError(error: Error, severity: string = null, props: any = null) {
    this.telemetry.trackException(error, severity, this.getProps(props));
  }

  trackEvent(eventName: string, props: any = null) {
    const featureName = this.router.url.substring(1);
    this.telemetry.trackEvent(featureName, eventName, this.getProps(props));
  }

  getProps(props = null) {
    props = props || {};
    Object.assign(props, {
      UserAlias: this.userAlias,
      IsFCA: this.isFCA,
      IsAdministrator: this.isAdministrator,
      IsSuperAdmin: this.isSuperAdmin
    });
    if (!this.router.url.includes('/admin')) {
      Object.assign(props, {
        IsSeller: this.isSeller,
        IncentiveType: this.incentiveType,
        IsManager: this.isManager,
        IsNonParticipantManager: this.isNonParticipantManager
      });
    }
    Object.assign(props, {
      XCV: this.correlator.getCorrelationId(),
      SessionId: this.correlator.getSessionId(),
      ComponentId: '2f537d0c-93ec-4270-9ec6-54d82bf62f93',
      ComponentName: 'Mint - Mobile',
      Service: 'Seller Experience',
      ServiceOffering: 'Finance',
      ServiceLine: 'Incentive Compensation',
      IctoId: '12863',
      EnvironmentName: environment.environment,
      AiAppKey: environment.appInsightsKey,
    });
    return props;
  }

  getRoleInfo() {
    this.appState.select(selectUserRoleInfo).subscribe(roleInfo => {
      if (!roleInfo) {
        return;
      }
      this.isFCA = roleInfo.isFCA;
      this.isAdministrator = roleInfo.isAdminPersona();
      this.isSuperAdmin = roleInfo.isSuperAdmin;
    });
  }

  getUserProfileData() {
    const userProfileState = this.appState.select(selectUserProfileState);
    userProfileState.subscribe(state => {
      if (!state) {
        return;
      }
      this.incentiveType = state.incentiveType;
      this.isSeller = state.isSeller;
      this.isManager = state.isManager;
      this.isNonParticipantManager = state.isNonParticipantManager;
    });
  }
}
