import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap } from 'rxjs/operators';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '@mint-libs/context';
import * as DisclaimerActions from './disclaimer.actions';
import { of } from 'rxjs';
import { Action } from '@ngrx/store';
import { NavigationPaths } from '../../core/routing/state/navigation-paths.enum';
import * as NavigationActions from '../../core/routing/state/navigation.actions';
import { CachingService } from '../../core/caching/caching.service';

@Injectable()
export class DisclaimerEffects {
  @Effect()
  setDisclaimerAcceptance$ = this.actions$.pipe(
    ofType(DisclaimerActions.SetDisclaimerAcceptance),
    concatMap(action => {
      let navigateAction: Action = NavigationActions.navigate({ path: NavigationPaths.Login });
      let actions: Action[] = null;
      return this.dataService.postWithGlobalFilters(this.endpoints.SetDisclaimerAcceptance.uri, action).pipe(
        map(data => data),
        switchMap((data: any) => {
          actions = [DisclaimerActions.SetDisclaimerAcceptanceSuccess({ data })];
          this.cachingService.clear();
          actions = [...actions, navigateAction];
          return actions;
        }),
        catchError(error => {
          actions = [DisclaimerActions.SetDisclaimerAcceptanceFailure({ error })];
          actions = [...actions, navigateAction];
          return actions;
        })
      );
    })
  );

  constructor(private actions$: Actions, private dataService: DataService, private endpoints: EndpointService, private cachingService: CachingService) {}
}
