import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UtilizationMetricFeatureKey, UtilizationMetricReducer } from '../ubi/utilization-metric/state/utilization-metric.reducer';
import { UtilizationMetricEffects } from '../ubi/utilization-metric/state/utilization-metric.effects';
import { iTAInformationFeatureKey, iTAInformationReducer } from '../ubi/ita-information/state/ita-information.reducer';
import { ITAInformationEffects } from '../ubi/ita-information/state/ita-information.effects';
import { workingHoursFeatureKey, workingHoursReducer } from '../ubi/working-hours/state/working-hours.reducer';
import { WorkingHoursEffects } from '../ubi/working-hours/state/working-hours.effects';
import { RubiTargetSummaryKey, RubiTargetSummaryReducer } from '../ubi/rubi-target-summary/state/rubi-target-summary.reducer';
import { RubiTargetSummaryEffects } from '../ubi/rubi-target-summary/state/rubi-target-summary.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(UtilizationMetricFeatureKey, UtilizationMetricReducer),
    StoreModule.forFeature(iTAInformationFeatureKey, iTAInformationReducer),
    StoreModule.forFeature(workingHoursFeatureKey, workingHoursReducer),
    StoreModule.forFeature(RubiTargetSummaryKey, RubiTargetSummaryReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([UtilizationMetricEffects, ITAInformationEffects, WorkingHoursEffects, RubiTargetSummaryEffects])
  ]
})
export class UbiSellerDashboardModule {}
