import { Injectable } from '@angular/core';
import { HeaderConfig } from './header-config';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  defaultHeaderConfig: HeaderConfig = {
    isBackIconVisible: false,
    isHamburgerIconVisible: true,
    isImpersonationBannerVisible: false,
    isNotificationIconVisible: false,
    isAvatarVisible: true,
    isHelpIconVisible: false
  };
  headerConfig: HeaderConfig;
  title: string;
  constructor() {}

  initializeHeaderConfig() {
    this.headerConfig = new HeaderConfig();
    this.headerConfig.isBackIconVisible = true;
    this.headerConfig.isHamburgerIconVisible = true;
    this.headerConfig.isImpersonationBannerVisible = true;
    this.headerConfig.isNotificationIconVisible = true;
    this.headerConfig.isAvatarVisible = true;
    this.headerConfig.isHelpIconVisible = false;
    return this.headerConfig;
  }
}
