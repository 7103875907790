import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UtilizationMetricActions, UtilizationMetricActionTypes, GetUtilizationMetricSuccess, GetUtilizationMetricFailure } from './utilization-metric.actions';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@mint-libs/context';

@Injectable()
export class UtilizationMetricEffects {
  @Effect()
  loadUtilizationMetric$ = this.actions$.pipe(
    ofType(UtilizationMetricActionTypes.GetUtilizationMetric),
    concatMap(action => {
      let params = null;
      if (action && action.periodStatus) {
        params = new HttpParams();
        params = params.append('periodStatus', action.periodStatus);
      }
      return this.dataService.getWithGlobalFilters(this.endpoints.GetUtilizationDetails.uri, params).pipe(
        map(data => new GetUtilizationMetricSuccess({ data })),
        catchError(error => of(new GetUtilizationMetricFailure({ error })))
      );
    })
  );

  constructor(private actions$: Actions<UtilizationMetricActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
