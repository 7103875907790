import { Action } from '@ngrx/store';
import { UserRoleInfo } from '@mint-libs/context';

export enum AuthActionTypes {
  PerformLogin = '[Login] Perform Login',
  LoginSuccess = '[Login] Login Success',
  LoginFailure = '[Login] Login Failure',
  AddUserRoleInfo = '[Login] Add UserRoleInfo'
}

export class PerformLoginAction implements Action {
  readonly type = AuthActionTypes.PerformLogin;
}

export class LoginSuccessAction implements Action {
  readonly type = AuthActionTypes.LoginSuccess;
  constructor(public payload: { userInfo: any }) {}
}

export class AddUserRoleInfoAction implements Action {
  readonly type = AuthActionTypes.AddUserRoleInfo;
  constructor(public payload: { userRoleInfo: UserRoleInfo }) {}
}

export class LoginFailureAction implements Action {
  readonly type = AuthActionTypes.LoginFailure;
  constructor(public payload: { error: any }) {}
}

export type AuthActions = PerformLoginAction | LoginSuccessAction | LoginFailureAction | AddUserRoleInfoAction;
