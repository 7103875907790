import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ImpersonationState } from './impersonation.state';
import { impersonationFeatureKey } from './impersonation.reducer';
import { selectUserProfileState } from '@mint-libs/context';

export const selectImpersonationState = createFeatureSelector<ImpersonationState>(impersonationFeatureKey);

export const selectImpersonationStateWithProfile = createSelector(selectImpersonationState, selectUserProfileState, (impersonation, userProfile) => {
  return { impersonationState: impersonation, userProfile: userProfile };
});
