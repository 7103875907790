<div role="dialog">
  <div id="sidebar_header">
    <div id="sidebar_title" class="col">
      <h1 id="sidebarUserName" class="title">Hello, {{ userName }}</h1>
    </div>
  </div>
  <ul id="sidebar_ul" style="height: 100%" role="menu">
    <li
      *ngFor="let sidebarItem of sidebarItemList"
      [routerLink]="[sidebarItem.url]"
      [routerLinkActive]="['activeRoute']"
      analytics-tracker
      [tracker]="'SideNav_ChangeMenuItem'"
      [value]="sidebarItem.url"
      tabindex="0"
      (keyup.enter)="onKeypressNavigate(sidebarItem.url)"
      cdkFocusInitial
      role="menuitem"
    >
      <div>
        <mat-icon aria-hidden="true">{{ sidebarItem.icon }}</mat-icon>
        <span>{{ sidebarItem.title }}</span>
      </div>
    </li>
    <div id="splitter-leftnav"></div>
    <li
      [routerLink]="[routePrefix + '/feedback']"
      [routerLinkActive]="['activeRoute']"
      [routerLinkActiveOptions]="{ exact: true }"
      analytics-tracker
      [tracker]="'SideNav_ChangeMenuItem'"
      [value]="routePrefix + '/feedback'"
      tabindex="0"
      (keyup.enter)="onKeypressNavigate(routePrefix + '/feedback')"
      role="menuitem"
    >
      <div>
        <mat-icon aria-hidden="true">insert_emoticon</mat-icon>
        <span>Feedback</span>
      </div>
    </li>
    <li analytics-tracker [tracker]="'SideNav_SupportLinkClick'" [value]="'Support clicked by' + userName" (keyup.enter)="onKeypress(supportLink)"  tabindex="0" role="menuitem">
      <a href="https://microsoft.service-now.com/wwic?id=sc_cat_item&sys_id=b34393d9131fd2042620f0912244b0e6" target="_blank" tabindex="-1">
        <div>
          <mat-icon aria-hidden="true">help_outline</mat-icon>
          <span role="link">Support</span>
        </div>
      </a>
    </li>
    <li (click)="logout()" analytics-tracker [tracker]="'SideNav_LogoutClick'" [value]="'Logout'"  tabindex="0" role="menuitem">
      <div>
        <mat-icon aria-hidden="true">power_settings_new</mat-icon>
        <span>Log out</span>
      </div>
    </li>
  </ul>
</div>
