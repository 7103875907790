<!-- <div fxFlex [id]="'metric-tile_1_' + componentId" class="stat__icon" *ngIf="tileData?.svg" [innerHTML]="tileData?.svg"></div>
<div fxFlex fxLayout="column" [id]="'metric-tile_2_' + componentId" class="stat__details">
  <div [id]="'metric-tile_3_' + componentId" [ngClass]="[tileDataValueClass]" class="stat__details-value">{{ tileData?.amount }}</div>
  <div fxFlex [id]="'metric-tile_4_' + componentId" class="stat__details-desc">
    <div [id]="'metric-tile_5_' + componentId" [ngClass]="[tileDataTextClass]" class="stat__details-desc__text">{{ tileData?.amountTitle }}</div>
  </div>
</div> -->

<!-- <div [id]="'metric-tile_1_' + componentId" class="stat__icon"></div> -->
<div role="text" class="stat" tabindex="0" [attr.aria-labelledby]="'metric-tile_3_' + componentId + ' ' + 'metric-tile_5_' + componentId">
  <mat-icon aria-hidden="false" class="stat__icon">{{ tileData?.iconName }}</mat-icon>
  <div [id]="'metric-tile_2_' + componentId" class="stat__details">
    <div [id]="'metric-tile_3_' + componentId" [ngClass]="[tileDataValueClass]" class="stat__details-value">{{ tileData?.amount }}</div>
    <div [id]="'metric-tile_4_' + componentId" class="stat__details-desc">
      <div [id]="'metric-tile_5_' + componentId" [ngClass]="[tileDataTextClass]" class="stat__details-desc__text">{{ tileData?.amountTitle }}</div>
    </div>
  </div>
</div>   
