import { Action } from '@ngrx/store';

export enum ComplianceStatusActionTypes {
  GetComplianceStatus = '[Compliance] Get Compliance Status',
  GetComplianceStatusSuccess = '[Compliance] Get Compliance Status Success',
  GetComplianceStatusFailure = '[Compliance] Get Compliance Status Failure'
}

export class GetComplianceStatus implements Action {
  readonly type = ComplianceStatusActionTypes.GetComplianceStatus;
  constructor(public payload: any) {}
}

export class GetComplianceStatusSuccess implements Action {
  readonly type = ComplianceStatusActionTypes.GetComplianceStatusSuccess;
  constructor(public payload: { data: any }) {}
}

export class GetComplianceStatusFailure implements Action {
  readonly type = ComplianceStatusActionTypes.GetComplianceStatusFailure;
  constructor(public payload: { error: any }) {}
}

export type ComplianceStatusActions = GetComplianceStatus | GetComplianceStatusSuccess | GetComplianceStatusFailure;
