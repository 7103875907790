import { QuotaDetailActions, QuotaDetailActionTypes } from './quota-detail.action';

export const QuotaDetailFeatureKey = 'QuotaDetail';

export class QuotaDetailState {}

export const quotaDetailState: QuotaDetailState = null;

export function QuotaDetailReducer(state = quotaDetailState, action: QuotaDetailActions): QuotaDetailState {
  switch (action.type) {
    case QuotaDetailActionTypes.GetQuotaDetail:
      return state;

    case QuotaDetailActionTypes.GetQuotaDetailSuccess:
      const quotaDetail = action.payload.data;
      return quotaDetail;

    case QuotaDetailActionTypes.GetQuotaDetailFailure:
      console.log('Error: ' + action.payload.error);
      return state;

    default:
      return state;
  }
}
