import { createAction, props } from '@ngrx/store';

export const navigate = createAction('[Navigation] Navigate', props<{ path: any }>());

export const navigateWithParams = createAction('[Navigation] Navigate With Params', props<{ path: any; queryParams: any }>());

export const navigationSuccess = createAction('[Navigation] Navigations Success', props<{ data: any }>());

export const navigationFailure = createAction('[Navigation] Navigations Failure', props<{ error: any }>());

export const refresh = createAction('[Navigation] Refresh Route', props<{ path: string }>());
