import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../layout/header/header.service';
import { SidebarService } from '../layout/sidebar/sidebar.service';
import { BaseContainerComponent } from '../shared/base/base-container.component';

@Component({
  selector: 'mint-mobile-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseContainerComponent implements OnInit {
  constructor(protected activeRoute: ActivatedRoute, protected router: Router, public sidebarService: SidebarService, public headerService: HeaderService) {
    super(activeRoute, router, headerService);
  }

  ngOnInit() {
    this.headerConfig = this.headerService.initializeHeaderConfig();
  }
}
