import { createAction, props } from '@ngrx/store';

export const startImpersonation = createAction('[Impersonation] Start', props<{ alias: string }>());

export const stopImpersonation = createAction('[Impersonation] Stop');

export const resetImpersonation = createAction('[Impersonation] Reset');

export const impersonationSuccess = createAction('[Impersonation] Success', props<{ alias: string; data: any }>());

export const impersonationFailure = createAction('[Impersonation] Failure', props<{ alias: string; error: any }>());
