<div *ngIf="!isLoaded"><mint-content-loader-large></mint-content-loader-large></div>
<div *ngIf="isLoaded" class="compliance-container">
  <mint-mobile-main-content-header
    *ngIf="complianceService.showMainContentHeader"
    [modalRef]="modalRef"
    [template]="filterstemplate"
    [heading]="tabTitle"
    [showFilters]="showFilters"
  ></mint-mobile-main-content-header>
  <router-outlet (activate)="changeOfRoutes()"></router-outlet>
</div>
<ng-template #filterstemplate let-modal>
  <div role="dialog" aria-label="Filters Dialog" tabindex="0">
    <mint-mobile-filter [applicableFilters]="applicableFilters" [selectedEnvelopeId]="complianceService.envelopeId"></mint-mobile-filter>
  </div>
</ng-template>
