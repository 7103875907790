import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { NavigationPaths } from '../../../core/routing/state/navigation-paths.enum';
import { ImpersonationState } from '../../../core/context/impersonation/state/impersonation.state';
import { selectImpersonationStateWithProfile } from '../../../core/context/impersonation/state/impersonation.selectors';
import { InitializationService } from '../../../core/context/initialization/initialization.service';
import { SidebarService } from '../../../layout/sidebar/sidebar.service';

@Injectable({
  providedIn: 'root'
})
export class ComplianceGuard implements CanActivate {
  constructor(private impersonationState: Store<ImpersonationState>, private initializationService: InitializationService, private sidebarService: SidebarService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const impersonationWithUserProfileState$ = this.impersonationState.select(selectImpersonationStateWithProfile);

    return impersonationWithUserProfileState$.pipe(
      map(impersonationWithUserProfileState => {
        if (!(impersonationWithUserProfileState && impersonationWithUserProfileState.userProfile)) {
          return false;
        }
        if (
          (impersonationWithUserProfileState.userProfile.isQuotaAcknowledgementPending() || impersonationWithUserProfileState.userProfile.isQuotaFCAApprovalPending()) &&
          !impersonationWithUserProfileState.impersonationState.isImpersonating
        ) {
          this.initializationService.initialize(impersonationWithUserProfileState.userProfile.defaultFilter, NavigationPaths.Compliance);
          this.sidebarService.setSidebarItemList(NavigationPaths.Compliance);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
