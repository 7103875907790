import { MetricCardConfig } from './metric-card-config';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PCGMetrics } from './pcg-metrics.model';
// import { MetricPCG } from '../models/metric-pcg.model';

@Component({
  selector: 'lib-metric-card',
  templateUrl: './metric-card.component.html',
  styleUrls: ['./metric-card.component.scss']
})
export class MetricCardComponent implements OnInit {
  @Input() componentId: any;
  @Input() cardData: PCGMetrics;
  @Input() isYTD: boolean;

  @Input() metricCardConfig: MetricCardConfig;
  @Input() showProductList = false;
  /** Below FOUR properties are just used for communication between Parent component(Plan-Summary)
   * And Inner Child component product-list
   * In case this component will be use
   */
  /** This property will be passed into the INNER ProductList Component as is */
  @Input() commonProducts: Array<string>;
  /** This property will be passed into the INNER ProductList Component as is */
  @Input() otherProducts: Array<string>;
  /** This property will be passed into the INNER ProductList Component as is */
  @Input() highlightedProductName: string;
  @Input() conditionalClass: string;
  @Input() tooltipSize: any;
  @Input() hideToolTip = false;

  /** This event used BUBBLE up the signal to the parent component and other instances of ProductListComponent
   * about the change in Highlighted Product from the INNER ProductList Component
   */
  @Output() notifyProductSelection: EventEmitter<string> = new EventEmitter<string>();
  @Output() bucketNameClick: EventEmitter<PCGMetrics> = new EventEmitter<PCGMetrics>();
  constructor() {
    this.metricCardConfig = new MetricCardConfig();
  }

  ngOnInit() {}

  /** This event will be called from Child Component ProductList and will be bubbled up to Parent Component
   */
  onProductSelectionInProductListComponent(productName: string) {
    this.notifyProductSelection.emit(productName);
  }

  onNameClick() {
    this.bucketNameClick.emit(this.cardData);
  }
}
