import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as NavigationActions from './navigation.actions';
import { Router } from '@angular/router';
import { TemplateParseResult } from '@angular/compiler';

@Injectable()
export class NavigationEffects {
  navigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NavigationActions.navigate),
        tap(action => {
          this.ngZone.run(() => this.router.navigate([action.path]));
        })
      );
    },
    { dispatch: false }
  );

  navigatewithParams$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NavigationActions.navigateWithParams),
        tap(action => {
          this.ngZone.run(() => this.router.navigate([action.path], { queryParams: action.queryParams }));
        })
      );
    },
    { dispatch: false }
  );

  refresh$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NavigationActions.refresh),
        tap(action => {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([action.path]));
        })
      );
    },
    { dispatch: false }
  );

  constructor(private actions$: Actions, private router: Router, private ngZone: NgZone) {}
}
