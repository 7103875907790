import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from './error.service';
import { LoggerService } from '../logging/logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggerService);

    let message;
    let stackTrace = null;
    let url = null;
    let status = null;
    if (error instanceof HttpErrorResponse) {
      // Server error
      message = errorService.getServerErrorMessage(error);
      stackTrace = error.error;
      url = error.url;
      status = error.status;
      logger.trackException(message, stackTrace, null, { url: url, status: status });
    } else {
      // Client Error
      message = errorService.getClientErrorMessage(error);
      stackTrace = errorService.getClientStack(error);
      logger.trackError(error, null, { stackTrace: stackTrace });
    }
    logger.trackEvent('exception', { error: message });
    console.error(error);
  }
}
