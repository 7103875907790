import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { BaseSellerDashboardComponent } from './base-seller-dashboard.component';
import { RbiSellerDashboardComponent } from './rbi/rbi-seller-dashboard.component';
import { UbiSellerDashboardModule } from './ubi/ubi-seller-dashboard.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IncentiveTypeGuard } from '../shared/shared-dashboard/guards/incentive-type.guard';
import { ComplianceGuard } from '../shared/shared-dashboard/guards/compliance.guard';
import { RollOverGuard } from '../shared/shared-dashboard/guards/rollover.guard';
import { PPADisclaimerGuard } from '../shared/shared-dashboard/guards/ppa-disclaimer.guard';
import { MintComponentsModule } from '@mint-libs/components';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedDashboardModule } from '../shared/shared-dashboard/shared-dashboard.module';
import { UbiSellerDashboardComponent } from './ubi/ubi-seller-dashboard.component';
import { DirectivesModule } from '../shared/directives/directives.module';

const providers = [IncentiveTypeGuard, ComplianceGuard, RollOverGuard, PPADisclaimerGuard];
@NgModule({
  declarations: [BaseSellerDashboardComponent, RbiSellerDashboardComponent, UbiSellerDashboardComponent],
  imports: [CommonModule, LayoutModule, SharedDashboardModule, DirectivesModule, MintComponentsModule, SlickCarouselModule, UbiSellerDashboardModule, NgbModule],
  providers: providers
})
export class DashboardModule {}
