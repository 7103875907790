import { GetRubiTargetSummaryActions, GET_RUBITARGET_SUMMARY, GET_RUBITARGET_SUMMARY_SUCCESS, GET_RUBITARGET_SUMMARY_FAILURE } from './rubi-target-summary.actions';
import { RubiTargetSummaryState } from './rubi-target-summary.model';

export const RubiTargetSummaryKey = 'RubiTargetSummary';

export const initialState: RubiTargetSummaryState = null;

export function RubiTargetSummaryReducer(state = initialState, action: GetRubiTargetSummaryActions): any {
  switch (action.type) {
    case GET_RUBITARGET_SUMMARY:
      return null;
    case GET_RUBITARGET_SUMMARY_SUCCESS:
      const targetSummary = action.payload.data;
      return targetSummary;
    case GET_RUBITARGET_SUMMARY_FAILURE:
      return state;
    default:
      return state;
  }
}
