import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WorkingHoursActions, WorkingHoursActionTypes, GetWorkingHoursSuccess, GetWorkingHoursFailure } from './working-hours.actions';
import { EndpointService } from '@mint-libs/network';
import { HttpParams } from '@angular/common/http';
import { DataService } from '@mint-libs/context';

@Injectable()
export class WorkingHoursEffects {
  @Effect()
  loadWorkingHours$ = this.actions$.pipe(
    ofType(WorkingHoursActionTypes.GetWorkingHours),
    concatMap(action => {
      return this.dataService.getWithGlobalFilters(this.endpoints.GetUBIWorkingHours.uri, null).pipe(
        map(data => new GetWorkingHoursSuccess({ data })),
        catchError(error => of(new GetWorkingHoursFailure({ error })))
      );
    })
  );

  constructor(private actions$: Actions<WorkingHoursActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
