import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

// TODO: Can be moved to a related lib to have this common for both desktop and mobile. Currently libs are not consumed by desktop site
// so first we need to do that before taking this up
@Injectable()
export class CachingService {
  maxAge = 15 * 60 * 1000; // max age of cached response in milliseconds
  cacheMap = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const urlWithParams = req.urlWithParams;
    const cached = this.cacheMap.get(urlWithParams);

    if (!cached) {
      return null;
    }

    const isExpired = cached.lastRead < Date.now() - this.maxAge;
    return isExpired ? null : cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const urlWithParams = req.urlWithParams;
    const entry = { urlWithParams, response, lastRead: Date.now() };
    this.cacheMap.set(urlWithParams, entry);

    this.deleteExpired();
  }

  private deleteExpired() {
    const expired = Date.now() - this.maxAge;
    this.cacheMap.forEach(expiredEntry => {
      if (expiredEntry.lastRead < expired) {
        this.cacheMap.delete(expiredEntry.url);
      }
    });
  }

  public clearCache(url) {
    this.cacheMap.forEach(key => {
      if (key.urlWithParams.includes(url)) {
        this.cacheMap.delete(key.urlWithParams);
      }
    });
  }

  clear() {
    this.cacheMap.clear();
  }
}
