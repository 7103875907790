<div *ngIf="!isLoaded">
  <div class="splash">
    <div class="loader">
      <div>
        <img src="../../assets/logos/Square200x200Logo_bg.png" alt="Mint Logo" class="loader__logo" />
        <div class="loader__text">
          <span>Accepting...</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoaded">
  <mint-mobile-header [title]="title" [headerConfig]="disclaimerHeaderConfig"></mint-mobile-header>
  <div class="disclaimer-content" [innerHTML]="disclaimerText"></div>
  <button type="button" id="disclaimer-btn" class="action-btn" title="I Accept"
    (click)="onAcceptance(sharedContext.settings.disclaimer)"
    (keyup.enter)="onAcceptance(sharedContext.settings.disclaimer)" analytics-tracker [tracker]="'Disclaimer_Button'"
    [value]="'Disclaimer_I_Accept'">
    I Accept
  </button>
</div>