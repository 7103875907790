import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { MintMobileAppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(MintMobileAppModule)
  .then(platformRef => {
    // const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
    // upgrade.bootstrap(document.body, ['participantExpApp']);
    if ('serviceWorker' in navigator && environment.enablePWA) {
      navigator.serviceWorker.register('/ngsw-worker.js');
    }
  });
