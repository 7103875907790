import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LayoutModule } from '../../layout/layout.module';
import { MintComponentsModule } from '@mint-libs/components';
import { MatIconModule } from '@angular/material/icon';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { AvatarModule } from 'ngx-avatar';
import { QuotaComponent } from './quota.component';
import { QuotaDetailFeatureKey, QuotaDetailReducer } from './quota-dashboard/state/quota-detail.reducer';
import { QuotaDetailEffects } from './quota-dashboard/state/quota-detail.effects';
import { MatDividerModule } from '@angular/material/divider';
import { AcknowledgementSectionComponent } from './acknowledgement-section/acknowledgement-section.component';
import { QuotaService } from './quota.service';
import { QuotaDetailComponent } from './quota-detail/quota-detail.component';
import { QuotaAcknowledgementFeatureKey, QuotaAcknowledgementReducer } from './acknowledgement-section/state/quota-acknowledgement.reducer';
import { QuotaAcknowledgementEffects } from './acknowledgement-section/state/quota-acknowledgement.effects';
import { ComplianceRoutingModule } from '../compliance-routing.module';
import { BaseQuotaDashboardComponent } from './quota-dashboard/base-quota-dashboard.component';
import { QuotaSummaryComponent } from './quota-dashboard/quota-summary/quota-summary.component';
import { RbiQuotaDashboardComponent } from './quota-dashboard/rbi/rbi-quota-dashboard.component';
import { UbiTargetDashboardComponent } from './quota-dashboard/ubi/ubi-target-dashboard.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilizationMetricTooltipComponent } from './quota-dashboard/ubi/utilization-metric-tooltip/utilization-metric-tooltip.component';

@NgModule({
  declarations: [
    QuotaComponent,
    BaseQuotaDashboardComponent,
    QuotaSummaryComponent,
    RbiQuotaDashboardComponent,
    UbiTargetDashboardComponent,
    UtilizationMetricTooltipComponent,
    QuotaDetailComponent,
    AcknowledgementSectionComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    MatIconModule,
    AvatarModule,
    DirectivesModule,
    MatDividerModule,
    ComplianceRoutingModule,
    NgbNavModule,
    MintComponentsModule,
    StoreModule.forFeature(QuotaDetailFeatureKey, QuotaDetailReducer),
    StoreModule.forFeature(QuotaAcknowledgementFeatureKey, QuotaAcknowledgementReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([QuotaDetailEffects, QuotaAcknowledgementEffects])
  ],
  providers: [QuotaService]
})
export class QuotaModule {}
