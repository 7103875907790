import { Notifications } from '../notifications/Models/notifications.model';
import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { configurationReducer, ConfigurationState } from '@mint-libs/configuration';
import { PayoutSummaryState } from '../shared/shared-dashboard/payout-summary/state/payout-summary.model';
import { PayoutSummaryReducer } from '../shared/shared-dashboard/payout-summary/state/payout-summary.reducer';
import { PaylineSummaryState } from '../shared/shared-dashboard/payline-summary/state/payline-summary.model';
import { PaylineSummaryReducer } from '../shared/shared-dashboard/payline-summary/state/payline-summary.reducer';
import { TargetSummaryReducer } from '../shared/shared-dashboard/target-summary/state/target-summary.reducer';
import { TargetSummaryState } from '../shared/shared-dashboard/target-summary/state/target-summary.model';
import { RefreshSummaryState } from '../shared/shared-dashboard/refresh-summary/state/refresh-summary.model';
import { RefreshSummaryReducer } from '../shared/shared-dashboard/refresh-summary/state/refresh-summary.reducer';
import { RouterStateUrl } from '../core/routing/state/router-state-url';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { AuthState, authReducer } from '../core/authentication/state/auth.reducer';
import { UserProfileState, userProfileReducer, filtersReducer, Filters, ParticipantDetails, allParticipantsReducer } from '@mint-libs/context';
import { ImpersonationState } from '../core/context/impersonation/state/impersonation.state';
// import { Filters } from '../core/filters/filters.model';
import { impersonationReducer } from '../core/context/impersonation/state/impersonation.reducer';
// import { filtersReducer } from '../core/filters/state/filters.reducer';
import { NotificationsReducer } from '../notifications/state/notifications.reducer';
import { RubiTargetSummaryState } from '../dashboard/ubi/rubi-target-summary/state/rubi-target-summary.model';
import { RubiTargetSummaryReducer } from '../dashboard/ubi/rubi-target-summary/state/rubi-target-summary.reducer';

export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
  authInfo: AuthState;
  userProfile: UserProfileState;
  configuration: ConfigurationState;
  impersonation: ImpersonationState;
  filters: Filters;
  payoutSummary: PayoutSummaryState;
  paylineSummary: PaylineSummaryState;
  targetSummary: TargetSummaryState;
  refreshSummary: RefreshSummaryState;
  notifications: Notifications;
  participantDetails: ParticipantDetails;
  rubiTargetSummary: RubiTargetSummaryState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  authInfo: authReducer,
  userProfile: userProfileReducer,
  configuration: configurationReducer,
  impersonation: impersonationReducer,
  filters: filtersReducer,
  payoutSummary: PayoutSummaryReducer,
  paylineSummary: PaylineSummaryReducer,
  targetSummary: TargetSummaryReducer,
  refreshSummary: RefreshSummaryReducer,
  notifications: NotificationsReducer,
  participantDetails: allParticipantsReducer,
  rubiTargetSummary: RubiTargetSummaryReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
