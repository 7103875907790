<div id="dashboard_23" fxLayout="column" fxFlexFill class="stats stats-dashboard" *ngIf="payoutTilesLoaded && attainmentTileLoaded">
  <lib-metric-tile id="dashboard_24" [componentId]="'dashboard_24'" [tileData]="ytdPaymentKPI" class="stat" *ngIf="!isPayoutUnavailable"> </lib-metric-tile>
  <lib-metric-tile id="dashboard_25" [componentId]="'dashboard_25'" [tileData]="projectedPaymentKPI" class="stat" *ngIf="!isPayoutUnavailable"> </lib-metric-tile>
  <lib-metric-tile id="dashboard_26" [componentId]="'dashboard_26'" [tileData]="weightedAttainmentKPI" class="stat"> </lib-metric-tile>
</div>
<div class="mint_tile_loader" *ngIf="!payoutTilesLoaded || !attainmentTileLoaded"><mint-content-loader-icon-text></mint-content-loader-icon-text></div>

<div class="tile">
  <div ngbNav #nav="ngbNav" class="nav-tabs tile-tabset tabs">
    <div ngbNavItem>
      <a ngbNavLink>Performance by Bucket</a>
      <ng-template ngbNavContent>
        <div class="tab">
          <div *ngIf="!paylinesLoaded"><mint-content-loader-medium></mint-content-loader-medium></div>
          <div *ngIf="paylinesLoaded">
            <ngx-slick-carousel
              class="carousel"
              #slickModal="slick-carousel"
              [config]="slideConfig"
              (init)="slickInit($event)"
              (breakpoint)="breakpoint($event)"
              (afterChange)="afterChange($event)"
              (beforeChange)="beforeChange($event)"
              (mousedown)="dragStart()"
              (mouseup)="dragEnd()"
              analytics-tracker
              [tracker]="'Dashboard_AttainmentSlide'"
              [value]="'AttainmentCarousel_Slide'"
            >
              <div
                ngxSlickItem
                *ngFor="let pcg of ytdPCGs; let i = index"
                class="slide"
                (click)="onTileClick(pcg)"
                (keyup.enter)="onTileClick(pcg)"
                analytics-tracker
                [tracker]="'Dashboard_AttainmentTileDetails'"
                [value]="'Attainment_Tile_Click' + pcg"
                role="button"
              >
                <lib-metric-card
                  [componentId]="'metric-pcg-block_3_' + i"
                  [id]="'metric-pcg-block_3' + i"
                  [cardData]="pcg"
                  [conditionalClass]="pcg.attainmentStatus"
                  [metricCardConfig]="metricCardConfig"
                  [hideToolTip]="true"
                >
                </lib-metric-card>
                <p class="foot-note" role="note">Click on tile to learn more</p>
                <mint-mobile-legends [attainmentStatus]="pcg.attainmentStatus" [componentId]="i"></mint-mobile-legends>
              </div>
            </ngx-slick-carousel>
            <div class="carousel_container"><div class="carousel_container carousel_container_rect" [ngClass]="{ rect_active: pcg.isActive }" *ngFor="let pcg of ytdPCGs; let i = index"></div></div>
          </div>
        </div>
      </ng-template>
    </div>
    <div ngbNavItem>
      <a ngbNavLink>Payout Details</a>
      <ng-template ngbNavContent>
        <div class="tab">
          <mint-mobile-payout-summary></mint-mobile-payout-summary>
        </div>
      </ng-template>
    </div>
  </div>

  <div [ngbNavOutlet]="nav"></div>
</div>
