import { ComplianceStatusActionTypes, ComplianceStatusActions } from './compliance-status.actions';
import { ComplianceStatus } from '../../models/compliance-status.model';

export const ComplianceStatusFeatureKey = 'complianceStatus';

export class ComplianceStatusState extends ComplianceStatus {}

export const complianceStatusState: ComplianceStatusState = null;

export function ComplianceStatusReducer(state = complianceStatusState, action: ComplianceStatusActions): ComplianceStatusState {
  switch (action.type) {
    case ComplianceStatusActionTypes.GetComplianceStatus:
      return state;

    case ComplianceStatusActionTypes.GetComplianceStatusSuccess:
      const complianceStatus = new ComplianceStatus().deserialize(action.payload.data);
      return complianceStatus;

    case ComplianceStatusActionTypes.GetComplianceStatusFailure:
      console.log('Error: ' + action.payload.error);
      return state;

    default:
      return state;
  }
}
