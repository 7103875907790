import { Expose } from 'class-transformer';
import { IDeserializable, BaseModel } from '@mint-libs/common';
import { plainToClass } from 'class-transformer';
import { DocumentType } from './document-type.enum';

export class ComplianceStatus extends BaseModel implements IDeserializable<ComplianceStatus> {
  @Expose() statusId: number;
  @Expose() signature: string;
  @Expose() reminderCount: number;
  @Expose() firstReminderDate: Date | string;
  @Expose() lastReminderDate: Date | string;
  @Expose() snapshot: string;
  @Expose() participationId: number | null;
  @Expose() participationStartDate: Date | null;
  @Expose() participationEndDate: Date | null;
  @Expose() signedDate: Date | null;
  @Expose() publishDate: Date | string;
  @Expose() countryName: string;
  @Expose() incentiveType: string;
  @Expose() documentType: number;
  @Expose() documentStatusId: number | null;
  @Expose() documentStatusDesc: string;
  @Expose() docType: string;
  @Expose() ppaStatusId: number;
  @Expose() envelopeId: string;
  @Expose() isActive: boolean;

  deserialize(from: object): ComplianceStatus {
    const complianceStatusDetail = plainToClass<ComplianceStatus, object>(ComplianceStatus, from, this.classTransformOptions);
    return complianceStatusDetail;
  }

  private isDocumentType(documentType: number): boolean {
    return this.documentType === documentType;
  }

  public isPPADocument() {
    return this.isDocumentType(DocumentType.PlanParticipantAgreement);
  }

  public isQuotaDocument() {
    return this.isDocumentType(DocumentType.QuotaAcknowledgement);
  }
}
