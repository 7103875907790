import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomRouteSerializer } from './routing/custom-route-serializer';
import { throwIfAlreadyLoaded } from '../core/module-import-guard';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NavigationEffects } from './routing/state/navigation.effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContextInterceptor } from './context/context.interceptor';
import { filtersFeatureKey, filtersReducer } from '../core/context/filters/state/filters.reducer';
import { impersonationFeatureKey, impersonationReducer } from './context/impersonation/state/impersonation.reducer';
import { ImpersonationEffects } from './context/impersonation/state/impersonation.effects';
import { FilterEffects } from '../core/context/filters/state/filters.effects';
import { GlobalErrorHandler } from './exceptionHandling/global-error.handler';
import { CachingInterceptor } from './caching/caching.interceptor';
import { CachingService } from './caching/caching.service';
import { userProfileFeatureKey, userProfileReducer, UserProfileEffects, UserProfileResolver } from '@mint-libs/context';
import { TelemetryService } from '@mint-libs/logging';
import { NotificationsKey, NotificationsReducer } from '../notifications/state/notifications.reducer';
import { NotificationsEffects } from '../notifications/state/notifications.effects';
import { allParticipantsFeatureKey, allParticipantsReducer, AllParticipantsEffects } from '@mint-libs/context';
import { AuthEffects } from './authentication/state/auth.effects';
export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ContextInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true }
  // { provide: HTTP_INTERCEPTORS, useClass: DownTimeInterceptor, multi: true }
];

export const globalErrorHandlerProvider = { provide: ErrorHandler, useClass: GlobalErrorHandler };
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(userProfileFeatureKey, userProfileReducer),
    StoreModule.forFeature(filtersFeatureKey, filtersReducer),
    StoreModule.forFeature(impersonationFeatureKey, impersonationReducer),
    StoreModule.forFeature(NotificationsKey, NotificationsReducer),
    StoreModule.forFeature(allParticipantsFeatureKey, allParticipantsReducer),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([AuthEffects, NavigationEffects, UserProfileEffects, ImpersonationEffects, FilterEffects, AllParticipantsEffects, NotificationsEffects])
  ],
  providers: [HttpInterceptorProviders, CustomRouteSerializer, UserProfileResolver, TelemetryService, globalErrorHandlerProvider, CachingService]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
