import { Action, createReducer, on } from '@ngrx/store';
import * as QuotaAcknowledgementActions from './quota-acknowledgement.action';

export const QuotaAcknowledgementFeatureKey = 'QuotaAcknowledgement';

interface QAState {
  isQuotaAcknowledgeSuccess: boolean;
}

export interface QuotaAcknowledgementState extends QAState {}

export const quotaAcknowledgementState: QuotaAcknowledgementState = null;

const reducer = createReducer(
  quotaAcknowledgementState,

  on(QuotaAcknowledgementActions.SetQuotaAsViewed, state => state),
  on(QuotaAcknowledgementActions.AcknowledgeQuota, (state, action) => state),
  on(QuotaAcknowledgementActions.AcknowledgeQuotaSuccess, (state, action) => {
    return {
      data: action.data,
      isQuotaAcknowledgeSuccess: true
    };
  }),
  on(QuotaAcknowledgementActions.AcknowledgeQuotaFailure, (state, action) => {
    return {
      error: action.error,
      isQuotaAcknowledgeSuccess: false
    };
  })
);

export function QuotaAcknowledgementReducer(state: QuotaAcknowledgementState | undefined, action: Action) {
  return reducer(state, action);
}
