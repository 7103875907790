import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectAuthInfo, selectAuthInfoFullName } from '../../core/authentication/state/auth.selectors';

@Component({
  selector: 'mint-mobile-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  thumbnailPhoto = '';
  name = '';

  constructor(private router: Router, private appState: Store<AppState>) {}

  ngOnInit() {
    const authInfoSelector = this.appState.select(selectAuthInfo);
    const fullNameSelector = this.appState.select(selectAuthInfoFullName);
    authInfoSelector.subscribe(state => {
      this.thumbnailPhoto = '';
      if (!state || !state.userInfo.profile.thumbnailPhoto) {
        return;
      }
      this.thumbnailPhoto = `data:image/jpeg;base64,${state.userInfo.profile.thumbnailPhoto}`;
    });
    fullNameSelector.subscribe(fullName => (this.name = fullName));
  }
}
