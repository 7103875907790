<button
  *ngIf="routerHistoryService.showBackButton && isBackIconVisible"
  class="mat-icon-container"
  aria-label="Back icon"
  tabindex="0"
  (click)="onClick($event)"
  (keyup.enter)="onClick($event)"
  analytics-tracker
  [tracker]="'Back_ButtonClick'"
  [value]="routerHistoryService.previousUrl.getValue()"
>
  <mat-icon name="Go Back" role="button" aria-label="Back_ButtonClick" class="back-button" aria-hidden="false">keyboard_arrow_left</mat-icon>
</button>
