<div ng-show="!quotaService.notAvailable">
  <div *ngFor="let period of quotaPeriods?.titles; let i = index">
    <div class="quota-summary-period-container">
      <span class="quota-summary-period-title">{{ period.Name }} {{ period.Text }}</span
      ><mat-icon
        name="Expand Quota details"
        role="button"
        aria-label="Click here"
        aria-hidden="false"
        (click)="navigateToQuotaDetails(period)"
        (keyup.enter)="navigateToQuotaDetails(period)"
        analytics-tracker
        [tracker]="'QuotaDetails_' + period.Name"
        [value]="period.Name + period.Text"
        >keyboard_arrow_right</mat-icon
      >
    </div>
    <mat-divider [ngStyle]="{ visibility: i !== quotaPeriods?.titles.length - 1 ? 'visible' : 'hidden' }"></mat-divider>
  </div>
  <div id="quota-summary-footer">
    <span class="text-danger">*</span>Note: If you have a performance measure displaying no quota, please contact your manager or view
    <a [href]="supportHref" target="_blank" title="WWIC Support">WWIC Help</a> before acknowledging this Quota.
  </div>
</div>
<div *ngIf="quotaService.notAvailable">{{ quotaService.notAvailableMessage }}</div>
