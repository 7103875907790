import { NotificationType } from './notification-type.enum';
import { plainToClass, Expose, Type } from 'class-transformer';
export class UserNotifications {
    @Expose() notificationId: number;
    @Expose() notificationCreatedDate: Date | string;
    @Expose() notificationModifiedDate: Date | string;
    @Expose() notificationStartDate: Date | string;
    @Expose() notificationEndDate: Date | string;
    @Expose() isActiveNotification: boolean;
    @Expose() notificationText: string;
    @Expose() notificationType: NotificationType;
    @Expose() isNotificationRead: boolean;
    @Expose() roleIds: string;
    @Expose() roles: string;
    @Expose() currentStatus: string;
    @Expose() action: string;
    @Expose() createdBy: string;
    @Expose() audienceFilter: string;
    @Expose() standardTitleKeys: string;
    @Expose() planIds: string;
    @Expose() standardTitles: string;
    @Expose() plans: string;
    @Expose() countryIds: string;
    @Expose() countryNames: string;
    @Expose() businessSegments: string;
    @Expose() qualifier1Ids: string;
    @Expose() qualifier1Names: string;
    @Expose() qualifier2Ids: string;
    @Expose() qualifier2Names: string;
    @Expose() roleNames: string;
    @Expose() standardTitleIds: string;
    @Expose() standardTitleNames: string;
    @Expose() planNames: string;
    @Expose() listOfAlias: string;
}