import { BaseModel, IDeserializable } from '@mint-libs/common';
import { UserNotifications } from './user-notifications.model';
import { plainToClass, Expose, Type } from 'class-transformer';
export class Notifications extends BaseModel implements IDeserializable<Notifications> {
  @Expose() userAlias: string;
  @Type(() => UserNotifications)
  @Expose()
  notifications: UserNotifications[];

  deserialize(from: object): Notifications {
    const notifications = plainToClass(Notifications, from, this.classTransformOptions);
    return notifications;
  }
}
