import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap } from 'rxjs/operators';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '@mint-libs/context';
import { GetQuotaDetailFailure, GetQuotaDetailSuccess, QuotaDetailActions, QuotaDetailActionTypes } from './quota-detail.action';
import { HttpParams } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { CachingService } from '../../../../core/caching/caching.service';
import * as NavigationActions from '../../../../core/routing/state/navigation.actions';

@Injectable()
export class QuotaDetailEffects {
  @Effect()
  loadQuotaDetail$ = this.actions$.pipe(
    ofType(QuotaDetailActionTypes.GetQuotaDetail),
    concatMap(action => {
      let navigateAction: Action = null;
      let actions: Action[] = null;
      let params = null;
      params = new HttpParams();
      params = params.append('IsReportView', false);
      return this.dataService.getWithGlobalFilters(this.endpoints.GetQuotaDetails.uri, params).pipe(
        map(data => data),
        switchMap((data: any) => {
          actions = [new GetQuotaDetailSuccess({ data })];
          navigateAction = NavigationActions.navigate({ path: action.route });
          actions = [...actions, navigateAction];
          return actions;
        }),
        catchError(error => {
          actions = [new GetQuotaDetailFailure({ error })];
          navigateAction = NavigationActions.navigate({ path: action.route });
          actions = [...actions, navigateAction];
          return actions;
        })
      );
    })
  );
  constructor(private actions$: Actions<QuotaDetailActions>, private dataService: DataService, private endpoints: EndpointService, private cachingService: CachingService) {}
}
