import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { mergeMap } from 'rxjs/operators';
import { navigateWithParams } from '../../../core/routing/state/navigation.actions';
import { selectUserRoleInfo } from '../../../core/authentication/state/auth.selectors';
import { selectUserProfileState } from '@mint-libs/context';
import { ErrorTypes } from '../../../core/context/error/error-type.enum';
import { NavigationPaths } from '../../../core/routing/state/navigation-paths.enum';
import { SidebarService } from '../../../layout/sidebar/sidebar.service';
import { InitializationService } from '../../../core/context/initialization/initialization.service';

@Injectable({
  providedIn: 'root'
})
export class IncentiveTypeGuard implements CanActivate {
  constructor(private appState: Store<AppState>, private sidebarService: SidebarService, private initializationService: InitializationService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const userRoleInfo$ = this.appState.select(selectUserRoleInfo);
    const userProfileState$ = this.appState.select(selectUserProfileState);

    return userRoleInfo$.pipe(
      mergeMap(
        userRoleInfo => userProfileState$,
        (userRoleInfo, userProfileState) => {
          if (!userProfileState) {
            return false;
          }
          this.sidebarService.setSidebarItemList(NavigationPaths.Dashboard);
          if (userProfileState.isPPIPersona()) {
            this.appState.dispatch(navigateWithParams({ path: NavigationPaths.Error, queryParams: { errorType: ErrorTypes.RoleNotSupported } }));
            return false;
          } else {
            return true;
          }
        }
      )
    );
  }
}
