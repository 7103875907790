import { UtilizationMetricActions, UtilizationMetricActionTypes } from './utilization-metric.actions';

export const UtilizationMetricFeatureKey = 'UtilizationMetric';

export class UtilizationMetricState {}

export const initialState: UtilizationMetricState = null;

export function UtilizationMetricReducer(state = initialState, action: UtilizationMetricActions): UtilizationMetricState {
  switch (action.type) {
    case UtilizationMetricActionTypes.GetUtilizationMetric:
      return state;

    case UtilizationMetricActionTypes.GetUtilizationMetricSuccess:
      const userProfile = action.payload.data;
      return userProfile;

    case UtilizationMetricActionTypes.GetUtilizationMetricFailure:
      console.log('Error: ' + action.payload.error);
      return state;

    default:
      return state;
  }
}
