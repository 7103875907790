import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectImpersonationState } from './impersonation/state/impersonation.selectors';
import { environment } from '../../../environments/environment';
import { CorrelatorService } from '@mint-libs/context';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ContextInterceptor implements HttpInterceptor {
  skipImpersonationValues: boolean;
  isImpersonating = false;
  impersonatingAlias = '';

  constructor(private appState: Store<AppState>, private correlator: CorrelatorService) {
    this.appState.select(selectImpersonationState).subscribe(state => {
      this.isImpersonating = state.isImpersonating;
      this.impersonatingAlias = state.impersonatedAlias;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const injectedHeaders = this.injectHeaders(req.headers);
    const injectedUrl = this.injectUrl(req.url, req.params);

    const clonedReq = req.clone({
      headers: injectedHeaders,
      url: injectedUrl
    });

    return next.handle(clonedReq);
  }

  injectHeaders(headers: HttpHeaders): HttpHeaders {
    headers = headers.set('X-CorrelationId', this.correlator.getCorrelationId());
    headers = headers.set('X-SessionId', this.correlator.getSessionId());
    headers = headers.set('X-Content-Type-Options', 'nosniff');
    // headers = headers.set('DemoMode', this.sharedContext.isDemoMode3 ? 'True' : 'False');
    return headers;
  }

  injectUrl(url: string, params: any): any {
    url = this.injectImpersonationValues(url);
    url = this.injectSpoofing(url, params);
    return url;
  }

  injectImpersonationValues(url: string): string {
    let skipImpersonationValues = false;

    const location = this.getLocation(url);
    const locationUrl = url ? location.protocol + '//' + location.host : url;
    if (locationUrl.indexOf('api.powerbi') > -1 || locationUrl.indexOf('graph.microsoft.com') > -1) {
      skipImpersonationValues = true;
    }

    this.skipImpersonationValues = skipImpersonationValues;

    if (skipImpersonationValues || !this.isImpersonating) {
      return url;
    }
    const queryStringIndex = url.indexOf('?');
    const impersonatingUserString = '/' + this.impersonatingAlias;
    if (queryStringIndex === -1) {
      url = url.concat(impersonatingUserString);
    } else {
      url = url
        .substr(0, queryStringIndex)
        .concat(impersonatingUserString)
        .concat(url.substr(queryStringIndex));
    }

    return url;
  }

  injectSpoofing(url: string, params: any): any {
    if (this.skipImpersonationValues || (params && params.RequestedUser)) {
      return;
    }
    const spoofUser = environment.spoofUser;

    if (spoofUser && url && url.toLowerCase().indexOf('spoofuser') === -1) {
      if (url.indexOf('?') > -1) {
        url = url.concat('&');
      } else {
        url = url.concat('?');
      }
      url = url.concat('spoofUser=' + spoofUser);
    }

    return url;
  }

  getLocation(url) {
    const match = url.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)(\/[^?#]*)(\?[^#]*|)(#.*|)$/);
    return (
      match && {
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
      }
    );
  }
}
