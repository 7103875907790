import { Injectable } from '@angular/core';
import { ToasterService, Toast } from 'angular2-toaster';

@Injectable({
  providedIn: 'root'
})
export class AcknowledgementToasterService {
  constructor(private toasterService: ToasterService) {}
  popToast(title, toastType) {
    var toast: Toast = {
      type: toastType,
      body: title,
      showCloseButton: false
    };

    this.toasterService.pop(toast);
  }
}
