import { Expose, plainToClass, deserializeArray, Transform } from 'class-transformer';
import { BaseModel } from '../../base/base.model';
import { IDeserializable } from '../../base/iDeserializable';

export class Payline extends BaseModel implements IDeserializable<Payline[]> {
  @Expose() pcgId: number;
  @Expose() pcgName: string;
  @Transform(convert, { toClassOnly: true })
  @Expose()
  weightage: number;
  @Expose() attainment: number;
  @Expose() attainmentStatus?: string;
  @Expose() simulatedAttainment?: number;
  @Expose() payoutPercentage?: number;
  @Expose() pcgCurrency: string;
  // Earnings
  @Expose() pcgEarnings?: number;
  @Expose() pcgActiveEarnings?: number;
  @Expose() pcgTAEarnings?: number;
  @Expose() pcgLOAEarnings?: number;
  // Payline stages
  @Expose() threshold: number;
  @Expose() thresholdPayout: number;
  @Expose() target: number;
  @Expose() targetPayout: number;
  @Expose() excellence: number;
  @Expose() excellencePayout: number;
  @Expose() payoutAtThreshold?: number;
  @Expose() attainmentAtThreshold?: number;
  @Expose() multiplierTillThreshold?: number;
  @Expose() payoutThresholdToTarget?: number;
  @Expose() attainmentThresholdToTarget?: number;
  @Expose() multiplierThresholdToTarget?: number;
  @Expose() payoutTargetToExcellence?: number;
  @Expose() attainmentTargetToExcellence?: number;
  @Expose() multiplierTargetToExcellence?: number;
  @Expose() payoutBeyondExcellence?: number;
  @Expose() attainmentBeyondExcellence?: number;
  @Expose() multiplierBeyondExcellence?: number;
  // Caps
  @Expose() softCap?: number;
  @Expose() hardCap?: number;
  @Expose() usedCap?: number;
  @Expose() hurdleCap?: string;
  @Expose() isHurdleCapApplicable: boolean;
  @Expose() setupLevel: string;

  deserialize(from: any): Payline[] {
    const paylines = deserializeArray<Payline>(Payline, JSON.stringify(from), this.classTransformOptions);
    return paylines;
  }

  deserializeSingle(from: object): Payline {
    const paylines = plainToClass<Payline, object>(Payline, from, this.classTransformOptions);
    return paylines;
  }
}

export function convert(value) {
  return value.value * 100;
}
