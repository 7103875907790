import { NgModule } from '@angular/core';
import { AnalyticsTrackerDirective } from './analytics-tracker.directive';
import { ApplicableForRBIDirective } from './applicable-for-rbi.directive';
import { ApplicableForUBIDirective } from './applicable-for-ubi.directive';

@NgModule({
  declarations: [AnalyticsTrackerDirective, ApplicableForRBIDirective, ApplicableForUBIDirective],
  exports: [AnalyticsTrackerDirective, ApplicableForRBIDirective, ApplicableForUBIDirective]
})
export class DirectivesModule {}
