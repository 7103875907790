import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap } from 'rxjs/operators';
import { EndpointService } from '@mint-libs/network';
import { DataService } from '@mint-libs/context';
import { GetPPAInformationFailure, GetPPAInformationSuccess, PPAInformationActions, PPAInformationActionTypes } from './ppa-information.action';
import { HttpParams } from '@angular/common/http';
import { Action } from '@ngrx/store';
import * as NavigationActions from '../../../core/routing/state/navigation.actions';

@Injectable()
export class PPAInformationEffects {
  @Effect()
  loadPPAInformation$ = this.actions$.pipe(
    ofType(PPAInformationActionTypes.GetPPAInformation),
    concatMap(action => {
      let navigateAction: Action = null;
      let actions: Action[] = null;
      let params = null;
      if (action) {
        params = new HttpParams();
        params = params.append('envelopeId', action.envelopeId);
        params = params.append('documentSignedStatusID', action.documentStatusId);
      }
      return this.dataService.getWithGlobalFilters(this.endpoints.GetPPAReceipientViewUrl.uri, params).pipe(
        map(data => data),
        switchMap((data: any) => {
          actions = [new GetPPAInformationSuccess({ data })];
          navigateAction = NavigationActions.navigate({ path: action.route });
          actions = [...actions, navigateAction];
          return actions;
        }),
        catchError(error => {
          actions = [new GetPPAInformationFailure({ error })];
          navigateAction = NavigationActions.navigate({ path: action.route });
          actions = [...actions, navigateAction];
          return actions;
        })
      );
    })
  );
  constructor(private actions$: Actions<PPAInformationActions>, private dataService: DataService, private endpoints: EndpointService) {}
}
