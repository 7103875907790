import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuotaService } from '../../quota.service';
import { CustomNumberPipe } from '@mint-libs/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UtilizationMetricTooltipComponent } from './utilization-metric-tooltip/utilization-metric-tooltip.component';

@Component({
  selector: 'mint-mobile-ubi-target-dashboard',
  templateUrl: './ubi-target-dashboard.component.html',
  styleUrls: ['./ubi-target-dashboard.component.scss']
})
export class UbiTargetDashboardComponent implements OnInit {
  utilizationMetricTotal: any;
  activeId: any;
  @Input() quotaPeriods: any;
  @Input() targetHours: any;
  @Output() h2MessageOnTabChange = new EventEmitter<any>();

  constructor(public quotaService: QuotaService, private bottomSheet: MatBottomSheet, private customNumberPipe: CustomNumberPipe) {}

  ngOnInit() {
    this.utilizationMetricTotal = this.quotaService.pccs[0].Metrics;
  }

  onTabChange(tabId) {
    this.quotaService.isH2MessageHidden = false;
    if (tabId == 0) {
      this.quotaService.isH2MessageHidden = true;
    }
    this.quotaService.ubiSelectedTab = tabId;
    this.h2MessageOnTabChange.emit(this.quotaService.isH2MessageHidden);
  }

  getTimePeriod(data) {
    const timePeriod = data.split('-').pop();
    const result = this.quotaPeriods.titles.find(period => {
      return period.Name === timePeriod;
    });

    let text = result.Name + ' ' + result.Text + ' Target';
    if (result.Name == 'H2') {
      text = ' Total FY (Jul - Jun) Target';
    }
    return text;
  }

  convertToNegative(reductionValue) {
    if (reductionValue && !isNaN(parseInt(reductionValue)) && parseInt(reductionValue) !== 0) {
      return reductionValue * -1;
    } else {
      return reductionValue;
    }
  }

  customNumber(number) {
    return this.customNumberPipe.transform(number);
  }

  openBottomSheet(): void {
    this.bottomSheet.open(UtilizationMetricTooltipComponent, {
      data: { targetHours: this.targetHours }
    });
  }
}
