import { createAction, props } from '@ngrx/store';

export const SetQuotaAsViewed = createAction('[Compliance] Set Quota As Viewed');

export const SetQuotaAsViewedSuccess = createAction('[Compliance] Set Quota As Viewed Success', props<{ data: any }>());

export const SetQuotaAsViewedFailure = createAction('[Compliance] Set Quota As Viewed Failure', props<{ error: any }>());

export const AcknowledgeQuota = createAction('[Compliance] Acknowledge Quota', props<{ complianceInfo: any }>());

export const AcknowledgeQuotaSuccess = createAction('[Compliance] Acknowledge Quota Success', props<{ data: any }>());

export const AcknowledgeQuotaFailure = createAction('[Compliance] Acknowledge Quota Failure', props<{ error: any }>());
