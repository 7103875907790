import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComplianceDashboardComponent } from './compliance-dashboard/compliance-dashboard.component';
import { ComplianceComponent } from './compliance.component';
import { PpaComponent } from './ppa/ppa.component';
import { BaseQuotaDashboardComponent } from './quota/quota-dashboard/base-quota-dashboard.component';
import { QuotaDetailComponent } from './quota/quota-detail/quota-detail.component';
import { QuotaComponent } from './quota/quota.component';

const routes: Routes = [
  {
    path: '',
    component: ComplianceComponent,
    children: [
      {
        path: '',
        component: ComplianceDashboardComponent,
        data: {
          tabId: 1
        }
      },
      {
        path: 'ppa',
        component: PpaComponent,
        data: {
          tabId: 2
        }
      },
      {
        path: 'quota',
        component: QuotaComponent,
        data: {
          tabId: 3
        },
        children: [
          {
            path: '',
            component: BaseQuotaDashboardComponent,
            data: {
              title: 'Compliance',
              showMainContentHeader: true
            }
          },
          {
            path: ':id',
            component: QuotaDetailComponent,
            data: {
              title: 'Quota by Metric',
              showMainContentHeader: false
            }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComplianceRoutingModule {}
