import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { mergeMap } from 'rxjs/operators';
import { navigate } from '../../../core/routing/state/navigation.actions';
import { selectUserRoleInfo } from '../../../core/authentication/state/auth.selectors';
import { selectImpersonationState } from '../../../core/context/impersonation/state/impersonation.selectors';
import { NavigationPaths } from '../../../core/routing/state/navigation-paths.enum';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationGuard implements CanActivate {
  constructor(private appState: Store<AppState>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const userRoleInfo$ = this.appState.select(selectUserRoleInfo);
    const impersonationState$ = this.appState.select(selectImpersonationState);

    return userRoleInfo$.pipe(
      mergeMap(
        userRoleInfo => impersonationState$,
        (userRoleInfo, impersonationState) => {
          if (userRoleInfo.isAdminPersona() && !impersonationState.isImpersonating) {
            this.appState.dispatch(navigate({ path: NavigationPaths.Impersonation }));
            return false;
          } else {
            return true;
          }
        }
      )
    );
  }
}
