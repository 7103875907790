<div [id]="'metric-card_26_' + componentId" class="ubi_metric_title" *ngIf="cardData.pcgTitle">{{ cardData.pcgTitle }}</div>
<div class="pcg__item__metric__top__border__{{ conditionalClass }}"><!-- empty div for accessibiity using this div as border--></div>
<div class="pcg__item__metric" [ngClass]="conditionalClass">
  <div [id]="'metric-card_1_' + componentId" class="metric__title" title="{{ cardData.pcgName }}" *ngIf="metricCardConfig.shouldShowTitle">{{ cardData.pcgName }}</div>
  <div [id]="'metric-card_27_' + componentId" class="metric__timespan" *ngIf="metricCardConfig.shouldShowTimeSpan">{{ cardData.timeSpan }}</div>
  <div [id]="'metric-card_2_' + componentId" class="metric__weight" *ngIf="metricCardConfig.shouldShowWeight">
    <div [id]="'metric-card_3_' + componentId" class="metric__weight__bar"></div>
    <div *ngIf="metricCardConfig.shouldShowWeight" [id]="'metric-card_4_' + componentId" class="metric__weight__val">Weight: {{ cardData.pcgWeight }}%</div>
    <div [id]="'metric-card_5_' + componentId" class="metric__weight__bar"></div>
  </div>
  <div [id]="'metric-card_25_' + componentId" class="metric__note" *ngIf="cardData.pcgNote">
    <i>{{ cardData.pcgNote }}</i>
  </div>
  <div [id]="'metric-card_6_' + componentId" class="metric__stat">
    <div [id]="'metric-card_7_' + componentId" class="metric__stat-left" *ngIf="metricCardConfig.shouldShowAttainment && cardData.attainmentPercent">
      <div [id]="'metric-card_8_' + componentId" class="metric__stat-left__val metric__stat__val">{{ cardData.attainmentPercent }}%</div>
      <div [id]="'metric-card_9_' + componentId" class="metric__stat-left__desc metric__stat__desc">
        <div [id]="'metric-card_10_' + componentId" class="metric__stat__desc__txt">{{ cardData.attainmentTitle }}</div>
        <!-- <mint-tooltip *ngIf="!hideToolTip" [id]="'metric-card_11_' + componentId" [tipText]="cardData.attainmentHelpText" [size]="tooltipSize"></mint-tooltip> -->
      </div>
    </div>
    <div [id]="'metric-card_16_' + componentId" class="metric__stat-right" *ngIf="metricCardConfig.shouldShowEarnings">
      <!-- *ngIf="!isYTD && metricCardConfig.shouldShowEarnings && cardData.earningsBeforeCap && cardData.earningsTitle !== 'Accelerator'" -->

      <div [id]="'metric-card_17_' + componentId" class="metric__stat-right__val metric__stat__val">
        {{ cardData.earningsBeforeCap }}
      </div>
      <div [id]="'metric-card_18_' + componentId" class="metric__stat-right__desc metric__stat__desc">
        <div [id]="'metric-card_19_' + componentId" class="metric__stat__desc__txt">{{ cardData.earningsTitle }}</div>
        <div [id]="'metric-card_20_' + componentId" class="metric__stat__info">
          <!-- <div [id]="'metric-card_21_' + componentId" class="icon icon-info"></div> -->
          <div [id]="'metric-card_22_' + componentId" class="stat__tooltip">
            <div [id]="'metric-card_23_' + componentId" class="stat__tooltip__header"></div>
            <p [id]="'metric-card_24_' + componentId" class="stat__tooltip__text">{{ cardData.earningsHelpText }}</p>
          </div>
        </div>
      </div>
    </div>
    <div [id]="'metric-card_16_' + componentId" class="metric__stat-right" *ngIf="metricCardConfig.shouldShowEarnings && cardData.earningsTitle === 'Accelerator'">
      <div [id]="'metric-card_17_' + componentId" class="metric__stat-right__val metric__stat__val">{{ cardData.accelerator }}</div>
      <div [id]="'metric-card_18_' + componentId" class="metric__stat-right__desc metric__stat__desc">
        <div [id]="'metric-card_19_' + componentId" class="metric__stat__desc__txt">{{ cardData.earningsTitle }}</div>
        <!-- <mint-tooltip *ngIf="!hideToolTip" [id]="'metric-card_20_' + componentId" [tipText]="cardData.earningsHelpText"></mint-tooltip> -->
      </div>
    </div>
    <!-- <mint-product-list
      *ngIf="showProductList"
      [commonProducts]="commonProducts"
      [otherProducts]="otherProducts"
      [highlightedProductName]="highlightedProductName"
      (notifyProductSelection)="onProductSelectionInProductListComponent($event)"
      [pcgName]="cardData.pcgName"
      class="metric__product__list"
    ></mint-product-list> -->
  </div>
</div>
