import { Action } from '@ngrx/store';

export const GET_PAYOUT_SUMMARY = 'Get Payout Summary';
export const GET_PAYOUT_SUMMARY_SUCCESS = 'Get Payout Summary Success';
export const GET_PAYOUT_SUMMARY_FAILURE = 'Get Payout Summary Failure';

export class GetPayoutSummary implements Action {
  readonly type: string = GET_PAYOUT_SUMMARY;

  constructor(public payload: any) {
    console.log(`ACTION ${GET_PAYOUT_SUMMARY} payload`);
  }
}

export class GetPayoutSummarySuccess implements Action {
  readonly type: string = GET_PAYOUT_SUMMARY_SUCCESS;

  constructor(public payload: { data: any }) {
    console.log(`ACTION ${GET_PAYOUT_SUMMARY_SUCCESS} payload`);
  }
}

export class GetPayoutSummaryFailure implements Action {
  readonly type: string = GET_PAYOUT_SUMMARY_FAILURE;

  constructor(public payload: { error: any }) {
    console.log(`ACTION ${GET_PAYOUT_SUMMARY_FAILURE} payload`);
  }
}

export type GetPayoutSummayActions = GetPayoutSummary | GetPayoutSummarySuccess | GetPayoutSummaryFailure;
