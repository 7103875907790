import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HeaderConfig } from '../layout/header/header-config';
import { UserProfileState, selectUserProfileState } from '@mint-libs/context';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SetDisclaimerAcceptance } from './state/disclaimer.actions';
import { DisclaimerState } from './state/disclaimer.reducer';
import * as pascalcaseKeys from 'pascalcase-keys';

@Component({
  selector: 'mint-mobile-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  disclaimerHeaderConfig: HeaderConfig;
  title: string;
  userProfile: any;
  disclaimerText: SafeHtml;
  isLoaded = false;

  constructor(
    protected activeRoute: ActivatedRoute,
    protected router: Router,
    private userProfileState: Store<UserProfileState>,
    private sanitizer: DomSanitizer,
    private disclaimerState: Store<DisclaimerState>
  ) {}

  ngOnInit() {
    this.title = this.activeRoute.snapshot.data.title;
    this.initializeHeaderConfig();
    this.userProfileState.select(selectUserProfileState).subscribe(userProfileState => {
      if (!userProfileState) {
        return;
      }
      this.userProfile = userProfileState;
      this.disclaimerText = this.sanitizer.bypassSecurityTrustHtml(this.userProfile.settings.disclaimer.text);
      this.isLoaded = true;
    });
  }

  initializeHeaderConfig() {
    this.disclaimerHeaderConfig = new HeaderConfig();
    this.disclaimerHeaderConfig.isBackIconVisible = false;
    this.disclaimerHeaderConfig.isHamburgerIconVisible = false;
    this.disclaimerHeaderConfig.isImpersonationBannerVisible = false;
    this.disclaimerHeaderConfig.isNotificationIconVisible = false;
    this.disclaimerHeaderConfig.isAvatarVisible = true;
    this.disclaimerHeaderConfig.isHelpIconVisible = false;
  }

  onAcceptance(disclaimer) {
    this.isLoaded = false;
    this.disclaimerState.dispatch(SetDisclaimerAcceptance(pascalcaseKeys(this.userProfile.settings.disclaimer)));
  }
}
