<div class="impersonation">
  <div class="impersonation__notification-alert impersonation__notification-alert__alert-outline" role="alert" style="width: max-content;" *ngIf="isImpersonating">
    <div class="impersonation__notification-alert__alert-outline__alert-message">
      <div>
        <p aria-live="polite">
          <span>
            <strong style="display: unset"
              ><span class="text-lowercase">{{ impersonatedAlias }}</span></strong
            >
            <!-- <strong *ngIf="impersonationService.showFCAQuotaApprovalPendingError" class="text-danger">(Quota)approval is pending</strong>
            <strong *ngIf="impersonationService.fcaQuotaApprovalPendingButAllowAccess &amp;&amp; !sharedContext.terminationReason" class="text-danger">(Quota)approval is pending</strong>
            <strong [hidden]="sharedContext.terminationReason == null" class="text-danger">&nbsp;Termination Reason : {{ sharedContext.terminationReason }}</strong> -->
          </span>
          <!-- <span *ngIf="sharedContext.isDemoMode3"> <strong> Demo Mode </strong> </span> -->
          <button
            class="mat-icon-container"
            aria-label="Stop Impersonating"
            tabindex="0"
            (click)="stopImpersonation()"
            (keyup.enter)="stopImpersonation()"
            analytics-tracker
            [tracker]="'Stop_Impersonation'"
            [value]="impersonatedAlias"
          >
            <mat-icon class="stop-impersonation" aria-hidden="false">close</mat-icon>
          </button>

          <!-- <button
            class="icon icon-close stop-impersonation"
            type="button"
            title="Stop Impersonation"
            attr.aria-label="Stop Impersonating {{ impersonatedAlias }}"
            (click)="stopImpersonation()"
            style="height: 0px;"
          ></button> -->
        </p>
      </div>
    </div>
  </div>
</div>
