import { Action, createReducer, on } from '@ngrx/store';
import * as DisclaimerActions from './disclaimer.actions';

export const disclaimerFeatureKey = 'disclaimer';

export interface DisclaimerState {}

export const disclaimerState: DisclaimerState = {};

const reducer = createReducer(
  disclaimerState,
  on(DisclaimerActions.SetDisclaimerAcceptance, state => state),
  on(DisclaimerActions.SetDisclaimerAcceptanceSuccess, (state, action) => action.data),
  on(DisclaimerActions.SetDisclaimerAcceptanceFailure, (state, action) => action.error)
);

export function disclaimerReducer(state: DisclaimerState | undefined, action: Action) {
  return reducer(state, action);
}
