import { Action } from '@ngrx/store';

export enum WorkingHoursActionTypes {
  GetWorkingHours = 'Get Working Hours',
  GetWorkingHoursSuccess = 'Get Working Hours Success',
  GetWorkingHoursFailure = 'Get Working Hours Failure'
}

export class GetWorkingHours implements Action {
  readonly type = WorkingHoursActionTypes.GetWorkingHours;
  constructor(public payload: any) {}
}

export class GetWorkingHoursSuccess implements Action {
  readonly type = WorkingHoursActionTypes.GetWorkingHoursSuccess;
  constructor(public payload: { data: any }) {}
}

export class GetWorkingHoursFailure implements Action {
  readonly type = WorkingHoursActionTypes.GetWorkingHoursFailure;
  constructor(public payload: { error: any }) {}
}

export type WorkingHoursActions = GetWorkingHours | GetWorkingHoursSuccess | GetWorkingHoursFailure;
